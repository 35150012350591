import React, { useContext, useState } from "react";
import { GlobalModal } from "../../components/Modal";
import TableCell from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import { WarningModal } from "../Modal";
import api from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import siteadminService from "../../services/siteadminService";

export default function SchoolAdminCell({ row, school }) {

  const dispatch = useDispatch();

  const [removeSchoolAdminModelState, setRemoveSchoolAdminModelState] = useState(false)

  const removeSchoolAdminHandler = (row) => {
    setRemoveSchoolAdminModelState(true)
  }

  const closeRemoveSchoolAdminModel = () => {
    setRemoveSchoolAdminModelState(false)
  }

 const deleteSchoolAdmin = async (id) => {
  try {
    const data = await api.delete(
      `/api/v1/siteadmin/all/delete/${row?._id}/${row?.email}`
    ) 
    if (data && data.ok) {
      await siteadminService.getSchoolAdminBySchool(school, dispatch);
      closeRemoveSchoolAdminModel();
    }
  } catch (error) {
    toast.error(error.message);
    console.error('error', error);
  }
};

  return (
    <>
    <TableCell
      style={{ cursor: 'pointer' }}
      onClick={(e) => InspectOn(e, row._id)}
      align='left'
    >
      {row.name}
    </TableCell>
    <TableCell align="left">{row?.email}</TableCell>
    <TableCell align='left'>
      <Typography>
        <button
          className='remove-btn'
          onClick={() => removeSchoolAdminHandler(row)}
        >
          Remove
        </button>
      </Typography>
    </TableCell>
  
      <GlobalModal
          open={removeSchoolAdminModelState}
          close={closeRemoveSchoolAdminModel}
          heading=''
        >
          <Typography style={{ width: '390px' }}>
            Are you sure you want to delete this School Admin ?
          </Typography>
  
          <div className='btn-groups'>
            <button onClick={deleteSchoolAdmin} className='export'>
              Confirm
            </button>
            <button className='cancel' onClick={closeRemoveSchoolAdminModel}>
              Cancel
            </button>
          </div>
        </GlobalModal>
    </>
  );
}
