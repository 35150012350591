import * as React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import License from '../../components/SchoolHome/license'
import { SetProps, TabPanel } from '../../components/Tabs'
import InspectClassroom from '../../components/ClassroomHome/inspectClassroom'
import Teacher from '../../components/SchoolHome/teacher'
import { useLocation } from 'react-router-dom'
import BreadCrumb from '../../components/BreadCrumb'
import { StudentContext } from '../../components/Context/StudentContext'
import BreadCrumbList from '../../components/BreadCrumbList'
import { useDispatch, useSelector } from 'react-redux'
import siteadminService from '../../services/siteadminService'
import Sidebar from '../../components/Sidebar'
import api from '../../services/api'
export default function ClassroomHome({ match }) {
  const [value, setValue] = React.useState(0)
  const dispatch = useDispatch()

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const [allstudents, setAllStudents] = React.useState([])
  let schoolId = localStorage.getItem('schoolId')

  React.useEffect(() => {
    async function getAllStudents() {
      try {
        let path = `api/v1/siteadmin/view/all/class/${match.params.classId}/student`
        const res = await api.get(path)
        if (res && res.ok) {
          setAllStudents(res.data[0])
        }
      } catch (error) {
        console.error(error)
      }
    }
    localStorage.setItem('classId', match.params.classId)
    getAllStudents()
  }, [match.params.classId])

  const breadcrumbList = [
    {
      name: 'Dashboard',
      to: '/dashboard',
    },
    {
      name: 'School',
      to: `/dashboard/school/${schoolId}`,
    },
    {
      name: match.params.classroom,
      to: `/dashboard/classroom/${match.params.classId}/${match.params.classroom}`,
    },
  ]

  return (
    <StudentContext.Provider value={{ Info: match.params }}>
      <Sidebar />
      <div className='main'>
        <Box sx={{ width: '100%', padding: '38px', paddingTop: 0 }}>
          <BreadCrumbList values={breadcrumbList} />

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label='Classroom' {...SetProps(0)} />
              {/* <Tab label='Teacher' {...SetProps(1)} />
              <Tab label='License' {...SetProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <InspectClassroom
              resultData={allstudents}
              name={match.params.classroom}
            />
          </TabPanel>
          {/* <TabPanel value={value} index={1}>
            <Teacher resultData={allstudents} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <License resultData={allstudents} />
          </TabPanel> */}
        </Box>
      </div>
    </StudentContext.Provider>
  )
}
