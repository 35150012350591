import { Box, MenuItem } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import TableMain from "../Table";
import BreadCrumb from "../../components/BreadCrumb";
import { InfoContext } from "../Context/InfoContext";
import BreadCrumbList from "../BreadCrumbList";
import { useLocation } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { consoleLog } from "../../utils/helper";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import siteadminService from "../../services/siteadminService";

function createData(requests, location, type) {
  return {
    requests,
    location,
    type,
  };
}

const resultData = [
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
  createData(
    "Replacing STEM topic article with.....",
    "Lesson management > 4 week package > week 1",
    ".pdf"
  ),
];

// const headCells = [
//   {
//     id: "request",
//     label: "Request",
//     numeric: false,
//     disablePadding: false,
//   },

//   {
//     id: "location",
//     label: "File location",
//     numeric: false,
//     disablePadding: false,
//   },

//   {
//     id: "type",
//     label: "File type",
//     numeric: false,
//     disablePadding: false,
//   },
//   {
//     id: "action",
//     label: "Action",
//     numeric: false,
//     disablePadding: false,
//   },
// ];

const headCells = [
  {
    id: "title",
    label: "Title",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "classroom",
    label: "Classroom",
    numeric: false,
    disablePadding: false,
  },
  {
    id: "action",
    label: "Action",
    numeric: false,
    disablePadding: false,
  },
];

export default function ContentManagement({ match }) {
  const { school } = useParams();
  const dispatch = useDispatch();

  const [language, setLanguage] = useState("none");
  const [subtitle, setSubtitle] = useState("none");

  const handleLanguage = (e) => {
    setLanguage(e.target.value);
  };

  const handleSubtitle = (e) => {
    setSubtitle(e.target.value);
  };

  const location = useLocation();

  const breadcrumbList = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "School",
      to: `/dashboard/school/${match.params.school}`,
    },
    {
      name: "Languages/Content Management",
      to: `/dashboard/content/${match.params.school}`,
    },
  ];

  const [contentData, setContentData] = useState([]);

  const fetchSchoolContent = async () => {
    try {
      const res = await api.get(
        `/api/v1/siteadmin/schoolContents/view/${school}`
      );
      if (res && res.ok) {
        if (res.data.length > 0) {
          setContentData(res.data);
        }
      }
    } catch (err) {
      consoleLog(err);
    }
  };

  React.useEffect(() => {
    function initializeAll() {
      siteadminService.getShool(school, dispatch);
    }
    initializeAll();
  }, []);

  useEffect(() => {
    fetchSchoolContent();
  }, [school]);

  const [filterData, setFilterData] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const classes = useSelector((state) => state.siteadmin.school);

  useEffect(() => {
    filterContent(classes[0]?._id);
  }, [classes, contentData]);

  const changeHandler = (id) => {
    setSelectedClass(id);
    filterContent(id);
  };

  const filterContent = (id) => {
    const data = [];
    for (var i = 0; i < contentData.length; i++) {
      if (
        JSON.stringify(contentData[i].classroomId._id) === JSON.stringify(id)
      ) {
        data.push(contentData[i]);
      }
    }
    setFilterData(data);
  };
  return (
    <>
      <Sidebar />
      <Box sx={{ p: 5, paddingTop: 0 }} className="main">
        <BreadCrumbList values={breadcrumbList} />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "60%",
            paddingTop: "44px",
            gap: "132px",
          }}
        >
          {/* <Box>
            <h4>Language For content</h4>
            <select className='content-select' name='' id=''>
              <option value={'us'}>English (US)</option>
              <option value={'chinese'}>Chinese (Traditional)</option>
              <option value={'uk'}>English (UK)</option>
            </select>
          </Box> */}
          <Box>
            <h4>Subtitle class</h4>
            <select
              className="content-select"
              name=""
              id=""
              value={selectedClass}
              onChange={(e) => changeHandler(e.target.value)}
            >
              {classes && classes.length > 0
                ? classes.map((data, index) => {
                    return (
                      <option key={index} value={data._id}>
                        {data.className}
                      </option>
                    );
                  })
                : ""}
            </select>
          </Box>
        </Box>

        {/* <h4 style={{ marginTop: '53px' }}>Requests for content modification</h4> */}
        <h4 style={{ marginTop: "53px" }}>Content modification</h4>
        <TableMain
          headCells={headCells}
          resultData={filterData}
          topic="school-content"
        />
     </Box>
    </>
  );
}
