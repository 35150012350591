import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import UserContextProvider from './context/User'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Provider } from 'react-redux'
import store from './store'

const theme = createTheme({
  typography: {
    fontFamily: 'Inter',
  },
})
const cache = createCache({
  key: 'css',
  prepend: true,
})
ReactDOM.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <UserContextProvider>
            <ToastContainer />

            <App />
          </UserContextProvider>
        </Provider>
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>,
  document.getElementById('root')
)
