import React, { useState } from "react";
import uploadIcon from "../../../assets/uploadIcon.svg";
import { GlobalModal } from "../../Modal";
import warning from "../../../assets/warning.svg";
import Box from "@mui/material/Box";
import { useParams, useHistory } from "react-router-dom";
import api from "../../../services/api";

const AddGameContent = () => {
  const { packageType, noOfWeek } = useParams();
  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [csvShop, setCsvShop] = useState("");
  const [csvDescision, setcsvDecision] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // ADD NEW MODEL STATE FOR LUCKY DRAW STARTS
  const [addModelForLuckDrawState, setAddModelForLuckyDrawState] =
    useState(false);

  const openModelForCSVLuckyDrawHandler = () => {
    setAddModelForLuckyDrawState(true);
  };

  const closeModelHandler = () => {
    setAddModelForLuckyDrawState(false);
  };

  // ADD NEW MODEL STATE FOR LUCKY DRAW ENDS

  // ADD NEW MODEL STATE FOR Shop STARTS
  const [addModelForShopState, setAddModelShopState] = useState(false);

  const openModelForCSVShopHandler = () => {
    setAddModelShopState(true);
  };

  const closeModelHandlerShop = () => {
    setAddModelShopState(false);
  };

  // ADD NEW MODEL STATE FOR Shop ENDS

  // ADD NEW MODEL STATE FOR Conversation STARTS
  const [addModelForConversationState, setAddModelConversationState] =
    useState(false);

  const openModelForCSVConversationHandler = () => {
    setAddModelConversationState(true);
  };

  const closeModelHandlerConversation = () => {
    setAddModelConversationState(false);
  };

  const [choosenImageDecision, setChooseImageDecision] = useState("");
  const [decisionFile, setDecisionFile] = useState("");
  const uploadFileDecision = async (e) => {
    setChooseImageDecision(e.target.files[0].name);
    setDecisionFile(e.target.files[0]);
    // const formData = new FormData();
    // formData.append("decisionTee-file", file);
    //setUploadingPdf(true);
    // try {
    //   const res = await api.post(`/api/v1/siteadmin/upload/decision-tree`, formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   });
    //   if(res && res.ok){
    //     console.log(res);
    //     //setCsvShop(res.urls[0]._id);
    //     const res1 = await api.get("/api/v1/siteadmin/decision/id");
    //     if(res1 && res1.ok){
    //       console.log(res1.data[0]);
    //       setcsvDecision(res1.data[0]._id);
    //     }

    //   }
    // } catch (error) {
    //   console.log(error);
    //   //setUploadingCharaImage(false);
    // }
  };

  const addGame = async () => {
    setErrorMessage("");
    if (decisionFile === "") {
      setErrorMessage("Please add decision tree");
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("decisionTree-file", decisionFile);
      formData.append("classroomContentId", packageType);
      const res = await api.post(
        "api/v1/siteadmin/upload/decisionTreeByCsv",
        // pacakageType: parseInt(packageType),
        // week: noOfWeek,
        // name: name,
        // description: description,
        // decisionTree: csvDescision,
        // shop: csvShop
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        setLoading(false);
        history.goBack();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setErrorMessage("error adding data");
    }
  };

  const cancelHandler = () => {
    history.goBack();
  };

  const editContentPageHandler = (weekNo = "2") => {
    history.push(`/platformManagement/editGameContent/${weekNo}`);
  };

  const [luckySpinFileName, setLuckySpinFileName] = useState("");
  const [luckySpinFile, setLuckySpinFile] = useState("");
  const [errSuccMessage, setErrSuccMessage] = useState("");
  const uploadImage = (e) => {
    setLuckySpinFileName(e.target.files[0].name);
    setLuckySpinFile(e.target.files[0]);
  };

  const uploadLuckySpinHandler = async () => {
    const formData = new FormData();
    formData.append("file", luckySpinFile);
    try {
      const res = await api.post(
        "/api/v1/siteadmin/upload/luckyspinByCsv",
        formData
      );
      if (res && res.ok) {
        setErrSuccMessage(res.message);
        setInterval(() => {
          closeModelHandler();
        }, 3000);
      }
    } catch (err) {
      setErrSuccMessage(err.message);
    }
  };

  const [choosenImageShop, setChooseImageShop] = useState("");
  const [shopFile, setShopFile] = useState("");
  const [shopErrSuccMsg, setShopErrSuccMsg] = useState("");
  const uploadFileShop = async (e) => {
    setChooseImageShop(e.target.files[0].name);
    const file = e.target.files[0];
    setShopFile(file);
  };

  const uploadShopHandler = async () => {
    const formData = new FormData();
    formData.append("shopitem-file", shopFile);
    try {
      const res = await api.post(
        "/api/v1/siteadmin/upload/shop-item",
        formData
      );
      if (res && res.ok) {
        setShopErrSuccMsg(res.message);
        setInterval(() => {
          closeModelHandlerShop();
        }, 3000);
      }
    } catch (err) {
      setShopErrSuccMsg(err.message);
    }
  };

  return (
    <>
      <div className="addComponentMainDiv">
        <div className="topBar">
          <span>Game mangement/ week {noOfWeek}</span>
          <div className="btnDiv">
            <button
              onClick={openModelForCSVLuckyDrawHandler}
              style={{ marginRight: "10px" }}
            >
              <img src={uploadIcon} alt="upoadIcon" />
              CSV Upload For Lucky Draw
            </button>
            <button
              onClick={openModelForCSVShopHandler}
              style={{ marginRight: "10px" }}
            >
              <img src={uploadIcon} alt="upoadIcon" />
              CSV Upload For Shop
            </button>
            <button
              onClick={openModelForCSVConversationHandler}
              style={{ marginRight: "10px" }}
            >
              <img src={uploadIcon} alt="upoadIcon" />
              CSV Decision Game
            </button>
          </div>
        </div>

        <div className="contentDiv">
          <div className="inputField">
            <label>Name the Game</label>
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className="inputField">
            <label>Game Description</label>
            {/* <input type="text" className="desc" /> */}
            <textarea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            ></textarea>
          </div>
        </div>
        {errorMessage.length ? (
          <div class="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        ) : (
          ""
        )}
        <div className="footerBar">
          <button className="create" onClick={addGame}>
            Create
          </button>
          <button className="cancel" onClick={cancelHandler}>
            Cancel
          </button>
        </div>
      </div>
      {/* ADD MEDIA MODEL For Lucky draw STARTS */}
      <GlobalModal
        type="upload"
        open={addModelForLuckDrawState}
        close={closeModelHandler}
        heading="Upload CSV For Lucky Draw"
      >
        <Box sx={{ width: "100%" }}>
          <div className="image-upload">
            <div className="image-upload-inner">
              <label htmlFor="input_file">
                {" "}
                <img src={uploadIcon} alt="" />
              </label>
              <input
                onChange={(e) => uploadImage(e)}
                type="file"
                class="input-file"
                id="input_file"
              />

              <span>{luckySpinFileName}</span>
              <span>CSV Upload</span>

              <label className="change-img" htmlFor="input_file">
                Browse{" "}
              </label>
            </div>
            {errSuccMessage ? (
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>{errSuccMessage}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="newEntryBottom-wrapper"
            style={{ marginBottom: "-20px" }}
          >
            <div className="btn-next" onClick={uploadLuckySpinHandler}>
              Done
            </div>
          </div>
        </Box>
      </GlobalModal>
      {/* ENDS For Lucky draw */}

      {/* ADD MEDIA MODEL For Shop STARTS */}
      <GlobalModal
        type="upload"
        open={addModelForShopState}
        close={closeModelHandlerShop}
        heading="Upload CSV For Shop"
      >
        <Box sx={{ width: "100%" }}>
          <div className="image-upload">
            <div className="image-upload-inner">
              <label htmlFor="input_file">
                {" "}
                <img src={uploadIcon} alt="" />
              </label>
              <input
                onChange={(e) => uploadFileShop(e)}
                type="file"
                class="input-file"
                id="input_file"
              />

              <span>{choosenImageShop}</span>
              <span>CSV Upload</span>

              <label className="change-img" htmlFor="input_file">
                Browse{" "}
              </label>
            </div>
            {shopErrSuccMsg ? (
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>{shopErrSuccMsg}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="newEntryBottom-wrapper"
            style={{ marginBottom: "-20px" }}
          >
            <div className="btn-next" onClick={uploadShopHandler}>
              Done
            </div>
          </div>
        </Box>
      </GlobalModal>
      {/* ENDS For Shop */}

      {/* ADD MEDIA MODEL For Conversation STARTS */}
      <GlobalModal
        type="upload"
        open={addModelForConversationState}
        close={closeModelHandlerConversation}
        heading="Upload CSV For Decision Tree"
      >
        <Box sx={{ width: "100%" }}>
          <div className="image-upload">
            <div className="image-upload-inner">
              <label htmlFor="input_file">
                {" "}
                <img src={uploadIcon} alt="" />
              </label>
              <input
                onChange={(e) => uploadFileDecision(e)}
                type="file"
                class="input-file"
                id="input_file"
              />

              <span>{choosenImageDecision}</span>
              <span>CSV Upload</span>

              <label className="change-img" htmlFor="input_file">
                Browse{" "}
              </label>
            </div>
            {/* <div className="error-upload">
              <img src={warning} alt="Warning" />
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Elementum,
              </span>
            </div> */}
          </div>
          <div
            className="newEntryBottom-wrapper"
            style={{ marginBottom: "-20px" }}
          >
            <div className="btn-next" onClick={closeModelHandlerConversation}>
              Done
            </div>
          </div>
        </Box>
      </GlobalModal>
      {/* ENDS For Conversation */}
    </>
  );
};
export default AddGameContent;
