import React, { useState, useEffect } from "react";
import uploadIcon from "../../../assets/uploadIcon.svg";
import uploadIconWhite from "../../../assets/uploadIconWhite.svg";
import { GlobalModal } from "../../Modal";
import warning from "../../../assets/warning.svg";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../Tabs";
import { SetProps } from "../../Tabs";
import uploadIconSm from "../../../assets/uploadIconSm.svg";
import videoUpload from "../../../assets/videoUpload.svg";
import plusIcon from "../../../assets/plusIcon.svg";
import api from "../../../services/api";
import { useParams, useHistory } from "react-router-dom";
import axios from "axios";
import store from "../../../store";
import {
  beginApiCall,
  endApiCall,
  cancelApiCall,
} from "../../../store/actions/siteadminActions";
import { getOnlyFolderStructure } from "../../../utils/helper";
import config from "../../../config";

const AddContentHomeComponent = () => {
  const { language, packageType, noOfWeek } = useParams();
  const history = useHistory();
  // ADD NEW MODEL STATE STARTS
  const [addModelState, setAddModelState] = useState(false);

  const [packageId, setPackageId] = useState("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openModelHandler = () => {
    setAddModelState(true);
  };

  const closeModelHandler = () => {
    setAddModelState(false);
  };

  const [name, setName] = useState();
  const [description, setDescription] = useState();

  const [choosenImage, setChooseImage] = useState("");
  const [choosenImageFile, setChoosenImageFile] = useState();

  const [choosenVideo1, setChooseVideo1] = useState("");
  const [choosenVideoFile1, setChoosenVideoFile1] = useState();
  const [choosenSub1, setChooseSub1] = useState("");
  const [choosenSubFile1, setChoosenSubFile1] = useState();

  const [choosenVideo2, setChooseVideo2] = useState("");
  const [choosenVideoFile2, setChoosenVideoFile2] = useState();
  const [choosenSub2, setChooseSub2] = useState("");
  const [choosenSubFile2, setChoosenSubFile2] = useState();

  const [choosenCSV, setChooseCSV] = useState("");
  const [choosenCsvFile, setChoosenCsvFile] = useState();

  const [pdfUrl, setPdfUrl] = useState("");

  const [video1, setVideo1] = useState("");
  const [video2, setVideo2] = useState("");

  const [subtitle1, setSubtitle1] = useState([
    {
      language: "",
      url: "",
    },
  ]);
  const [subtitle2, setSubtitle2] = useState([
    {
      language: "",
      url: "",
    },
  ]);

  const [quiz, setQuiz] = useState([]);
  const [quizId, setQuizId] = useState("");

  const [video1Title, setVideo1Title] = useState("");
  const [video2Title, setVideo2Title] = useState("");
  const [pdfTitle, setPdfTitle] = useState("");

  const [loading, setLoading] = useState("");

  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [loadingQuiz, setLoadingQuiz] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const getPackageId = async () => {
    try {
      const res = await api.get(
        `api/v1/siteadmin/getDefaultClassroomByPackageType/${language}/${packageType}`
      );
      if (res && res.ok) {
        setPackageId(res.data._id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPackageId();
  }, [packageType]);

  const uploadImage = async (e) => {
    setLoadingPdf(true);
    setChooseImage(e.target.files[0].name);
    setChoosenImageFile(e.target.files[0]);
    const file = e.target.files[0];
    // const formData = new FormData();
    // formData.append("docs", file);
    // try {
    //   const res = await api.post(
    //     `/api/v1/teacher/classroomContents/upload/pdf`,
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );
    //   if (res && res.ok) {
    //     console.log(res.url);
    //     setPdfUrl(res.url);
    //     setLoadingPdf(false);
    //   }
    // } catch (error) {
    //   console.log(error);
    //   setLoadingPdf(false);
    //   alert("erorr uploading PDF");
    // }
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/upload/video/preSignedUrl`,
        {
          fileName: `uploads/admin/files/${e.target.files[0].name}`,
        }
      );
      if (res.ok && res.urls) {
        store.dispatch(beginApiCall());
        await axios.put(res.urls, file);
        store.dispatch(endApiCall());
        const onlyFolderLink = getOnlyFolderStructure(res.urls.split("?")[0]);
        setPdfUrl(onlyFolderLink);
        setLoadingPdf(false);
      }
    } catch (err) {
      console.error(err);
      setLoadingPdf(false);
      alert("erorr uploading PDF");
    }
  };

  const handleTitle = (e) => {
    if (e.target.name === "video1Title") {
      setVideo1Title(e.target.value);
    }
    if (e.target.name === "video2Title") {
      setVideo2Title(e.target.value);
    }
    if (e.target.name === "pdfTitle") {
      setPdfTitle(e.target.value);
    }
  };

  const uploadVideo1 = async (e) => {
    setChooseVideo1(e.target.files[0].name);
    setChoosenVideoFile1(e.target.files[0]);
    const file = e.target.files[0];
    // const formData = new FormData();
    // formData.append("videos", file);
    // formData.append("file", file);
    setLoadingVideo(true);
    // try {
    //   const res = await api.post(
    //     `/api/v1/teacher/classroomContents/upload/video`,
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );
    //   if (res && res.ok) {
    //     console.log(res.urls);
    //     setVideo1(res.urls[0]._id);
    //     setLoadingVideo(false);
    //   }
    // } catch (error) {
    //   console.log(error);
    //   setLoadingVideo(false);
    //   alert("erorr uploading video 1");
    // }
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/upload/video/preSignedUrl`,
        {
          fileName: `uploads/admin/videos/${e.target.files[0].name}`,
        }
      );

      if (res.ok && res.urls) {
        store.dispatch(beginApiCall());
        await axios.put(res.urls, file);
        store.dispatch(endApiCall());
        const onlyFolderLink = getOnlyFolderStructure(res.urls.split("?")[0]);
        const uploadToDb = await api.post(
          `/api/v1/teacher/classroomContents/upload/video`,
          { videoUrl: onlyFolderLink }
        );
        if (uploadToDb && uploadToDb.ok) {
          setVideo1(uploadToDb.urls._id);
          setLoadingVideo(false);
        }
      }
    } catch (err) {
      console.error(err);
      setLoadingVideo(false);
      alert("erorr uploading video 1");
    }
  };

  const uploadVideo2 = async (e) => {
    setChooseVideo2(e.target.files[0].name);
    setChoosenVideoFile2(e.target.files[0]);
    const file = e.target.files[0];
    // const formData = new FormData();
    // formData.append("videos", choosenVideoFile1);
    // formData.append("file", file);
    setLoadingVideo(true);
    // try {
    //   const res = await api.post(
    //     `/api/v1/teacher/classroomContents/upload/video`,
    //     formData,
    //     {
    //       headers: {
    //         "Content-Type": "multipart/form-data",
    //       },
    //     }
    //   );
    //   if (res && res.ok) {
    //     console.log(res.urls);
    //     // setVideo1(res.urls[0]._id);
    //     setVideo2(res.urls[0]._id);
    //     setLoadingVideo(false);
    //   }
    // } catch (error) {
    //   console.log(error);
    //   setLoadingVideo(false);
    //   alert("erorr uplaading video 2");
    // }
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/upload/video/preSignedUrl`,
        {
          fileName: `uploads/admin/videos/${e.target.files[0].name}`,
        }
      );

      if (res.ok && res.urls) {
        store.dispatch(beginApiCall());
        await axios.put(res.urls, file);
        store.dispatch(endApiCall());
        const onlyFolderLink = getOnlyFolderStructure(res.urls.split("?")[0]);
        const uploadToDb = await api.post(
          `/api/v1/teacher/classroomContents/upload/video`,
          { videoUrl: onlyFolderLink }
        );
        if (uploadToDb && uploadToDb.ok) {
          setVideo2(uploadToDb.urls._id);
          setLoadingVideo(false);
        }
      }
    } catch (err) {
      console.error(err);
      setLoadingVideo(false);
      alert("erorr uploading video 1");
    }
  };

  const addNewSubtitle1Handler = () => {
    const oldData = [];
    for (var i = 0; i < subtitle1.length; i++) {
      oldData.push(subtitle1[i]);
    }
    oldData.push({ language: "", url: "" });
    setSubtitle1(oldData);
  };

  const changeSubtitle1LanguageHandler = (e, index) => {
    const { value } = e.target;
    const oldData = [];
    for (var i = 0; i < subtitle1.length; i++) {
      oldData.push(subtitle1[i]);
    }
    oldData[index].language = value;
    setSubtitle1(oldData);
  };

  const uploadSub1 = async (e, index) => {
    setChooseSub1(e.target.files[0].name);
    setChoosenSubFile1(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("subtiles", file);
    setLoadingVideo(true);
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/uploadSingle/subtitle`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        const oldData = [];
        for (var i = 0; i < subtitle1.length; i++) {
          oldData.push(subtitle1[i]);
        }
        oldData[index].url = res.url;
        setSubtitle1(oldData);
        // setSubtitle1(res.url);
        setLoadingVideo(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingVideo(false);
      alert("error uploading subtitle 1");
    }
  };

  const changeSubtitle2LanguageHandler = (e, index) => {
    const { name, value } = e.target;
    // setSubtitle2({ language: value });
    const oldData = [];
    for (var i = 0; i < subtitle2.length; i++) {
      oldData.push(subtitle2[i]);
    }
    oldData[index].language = value;
    setSubtitle2(oldData);
  };

  const addNewSubtitle2Handler = () => {
    const oldData = [];
    for (var i = 0; i < subtitle2.length; i++) {
      oldData.push(subtitle2[i]);
    }
    oldData.push({ language: "", url: "" });
    setSubtitle2(oldData);
  };

  const uploadSub2 = async (e, index) => {
    setChooseSub2(e.target.files[0].name);
    setChoosenSubFile2(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("subtiles", file);
    setLoadingVideo(true);
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/uploadSingle/subtitle`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        // setSubtitle2(res.url);
        const oldData = [];
        for (var i = 0; i < subtitle2.length; i++) {
          oldData.push(subtitle2[i]);
        }
        oldData[index].url = res.url;
        setSubtitle2(oldData);
        setLoadingVideo(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingVideo(false);
    }
  };

  const [configName, setConfigName] = useState("");

  const loadQuizFromJoyStick = async (event)  =>  {
    try {
      store.dispatch(beginApiCall());
      var data = {
        u: "",
        p: {},
      };
      var configData = {
        method: "post",
        url: `${config.app.joystickApiBaseUrl}/${configName}/dynamic`,
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": `${config.app.joystickAPIKey}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        },
        data: data,
      };

      await axios(configData)
        .then(function (response) {
          store.dispatch(endApiCall());
          uploadQuizCsv(response.data.data.questions);
        })
        .catch(function (error) {
          console.error(error);
          alert("Invalid Quiz Code");
        });
    } finally {
      store.dispatch(cancelApiCall());
    }
  };

  const uploadQuizCsv = async (data) => {
    try {
      const res = await api.post(
        `/api/v1/teacher/upload/defaultContentQuizExcel`,
        {
          data: data,
        }
      );
      if (res && res.ok) {
        alert(res.message);
        setQuiz(res.data);
        setLoadingQuiz(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingQuiz(false);
      endApiCall();
      alert("erorr uploading quiz");
    }
  };

  const addLesson = async () => {
    setErrorMessage("");
    if (
      video1 === "" ||
      video1Title === "" ||
      video2Title === "" ||
      video2 === "" ||
      subtitle1 === "" ||
      subtitle2 === "" ||
      pdfUrl === "" ||
      pdfTitle === ""
    ) {
      setErrorMessage("All fields are required");
      const logData = {
        video1: video1,
        video2: video2,
        subtitle1: subtitle1,
        subtitle2: subtitle2,
        pdfUrl: pdfUrl,
        video1Title: video1Title,
        video2Title: video2Title,
        pdfTitle: pdfTitle,
      };
      return;
    }
    setLoading(true);
    // console.log({
    //   title: name,
    //   pdfTitle: pdfTitle,
    //   description: pdfUrl,
    //   videos: [
    //     { _id: video1, videoTitle: video1Title },
    //     { _id: video2, videoTitle: video2Title },
    //   ],
    //   subtitles: [subtitle1, subtitle2],
    //   thumbnail: ["", ""],
    //   quiz: quiz,
    //   week: parseInt(noOfWeek),
    // });
    try {
      const res = await api.post(
        `api/v1/teacher/classroomContentBySiteAdmin/add/${packageId}`,
        {
          title: name,
          pdfTitle: pdfTitle,
          description: pdfUrl,
          videos: [
            { _id: video1, videoTitle: video1Title },
            { _id: video2, videoTitle: video2Title },
          ],
          subtitles: [subtitle1, subtitle2],
          thumbnail: ["", ""],
          quiz: quiz,
          week: parseInt(noOfWeek),
        }
      );
      if (res && res.ok) {
        setLoading(false);
        setErrorMessage("");
        history.goBack();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setErrorMessage("erorr adding data");
    }
  };

  return (
    <>
      <div className="addComponentMainDiv">
        <div className="topBar">
          <span>Lesson mangement/ week {noOfWeek}</span>
          <div className="btnDiv">
            <button onClick={openModelHandler}>
              <img src={uploadIconWhite} alt="upoadIcon" />
              CSV Upload For Lesson
            </button>
          </div>
        </div>

        <div className="contentDiv">
          <div className="inputField">
            <label>Name the lesson</label>
            <input
              defaultValue={""}
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="text"
            />
          </div>

          <div className="inputField">
            <label>Lesson Description</label>
            {/* <input type="text" className="desc" /> */}
            <textarea
              defaultValue={""}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
        </div>

        {errorMessage.length ? (
          <div class="alert alert-danger" role="alert">
            {errorMessage}
          </div>
        ) : (
          ""
        )}
        <div className="footerBar">
          <button className="create" onClick={addLesson}>
            Create
          </button>
          <button
            className="cancel"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </button>
        </div>
      </div>
      {/* ADD MEDIA MODEL STARTS */}
      <GlobalModal
        type="upload"
        open={addModelState}
        close={closeModelHandler}
        heading="CSV Upload"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div className="tabs-section tabs-section-upload">
              <Tabs
                sx={{
                  width: "80%",
                  marginBottom: "33px",
                  textTransform: "lowercase",
                  "& .Mui-selected": {
                    backgroundColor: "#EF5B2C",
                    color: "white !important",
                    borderRadius: 1,
                  },

                  "& .MuiTabs-indicator": {
                    borderBottomColor: "#EF5B2C !important",
                  },
                }}
                value={value}
                indicatorColor="green"
                onChange={handleChange}
              >
                <Tab label="PDF" {...SetProps(0)} />
                <Tab label="VIDEOS" {...SetProps(1)} />
                <Tab label="QUIZ QUESTIONS" {...SetProps(2)} />
              </Tabs>
            </div>
          </Box>
          <TabPanel value={value} index={0} sx={{ position: "relative" }}>
            <div className="image-upload">
              <input
                className="title-content"
                placeholder="Enter Title..."
                type="text"
                value={pdfTitle}
                onChange={handleTitle}
                name="pdfTitle"
              />
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={uploadIcon} alt="" />
                </label>
                <input
                  onChange={(e) => uploadImage(e)}
                  type="file"
                  accept=".pdf"
                  class="input-file"
                  id="input_file"
                />

                <span>{choosenImage}</span>
                <span>PDF Upload</span>

                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
            </div>
            <div
              className="newEntryBottom-wrapper"
              style={{ marginBottom: "-20px" }}
            >
              <div className="btn-next" onClick={() => setValue(1)}>
                Next
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} sx={{ position: "relative" }}>
            <div className="image-upload image-upload-video">
              <div className="image-upload-video-1">
                <input
                  className="title-content video-title"
                  placeholder="Enter video 1 Title..."
                  type="text"
                  value={video1Title}
                  name="video1Title"
                  onChange={handleTitle}
                />
                <div className="image-upload-inner">
                  <label htmlFor="input_file">
                    {" "}
                    <img src={videoUpload} alt="Upload Video" />
                  </label>
                  <input
                    onChange={(e) => uploadVideo1(e)}
                    type="file"
                    accept=".mp4"
                    class="input-file"
                    id="input_file"
                  />

                  <span>{choosenVideo1}</span>
                  <label className="change-img" htmlFor="input_file">
                    Change{" "}
                  </label>
                </div>

                {subtitle1 && subtitle1.length > 0
                  ? subtitle1.map((data, index) => {
                      return (
                        <div
                          className="error-upload"
                          style={{
                            // marginTop: "-20px",
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            alignItems: "flex-start",
                          }}
                          key={index}
                        >
                          <label>Language</label>
                          <input
                            type="text"
                            name={`language-${index}`}
                            value={subtitle1[index].language}
                            onChange={(e) =>
                              changeSubtitle1LanguageHandler(e, index)
                            }
                          />
                          <div className="subtitle">
                            <label for={`subtitle-${index}`}>
                              <img src={uploadIconSm} />
                              <span>
                                {data[index]?.url
                                  ? "Subtitle selected"
                                  : "subtitle"}
                              </span>
                            </label>
                            <input
                              onChange={(e) => uploadSub1(e, index)}
                              // id="`${"subtitle-0}
                              id={`subtitle-${index}`}
                              accept=".vtt,.srt"
                              type="file"
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <span
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={addNewSubtitle1Handler}
                >
                  +Add new subtitle
                </span>
              </div>
              <div className="image-upload-video-1">
                <input
                  className="title-content video-title"
                  placeholder="Enter Video 2 Title..."
                  value={video2Title}
                  type="text"
                  onChange={handleTitle}
                  name="video2Title"
                />
                <div className="image-upload-inner">
                  <label htmlFor="input_file2">
                    {" "}
                    <img src={videoUpload} alt="" />
                  </label>
                  <input
                    onChange={(e) => uploadVideo2(e)}
                    type="file"
                    accept=".mp4"
                    class="input-file"
                    id="input_file2"
                  />

                  <span>{choosenVideo2}</span>
                  <label className="change-img" htmlFor="input_file2">
                    Change{" "}
                  </label>
                </div>
                {subtitle2 && subtitle2.length > 0
                  ? subtitle2.map((data, index) => {
                      return (
                        <div
                          className="error-upload"
                          style={{
                            // marginTop: "-20px",
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            alignItems: "flex-start",
                          }}
                          key={index}
                        >
                          <label>Language</label>
                          <input
                            type="text"
                            name={`language-${index}`}
                            value={subtitle2[index].language}
                            onChange={(e) =>
                              changeSubtitle2LanguageHandler(e, index)
                            }
                          />
                          <div className="subtitle">
                            <label for={`uploadSubtitle-${index}`}>
                              <img src={uploadIconSm} />
                              <span>
                                {data[index]?.url
                                  ? "Subtitle selected"
                                  : "subtitle"}
                              </span>
                            </label>
                            <input
                              onChange={(e) => uploadSub2(e, index)}
                              type="file"
                              accept=".vtt,.srt"
                              // id="uploadSubtitle"
                              id={`uploadSubtitle-${index}`}
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <span
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={addNewSubtitle2Handler}
                >
                  +Add new subtitle
                </span>
              </div>
            </div>
            <div
              className="newEntryBottom-wrapper"
              style={{
                position: "relative",
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                padding: "10px",
              }}
            >
              <div
                className="btn-next"
                style={{
                  background: "#9D9D9D",
                }}
                onClick={() => setValue(2)}
              >
                Next
              </div>
            </div>
          </TabPanel>{" "}
          <TabPanel value={value} index={2} sx={{ position: "relative" }}>
            <div className="image-upload">
              <div className="image-upload-inner">
                <input
                  type="text"
                  name="configName"
                  placeholder="Enter config file name"
                  value={configName}
                  onChange={(e) => setConfigName(e.target.value)}
                />
                <button className="school-btn" onClick={loadQuizFromJoyStick}>
                  Load Data
                </button>
              </div>
            </div>
            <div
              className="newEntryBottom-wrapper"
              style={{ marginBottom: "-20px" }}
            >
              <div className="btn-next btn-done" onClick={closeModelHandler}>
                Done
              </div>
            </div>
          </TabPanel>
        </Box>
      </GlobalModal>
      {/* ENDS */}
    </>
  );
};
export default AddContentHomeComponent;
