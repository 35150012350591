import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import config from "../../config";
import { TabPanel } from "../../components/Tabs";
import { SetProps } from "../../components/Tabs";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import search from "../../assets/search.svg";
import PropTypes from "prop-types";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import OptionGroupUnstyled from "@mui/base/OptionGroupUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import { GlobalModal } from "../../components/Modal";
import Tab1Banner from "../../components/DataTable";
import { format, formatDistanceToNow } from "date-fns";

import { Link } from "react-router-dom";
import { useUser } from "../../context/User";
import { useHistory } from "react-router-dom";
import ExpandIconMinus from "../../assets/ExpandIconMinus.svg";
import ExpandIconPlus from "../../assets/ExpandIconPlus.svg";
import siteadminService from "../../services/siteadminService";
import { useDispatch, useSelector } from "react-redux";
import api from "../../services/api";
import { jsPDF } from "jspdf";

// select menu
import Sidebar from "../../components/Sidebar";
import { consoleError } from "../../utils/helper";
import { toast } from "react-toastify";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  
  width:100%;
  // min-width: ${theme.breakpoints.up("md") ? "283px" : "220px"};;
  
  
  background:white;
  border: 1px solid rgba(0, 0, 0, 0.2);

  border-radius: 2px;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  
 
  
  min-width: ${theme.breakpoints.up("md") ? "283px" : "220px"};;

  
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledGroupRoot = styled("li")`
  list-style: none;
`;

const StyledGroupHeader = styled("span")`
  display: block;
  padding: 15px 0 5px 10px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${grey[600]};
`;

const StyledGroupOptions = styled("ul")`
  list-style: none;
  margin-left: 0;
  padding: 0;

  > li {
    padding-left: 20px;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

function CustomSelect(props) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};

const CustomOptionGroup = React.forwardRef(function CustomOptionGroup(
  props,
  ref
) {
  const components = {
    Root: StyledGroupRoot,
    Label: StyledGroupHeader,
    List: StyledGroupOptions,
    ...props.components,
  };

  return <OptionGroupUnstyled {...props} ref={ref} components={components} />;
});

CustomOptionGroup.propTypes = {
  /**
   * The components used for each slot inside the OptionGroupUnstyled.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Label: PropTypes.elementType,
    List: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
};

function UnstyledSelectGrouping({ regions, handlerSelect }) {
  // -Country-
  // Denmar
  // Japan
  // Hong Kong SAR
  // Korea
  // Mainland China

  return (
    <CustomSelect sx={{ marginLeft: "-5px" }} onChange={handlerSelect}>
      <CustomOptionGroup label="-Region-">
        {regions.length > 0 &&
          regions.map((location) => (
            <StyledOption value={location?.name}>{location?.name}</StyledOption>
          ))}
      </CustomOptionGroup>
      {/* <CustomOptionGroup label='-Country-'>
        <StyledOption value='Galadriel'>Denmark</StyledOption>
        <StyledOption value='Japan'>Japan</StyledOption>
        <StyledOption value='Hong Kong SAR'>Hong Kong SAR</StyledOption>
        <StyledOption value='Korea'>Korea</StyledOption>
        <StyledOption value='Mainland China'>Mainland China</StyledOption>
      </CustomOptionGroup> */}
    </CustomSelect>
  );
}

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setClose(true);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();
  // const schools = [];
  const schools = useSelector((state) => state.siteadmin.schools);
  useEffect(() => {
    siteadminService.getSchools(dispatch);
  }, []);

  const [headCells, setHeadCells] = React.useState([
    {
      id: "school",
      label: "School",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "contact",
      label: "Contact",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "licenseStatus",
      label: "License Status",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "package",
      label: "Package",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "renewalRecord",
      label: "Renewal Record(last renewal)",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "dotVenue",
      label: "",
      numeric: false,
      disablePadding: false,
    },
  ]);

  const [headCellsExpired, setHeadCellsExpired] = React.useState([
    {
      id: "school",
      label: "School",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "contact",
      label: "Contact",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "licenseStatus",
      label: "License Status",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "renew",
      label: "Renew",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "Send Mail",
      label: "Send Mail",
      numeric: false,
      disablePadding: false,
    },
  ]);

  // useEffect(() => {
  //   checkIfUserIsLoggedInOrNot()
  // }, [isAuthenticated])

  // const checkIfUserIsLoggedInOrNot = () => {
  //   if (!isAuthenticated) {
  //     history.push('/')
  //   }
  // }

  const CustomExpandIcon = () => {
    return (
      <Box
        sx={{
          ".Mui-expanded & > .collapsIconWrapper": {
            display: "none",
          },
          ".expandIconWrapper": {
            display: "none",
          },
          ".Mui-expanded & > .expandIconWrapper": {
            display: "block",
          },
        }}
      >
        <div className="expandIconWrapper">
          <img src={ExpandIconPlus} />
        </div>
        <div className="collapsIconWrapper">
          <img src={ExpandIconMinus} />
        </div>
      </Box>
    );
  };

  const styles = (theme) => ({
    indicator: {
      backgroundColor: "white",
    },
  });
  // search by name of region
  const [searchText, setSearchText] = useState("");
  const searchByName = async (textValue) => {
    try {
      const data = await api.get(
        `${config.app.apiUrl}/api/v1/siteadmin/search/school?school=${textValue}`
      );

      if (data && data.ok) {
        setAllSchools(data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    if (searchText !== undefined && searchText !== "") {
      searchByName(searchText);
    } else {
      setAllSchools(allSchools0);
    }
  }, [searchText]);

  // get data according to licenses
  const [licenses, setLicenses] = useState([]);
  const getDataForLicense = async () => {
    try {
      const data = await api.get(
        `${config.app.apiUrl}/api/v1/siteadmin/all/license`
      );
      if (data && data.ok) {
        if (data.data.length > 0) {
          const filtered = data.data.filter(
            (datum) =>
              new Date(datum.liscenseExpiryDate).getTime() >=
              new Date().getTime()
          );
          setLicenses(filtered);
        }
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  // get all regions and countries
  const [allLocations, setAllLocations] = useState([]);
  const getAlllocations = async () => {
    try {
      const data = await api.get(
        `${config.app.apiUrl}/api/v1/siteadmin/view/all/region`
      );
      if (data && data.ok) {
        setAllLocations(data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  // get all regions of
  const [allSchools, setAllSchools] = useState([]);
  const [allSchools0, setAllSchools0] = useState([]);

  const getAllRegions = async () => {
    try {
      const data = await api.get(
        `${config.app.apiUrl}/api/v1/siteadmin/region/school`
      );
      if (data && data.ok && data.data.length > 0) {
        setAllSchools(data.data);
        setAllSchools0(data.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  // get activated regions schools
  const [allActivated, setAllActivated] = useState([]);

  const getAllRegionsActivated = async () => {
    try {
      const data = await api.get(
        `${config.app.apiUrl}/api/v1/siteadmin/region/active/school`
      );
      if (data && data.ok && data.data.length > 0) {
        setAllActivated(data.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  const [allExpired, setAllExpired] = useState([]);
  const getAllRegionsExpired = async () => {
    try {
      const data = await api.get(
        `${config.app.apiUrl}/api/v1/siteadmin/region/inactive/school`
      );
      if (data && data.ok && data.data.length > 0) {
        setAllExpired(data.data);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };
  useEffect(() => {
    getAllRegions();
    getAllRegionsActivated();
    getAllRegionsExpired();
    getAlllocations();
    getDataForLicense();
  }, []);
  // print as pdf
  const generatePdf = () => {
    const report = new jsPDF("portrait", "pt", "a4");

    report.html(document.querySelector("#licenseSection")).then(() => {
      report.save("report.pdf");
    });
  };
  // select box

  const handlerSelect = async (country, region) => {
    try {
      setCountry(country);
      setRegion(region);

      if (country.length <= 0) {
        country = "null";
      }
      if (region.length <= 0) {
        region = "null";
      }
      const data = await api.get(
        `${config.app.apiUrl}/api/v1/siteadmin/region/filter/${country}/${region}`
      );
      if (data && data.ok) {
        setAllSchools(data.data);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  // sort by alphabet
  const [ascending, setAscending] = useState(false);
  const [descending, setDescending] = useState(false);
  const changeHandler1 = () => {
    setDescending(false);
    setAscending(!ascending);
  };
  const changeHandler2 = () => {
    setAscending(false);
    setDescending(!descending);
  };
  const sorting = async () => {
    try {
      if (ascending) {
        const data = await api.get(
          `${config.app.apiUrl}/api/v1/siteadmin/region/filterSort?accSchool=true`
        );
        if (data && data.ok) {
          setAllSchools(data.accDate);
        }
      } else if (descending) {
        const data = await api.get(
          `${config.app.apiUrl}/api/v1/siteadmin/region/filterSort?descSchool=true`
        );
        if (data && data.ok) {
          setAllSchools(data.decDate);
        }
      }
    } catch (error) {
      console.error("error encountered", error);
    }
  };
  useEffect(() => {
    sorting();
  }, [ascending, descending]);

  useEffect(() => {
    handlerSelect(country, region);
  }, [country, region]);

  const [siteSettingModule, setSiteSettingModule] = useState(false);
  const [siteSettingData, setSiteSettingData] = useState({ moduleTimer: "" });
  const siteSettingModuleHandler = () => {
    setSiteSettingModule(!siteSettingModule);
  };

  const fetchSiteSettingData = async () => {
    try {
      const resData = await api.get("/api/v1/siteadmin/get/siteSetting");
      if (resData && resData.ok && resData.data) {
        setSiteSettingData(resData.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchSiteSettingData();
  }, []);

  const siteSettingChangeHandler = (e) => {
    const { name, value } = e.target;
    setSiteSettingData((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const siteSettingSaveHandler = async () => {
    try {
      const resData = await api.post("/api/v1/siteadmin/setSiteSetting", {
        time: siteSettingData.moduleTimer,
      });
      if (resData && resData) {
        toast.success(resData.message);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="heading-main">Dashboard</div>
        <div className="dashboard">
          <div className="dashboard-left">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <div className="tabs-section">
                  <Tabs
                    sx={{ width: "80%", marginBottom: "33px" }}
                    value={value}
                    indicatorColor="secondary"
                    onChange={handleChange}
                    sx={{ textTransform: "lowercase" }}
                  >
                    <Tab label="All Entries" {...SetProps(0)} />
                    <Tab label="Activated" {...SetProps(1)} />
                    <Tab label="Expired" {...SetProps(2)} />
                  </Tabs>
                  <div className="input-box-outer">
                    <input
                      className="input-box"
                      placeholder="Search"
                      type="text"
                      name="searchText"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                    <img src={search} alt="Search" />
                  </div>
                </div>
              </Box>
              {/* all starts here */}
              {allSchools && allSchools.length > 0 && (
                <TabPanel value={value} index={0}>
                  {allSchools.map((res) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          sx={{ fontSize: "12px", lineHeight: "15px" }}
                        >
                          {res?.name + ", " + res?.country}
                        </Typography>
                      </AccordionSummary>

                      {res.school && res.school.length > 0 && (
                        <AccordionDetails>
                          <Box sx={{ width: "100%" }}>
                            <Tab1Banner
                              resultData={res?.school}
                              headCells={headCells}
                            />
                          </Box>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  ))}
                </TabPanel>
              )}

              {/* Activated Starts */}
              {allActivated && allActivated.length > 0 && (
                <TabPanel value={value} index={1}>
                  {allActivated.map((res) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          sx={{ fontSize: "12px", lineHeight: "15px" }}
                        >
                          {res?.name + ", " + res?.country}
                        </Typography>
                      </AccordionSummary>

                      {res.school && res.school.length > 0 && (
                        <AccordionDetails>
                          <Box sx={{ width: "100%" }}>
                            <Tab1Banner
                              resultData={res?.school}
                              headCells={headCells}
                            />
                          </Box>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  ))}
                </TabPanel>
              )}
              {/* Activated Ends */}

              {/* Expired Starts */}
              {allExpired && allExpired.length > 0 && (
                <TabPanel value={value} index={2}>
                  {allExpired.map((res) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<CustomExpandIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          sx={{ fontSize: "12px", lineHeight: "15px" }}
                        >
                          {res?.name + ", " + res?.country}
                        </Typography>
                      </AccordionSummary>

                      {res.school && res.school.length > 0 && (
                        <AccordionDetails>
                          <Box sx={{ width: "100%" }}>
                            <Tab1Banner
                              resultData={res?.school}
                              headCells={headCellsExpired}
                              renewBtn={true}
                            />
                          </Box>
                        </AccordionDetails>
                      )}
                    </Accordion>
                  ))}
                </TabPanel>
              )}
              {/* Expired Ends */}
            </Box>
          </div>
          <div className="dashboard-right">
            <div className="btns">
              <Link to="/dashboard/new-school-entry">
                <input
                  type="button"
                  className="dashboard-btn"
                  value="New School Entry"
                />
              </Link>
              <Link to="/manage-accounts">
                <input
                  type="button"
                  className="dashboard-btn"
                  value="Manage Accounts"
                />
              </Link>
              {/* <input
                onClick={siteSettingModuleHandler}
                type="button"
                className="dashboard-btn"
                value="Site Setting"
              /> */}
            </div>
            <div className="license">
              <h3>License(s) about to expire</h3>
              <div>
                <span>{licenses.length}</span>
                <span style={{ cursor: "pointer" }} onClick={openModal}>
                  {"   "}Details
                </span>
              </div>
            </div>
            <div className="license-list">
              <div className="license-1">
                <h4>License</h4>
                <div className="license-1_inner">
                  <input type="checkbox" name="" id="" />{" "}
                  <span>List By Expiry Date</span>
                </div>
              </div>
              <div className="school">
                <h4>School</h4>
                <div>
                  <ul className="school-left list-unstyled">
                    <li>
                      <input
                        type="checkbox"
                        name="ascending"
                        checked={ascending}
                        onChange={changeHandler1}
                        // onChange={(e) => setAscending(!ascending)}
                        id=""
                      />{" "}
                      <span>A-Z</span>
                    </li>
                    <li>
                      <input
                        type="checkbox"
                        name="descending"
                        checked={descending}
                        onChange={changeHandler2}
                        // onChange={(e) => setDescending(!descending)}
                        id=""
                      />{" "}
                      <span>Z-A</span>
                    </li>
                  </ul>
                  <ul className="school-right list-unstyled">
                    <li>
                      <input type="checkbox" name="" id="" />{" "}
                      <span>4-week</span>
                    </li>
                    <li>
                      <input type="checkbox" name="" id="" />{" "}
                      <span>8-week</span>
                    </li>
                    <li>
                      <input type="checkbox" name="" id="" />{" "}
                      <span>12-week</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="country" style={{ marginTop: "13px" }}>
                <h4>Country</h4>
                <CountryDropdown
                  style={{
                    width: `100%`,
                  }}
                  value={country}
                  onChange={(val) => handlerSelect(val, "")}
                />
                {/***
                <Box>
                  <UnstyledSelectGrouping
                    regions={allLocations}
                    handlerSelect={handlerSelect}
                  />
                </Box>
                ***/}
              </div>
              <div className="region" style={{ marginTop: "13px" }}>
                <h4>Region</h4>
                <RegionDropdown
                  style={{
                    width: `100%`,
                  }}
                  country={country}
                  value={region}
                  onChange={(val) => handlerSelect(country, val)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Site setting module starts */}
        <GlobalModal
          open={siteSettingModule}
          close={siteSettingModule}
          heading="Site Setting"
          bigModel="no"
        >
          <div
            className="content-inner content-inner-license"
            id="licenseSection"
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label>Class Content Timer</label>
              <input
                type="text"
                name="moduleTimer"
                value={siteSettingData.moduleTimer}
                onChange={(e) => siteSettingChangeHandler(e)}
                placeholder="e.g:- 40:00 for 40 minutes"
              />
            </div>
          </div>
          <div className="btn-groups">
            <button className="export" onClick={siteSettingSaveHandler}>
              Save
            </button>
            <button className="cancel" onClick={siteSettingModuleHandler}>
              Cancel
            </button>
          </div>
        </GlobalModal>
        {/* Site setting module Ends*/}

        <GlobalModal
          open={open}
          close={close}
          heading="License(s) about to expire"
          bigModel="yes"
        >
          <div
            className="content-inner content-inner-license"
            id="licenseSection"
          >
            {licenses.length > 0 &&
              licenses.map((license, id) => (
                <div className="content-inner-details">
                  <div className="upper">
                    <div className="school">
                      {license?.name} {`(${license?.region?.name})`}
                    </div>
                    <div className="last-renew">
                      {/* 25 Aug 2022 */}
                      last renew:{" "}
                      {license &&
                        license.liscenseRenewDate &&
                        format(
                          new Date(license?.liscenseRenewDate),
                          "dd MMMM yyyy"
                        )}{" "}
                    </div>
                    <div className="expiry-date">
                      expiry date:{" "}
                      {license &&
                        format(
                          new Date(license?.liscenseExpiryDate),
                          "dd MMMM yyyy"
                        )}{" "}
                      <span>
                        (end in{" "}
                        {formatDistanceToNow(
                          new Date(license?.liscenseExpiryDate)
                        )}
                        )
                      </span>
                    </div>
                  </div>
                  <a
                    href={`mailto:${license.schoolAdminPrimaryDetail.email}`}
                    className="contact"
                  >
                    Contact
                  </a>
                </div>
              ))}
          </div>
          <div className="btn-groups">
            <button className="export" onClick={generatePdf}>
              Export List
            </button>
            <button className="cancel" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </GlobalModal>
      </div>
    </>
  );
};

export default Dashboard;
