import React, { useState, useEffect } from 'react'
import stemUnicornBig from '../../assets/stemUnicornBig.svg'
import lock from '../../assets/lock.svg'
import { Link, useHistory } from 'react-router-dom'
import { consoleError } from '../../utils/helper'
import api from '../../services/api'

const ForgotPassword = () => {
  let history = useHistory()

  const showPassword = () => {
    let field = document.getElementById('password')
    if (field.type === 'password') {
      field.type = 'text'
    } else {
      field.type = 'password'
    }
    // field.addEventListener('click', () => {
    //   if (field.type === 'password') {
    //     field.type = 'text'
    //   } else {
    //     field.type = 'password'
    //   }
    // })
  }

  const [pageState, setPageState] = useState('emailVerify')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  // EMAIL VERIFY
  const [email, setEmail] = useState('')
  const [key, setKey] = useState('')

  const emailVerifyHandler = async () => {
    try {
      const res = await api.post(`/api/v1/auth/forgotpassword/email/code`, {
        email: email,
      })
      if (res && res.ok) {
        setKey(res.key)
        setPageState('recoveryCode')
      }
    } catch (err) {
      consoleError(err)
      setErrorMsg(err.message)
      setTimeout(() => {
        setErrorMsg('')
      }, 3000)
    }
  }
  // END

  // Recovery Code
  const [recoveryCode, setRecoveryCode] = useState('')
  const [token, setToken] = useState('')

  const sumbitRecoveryCodeHandler = async () => {
    // console.log(recoveryCode);
    try {
      const res = await api.post(`/api/v1/auth/forgotpassword/email/token`, {
        key: key,
        code: recoveryCode,
      })
      if (res && res.ok) {
        setToken(res.token)
        setPageState('changePassword')
      }
    } catch (err) {
      consoleError(err)
      setErrorMsg(err.message)
      setTimeout(() => {
        setErrorMsg('')
      }, 3000)
    }
  }
  // End

  // Change Password
  const [password, setPassword] = useState({
    newPassword: '',
    confirmPassword: '',
  })

  const passwordValueChange = (e) => {
    const { name, value } = e.target
    setErrorMsg('')
    setPassword((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      }
    })
  }

  const passwordChageHandler = async () => {
    // console.log(password);
    if (password.newPassword !== password.confirmPassword) {
      setErrorMsg(`Password and confirm password doesn't match`)
      setTimeout(() => {
        setErrorMsg('')
      }, 3000)
      return
    }
    try {
      const res = await api.post(`/api/v1/auth/changepassword`, {
        token: token,
        newPassword: password.newPassword,
      })

      if (res && res.ok) {
        setSuccessMsg(res.message)
        setTimeout(() => {
          setSuccessMsg('')
          history.push('/')
        }, 3000)
      }
    } catch (err) {
      consoleError(err)
      setErrorMsg(err.message)
      setTimeout(() => {
        setErrorMsg('')
      }, 3000)
    }
  }
  // End

  return (
    <div className='login-container'>
      <div className='login-left'>
        <div className='logo'>
          <img src={stemUnicornBig} alt='Stem Unicorn' />
        </div>
        <div className='msg'>
          <div className='welcome-msg'>Welcome to Admin Panel</div>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            ullamcorper nisl erat, vel convallis elit fermentum pellentesque.
            Sed mollis velit facilisis facilisis viverra.
          </span>
        </div>
      </div>
      {pageState === 'emailVerify' ? (
        <div className='login-right login-right-recover'>
          <img src={lock} alt='Lock' />
          <div className='heading-forgot'>Recover Your Password</div>
          {errorMsg ? <span style={{ color: 'red' }}>{errorMsg}</span> : ''}
          <div className='form_control'>
            <label htmlFor=''>Email</label>
            <input
              type='text'
              value={email}
              name='email'
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button onClick={emailVerifyHandler}>Next</button>
        </div>
      ) : pageState === 'recoveryCode' ? (
        <div className='login-right login-right-recover'>
          <img src={lock} alt='Lock' />
          <div className='heading-forgot'>Enter Recover code</div>
          {errorMsg ? <span style={{ color: 'red' }}>{errorMsg}</span> : ''}
          <div className='form_control'>
            <label htmlFor=''>Recovery code</label>
            <input
              type='number'
              value={recoveryCode}
              name='recoveryCode'
              onChange={(e) => setRecoveryCode(e.target.value)}
            />
          </div>
          <button onClick={sumbitRecoveryCodeHandler}>Next</button>
        </div>
      ) : pageState === 'changePassword' ? (
        <div className='login-right login-right-recover'>
          <img src={lock} alt='Lock' />
          <div className='heading-forgot'>Change Password</div>
          {errorMsg ? (
            <span style={{ color: 'red' }}>{errorMsg}</span>
          ) : successMsg ? (
            <span style={{ color: 'yellowgreen' }}>{successMsg}</span>
          ) : (
            ''
          )}
          <div className='form_control'>
            <label htmlFor=''>New Password</label>
            <input
              type='password'
              value={password.newPassword}
              name='newPassword'
              onChange={(e) => passwordValueChange(e)}
            />
          </div>
          <div className='form_control'>
            <label htmlFor=''>Confirm Password</label>
            <input
              type='password'
              value={password.confirmPassword}
              name='confirmPassword'
              onChange={(e) => passwordValueChange(e)}
            />
          </div>
          <button onClick={passwordChageHandler}>Change Password</button>
        </div>
      ) : (
        ''
      )}
    </div>
  )
}

export default ForgotPassword
