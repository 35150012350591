import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Classroom from "../../components/SchoolHome/classroom";
import License from "../../components/SchoolHome/license";
import Teacher from "../../components/SchoolHome/teacher";
import SchoolAdmin from "../../components/SchoolHome/schooladmin";
import { SetProps, TabPanel } from "../../components/Tabs";
import BreadCrumb from "../../components/BreadCrumb";
import BreadCrumbList from "../../components/BreadCrumbList";
import siteadminService from "../../services/siteadminService";

import { InfoContext } from "../../components/Context/InfoContext";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
// export const InfoContext = React.createContext()
import Sidebar from "../../components/Sidebar";

export default function SchoolHome({ match }) {
  const [value, setValue] = React.useState(0);
  const { school } = match.params;
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const breadcrumbList = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "School",
      to: `/dashboard/school/${match.params.school}`,
    },
  ];

  // const { querydata } = useLocation();
  const query = localStorage.getItem("query");
  const dispatch = useDispatch();
  const particularSchool = useSelector((state) => state.siteadmin.school);
  const particularTeacher = useSelector(
    (state) => state.siteadmin.school_teacher
  );
  const particularLicense = useSelector(
    (state) => state.siteadmin.school_license
  );
  const particularSchoolAdmin = useSelector((state) => state.siteadmin.school_admin_by_school);

  React.useEffect(() => {
    function initializeAll() {
      siteadminService.getSchoolAdminBySchool(school, dispatch);
      siteadminService.getShool(school, dispatch);
      siteadminService.getShoolTeacher(school, dispatch);
      siteadminService.getShoolLicense(school, dispatch);
    }
    localStorage.setItem("schoolId", school);
    initializeAll();
  }, []);

  return (
    <InfoContext.Provider
      value={{ schoolName: match.params.school, schoolId: query ? query : "" }}
    >
      <Sidebar />
      <div className="schoolHomeMain main">
        <Box sx={{ width: "100%", padding: "38px", paddingTop: 0 }}>
          {/* <BreadCrumb url={match.url} /> */}
          <BreadCrumbList values={breadcrumbList} />

          {/* <div className='heading_main'>School</div> */}

          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div className="tabs-section">
              <Tabs value={value} onChange={handleChange}>
                <Tab label="School Admin" {...SetProps(3)} />
                <Tab label="Classroom" {...SetProps(0)} />
                <Tab label="Teacher" {...SetProps(1)} />
                <Tab label="License" {...SetProps(2)} />
              </Tabs>
            </div>
          </Box>
          <TabPanel value={value} index={0}> 
            <SchoolAdmin resultData={particularSchoolAdmin} school={school} />            
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Classroom resultData={particularSchool} school={school} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Teacher resultData={particularTeacher} school={school} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <License resultData={particularLicense} school={school} />
          </TabPanel>
        </Box>
      </div>
    </InfoContext.Provider>
  );
}
