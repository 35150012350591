import {
  Backdrop,
  Box,
  Button,
  Fade,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import ErrorMessage from '../../components/ErrorMessage'
import { jsPDF } from 'jspdf'

// import { InfoContext } from '../../pages/siteadmin/SchoolHome'
import { InfoContext } from '../Context/InfoContext'
import React, { useState, useContext, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import CancelButton from '../Buttons/CancelButton'
import ConfirmButton from '../Buttons/ConfirmButton'
import TableMain from '../Table'
import { WarningModal, GlobalModal } from '../Modal'
import adminService from '../../services/siteadminService'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../services/api'
import config from '../../config'
import siteadminService from "../../services/siteadminService"

function createData(name, size, progress, mean, teacher) {
  return {
    name,
    size,
    progress,
    mean,
    teacher,
  }
}

const headCells = [
  {
    id: 'class',
    label: 'Class',
    numeric: false,
    disablePadding: false,
  },

  { id: 'size', label: 'Size', numeric: false, disablePadding: false },

  {
    id: 'progress',
    label: 'Progress',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'mean',
    label: 'Mean scores',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'teacher',
    label: 'Assigned Teacher',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'actions',
    label: 'Actions',
    numeric: false,
    disablePadding: false,
  },
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 3,
  borderRadius: 3,
  width: '45%',
}

export default function Classroom({
  resultData,
  school,
  refresh,
  setRefresh,
}) {
  const [open, setOpen] = React.useState(false)
  const [open1, setOpen1] = React.useState(false)
  const [name, setClassName] = useState()
  const [size, setClassSize] = useState()
  const dispatch = useDispatch()

  const addClassroom = () => setOpen(true)
  const exportReport = () => {
    setOpen1(true)
  }

  const handleClose = () => setOpen(false)
  const handleClose1 = () => setOpen1(false)

  const [teachervalue, setTeacherValue] = useState('')

  const handleChange = (e) => {
    setTeacherValue(e.target.value)
  }

  const schoolName = useContext(InfoContext).schoolName
  const schoolId = useContext(InfoContext).schoolId

  const navigation = useHistory()

  const InspectOn = (e) => {
    navigation.push({
      pathname: `/dashboard/content/${schoolName}`,
      state: {
        name: e.target.textContent,
        school: schoolName,
      },
    })
  }
  const [errors, setErrors] = useState({})
  const validateForm = (values) => {
    let errors = {}
    if (!values.name) {
      errors.name = 'Class Name is Required'
    }
    if (!values.size) {
      errors.size = 'Class Size is Required'
    }
    if (!values.teachervalue) {
      errors.teachervalue = 'Teacher is Required'
    }

    return errors
  }
  const onAddClassroom = async (e) => {
    e.preventDefault()

    const events = {
      name,
      teachervalue,
      size,
    }
    try {
      setErrors(validateForm(events))
      if (Object.keys(validateForm(events)).length > 0) {
        return
      } else
        await adminService.addClassrooms(
          {
            className: name,
            classroomSize: size,
            teacher: teachervalue,
            school: school,
          },
          dispatch
        )
      await adminService.getShool(school, dispatch)

      handleClose()
      setClassName('')
      setClassSize('')
      setTeacher('')
    } catch (err) {
      console.error(err)
      setMessage(err.message)
    }
  }
  // fetch all teacher of the school
  const [message, setMessage] = useState('')
  const [pdfPrint, setPdfPrint] = useState(false)
  const teachers = useSelector((state) => state.siteadmin.school_teacher);
  
  useEffect(() => {
    siteadminService.getShoolTeacher(school, dispatch);
  }, [])

  const changeHandler = () => {
    setPdfPrint(!pdfPrint)
  }
  // print as pdf format
  const generatePdf = () => {
    const report = new jsPDF('landscape', 'pt', 'a3')

    report.html(document.querySelector('#classroomPrintSection')).then(() => {
      report.save('report.pdf')
    })
    handleClose1()
  }
  return (
    <div>
      <GlobalModal open={open1} close={handleClose1} heading='Export Report(s)'>
        <div className='middle-content-export'>
          <div className='group'>
            <input
              type='checkbox'
              name='pdfPrint'
              value={pdfPrint}
              checked={pdfPrint}
              onChange={changeHandler}
            />
            <label htmlFor=''>Whole class performance (.pdf)</label>
          </div>
          <div className='group'>
            <input type='checkbox' name='' id='' />
            <label htmlFor=''>Individual reports (.zip)</label>
          </div>
        </div>
        <br />
        <div className='confirm-close-btns'>
          <ConfirmButton>
            <div className='open-btn' onClick={generatePdf}>
              Confirm
            </div>
          </ConfirmButton>
          <CancelButton>
            <div className='close-btn' onClick={handleClose1}>
              Cancel
            </div>
          </CancelButton>
        </div>
      </GlobalModal>
      <div style={{ float: 'right', marginBottom: 20 }}>
        <button onClick={InspectOn} className='school-btn'>
          Languages / Content management
        </button>
        <br />
        <div className='school-btn-sm-outer'>
          <button onClick={exportReport} className='school-btn school-btn-sm'>
            Export report
          </button>

          <div onClick={addClassroom}>
            <button className='school-btn school-btn-sm'> Add Classroom</button>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              sx={{ textAlign: 'center', color: 'black', fontWeight: 'bold' }}
              variant='h6'
              component='h2'
            >
              New Classroom
            </Typography>
            {message && <ErrorMessage message={message} type='danger' />}

            <form>
              <Typography
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  mb: 5,
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex' }}>
                    <label style={{ marginTop: 15, marginRight: 10 }}>
                      Classroom name{' '}
                    </label>{' '}
                    <TextField
                      label='Enter name'
                      type={'text'}
                      required
                      value={name}
                      onChange={(e) => setClassName(e.target.value)}
                      variant='outlined'
                      style={{ width: '170px' }}
                    />
                  </div>
                  <ErrorMessage message={errors.name} type='danger' />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{ display: 'flex' }}>
                    <label style={{ marginTop: 15, marginRight: 10 }}>
                      Classroom size
                    </label>{' '}
                    <TextField
                      label='Enter size'
                      type={'number'}
                      required
                      value={size}
                      onChange={(e) => setClassSize(e.target.value)}
                      variant='outlined'
                      style={{ width: '170px' }}
                    />
                  </div>
                  <ErrorMessage message={errors.size} type='danger' />
                </div>
              </Typography>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                  <label style={{ marginTop: 15, marginRight: 15 }}>
                    Assign Teacher
                  </label>
                  <Select
                    style={{
                      width: '30%',
                      // marginBottom: 40,
                      marginBottom: 5,
                    }}
                    name="teachervalue"
                    value={teachervalue}
                    label=' '
                    onChange={handleChange}
                    displayEmpty
                    required
                  >
                    {teachers.length > 0 &&
                      teachers.map((teacher, id) => (
                        <MenuItem value={teacher._id}>
                          {teacher?.name}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <ErrorMessage message={errors.teachervalue} type='danger' />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 280,
                  marginLeft: '33%',
                }}
              >
                <div onClick={onAddClassroom}>
                  <ConfirmButton>
                    <div className='open-btn'>Confirm</div>
                  </ConfirmButton>
                </div>

                <CancelButton>
                  <div className='close-btn' onClick={handleClose}>
                    Cancel
                  </div>
                </CancelButton>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>

      <TableMain
        headCells={headCells}
        resultData={resultData}
        topic='classroom'
        school={school}
      />
    </div>
  )
}
