import React, { useState, useEffect, Fragment } from "react";
import { Divider } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../components/Tabs";
import { SetProps } from "../../components/Tabs";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chart from "react-apexcharts";
import { useSelector, useDispatch } from "react-redux";
import adminService from "../../services/siteadminService";
import Modal from "@mui/material/Modal";
import { AnalyticsModal } from "../../components/Modal";
import { Link } from "react-router-dom";
import AnalyticsSystem from "../../components/Analytics/AnalyticsSystem";
import AnalyticsMedia from "../../components/Analytics/AnalyticsMedia";
import AnalyticsGame from "../../components/Analytics/AnalyticsGame";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
// import { Line } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import Sidebar from "../../components/Sidebar";
import LineChart from "../../components/Analytics/distributionCurve/LineChart";
import api from "../../services/api";
import ReactApexChart from "react-apexcharts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement
);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  width: "1129px",
  height: "600px",

  background: "#FFFFFF",
  borderRadius: "7px",
};
const options = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    yAxes: {
      display: false,
      ticks: {
        display: false,
      },
      grid: {
        display: false,
        zeroLineColor: "transparent",
      },
    },
    xAxes: {
      display: true,
      ticks: {
        display: true,
      },
      grid: {
        display: false,
        zeroLineColor: "transparent",
      },
    },
  },

  plugins: {
    legend: {
      position: "bottom",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

// options object for modal
const optionsWeeklyModal = {
  responsive: true,
  maintainAspectRatio: true,
  elements: {
    point: {
      radius: 1,
    },
  },
  scales: {
    y1: {
      display: true,
      position: "left",
      title: {
        display: true,
        text: "Time Taken",
      },
      ticks: {
        display: true,
      },
      grid: {
        display: false,
        zeroLineColor: "transparent",
      },
    },

    yAxes: {
      display: true,
      position: "right",
      ticks: {
        display: true,
      },
      title: {
        display: true,
        text: "Correct Rate",
      },
      grid: {
        display: false,
        zeroLineColor: "transparent",
      },
    },
    xAxes: {
      display: true,
      ticks: {
        display: true,
      },
      title: {
        display: true,
        text: "Questions",
      },
      grid: {
        display: false,
        zeroLineColor: "transparent",
      },
    },
  },

  plugins: {
    legend: {
      position: "bottom",
      display: true,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};
export const options2 = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point: {
      radius: 2,
    },
  },
  scales: {
    yAxes: {
      display: true,
      ticks: {
        display: false,
      },
      gridLines: {
        display: true,
        zeroLineColor: "transparent",
      },
      zeroLineColor: "transparent",
    },
    xAxes: {
      display: false,
      ticks: {
        display: false,
      },
      gridLines: {
        display: true,
        zeroLineColor: "transparent",
      },
    },
  },

  plugins: {
    legend: {
      position: "bottom",
      display: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const labels1 = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

const series = [70];
const options1 = {
  chart: {
    type: "radialBar",
    height: "220px",
    width: "220px",
  },

  plotOptions: {
    radialBar: {
      hollow: {
        size: "70%",
      },
      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -20,
          show: true,
          color: "#000000",
          lineHeight: "15px",

          fontSize: "12px",
          fontWeight: "400",
        },
        value: {
          color: "#000000",

          show: true,
          fontWeight: 400,
          fontSize: "36px",
          lineHeight: "44px",
        },
      },
      track: {
        show: true,
        startAngle: undefined,
        endAngle: undefined,
        background: "#979797",
        strokeWidth: "97%",
        opacity: 1,
        margin: 5,
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
          opacity: 0.5,
        },
      },
    },
  },
  colors: ["#363636", "#0090FF"],
  stroke: {
    lineCap: "round",
  },
  labels: ["Average Score"],
};

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [10, 15, 20, 5, 30, 150, 90, 20, 70, 5],
      borderColor: "black",
    },
  ],
};
// for radial
const radialOptions = {
  chart: {
    type: "radialBar",
    height: "220px",
    width: "220px",
  },

  plotOptions: {
    radialBar: {
      hollow: {
        size: "70%",
      },
      dataLabels: {
        showOn: "always",
        name: {
          offsetY: -20,
          show: true,
          color: "#000000",
          lineHeight: "15px",

          fontSize: "12px",
          fontWeight: "400",
        },
        value: {
          color: "#000000",

          show: true,
          fontWeight: 400,
          fontSize: "36px",
          lineHeight: "44px",
        },
      },
      track: {
        show: true,
        startAngle: undefined,
        endAngle: undefined,
        background: "#979797",
        strokeWidth: "97%",
        opacity: 1,
        margin: 5,
        dropShadow: {
          enabled: false,
          top: 0,
          left: 0,
          blur: 3,
          opacity: 0.5,
        },
      },
    },
  },
  colors: ["#DC4C8F", "#ffffff"],
  stroke: {
    lineCap: "round",
  },
  labels: ["Average Score"],
};
export const data1 = {
  labels: labels1,
  datasets: [
    {
      label: "Dataset 1",
      data: [25, 10, 15, 50, 50, 40, 40, 20, 11, 5],
      borderColor: "#BCBCBC",
      backgroundColor: "#BCBCBC",
      tension: 0.5,
    },
  ],
};

const data4 = {
  labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  datasets: [
    {
      type: "line",
      label: "Bar Dataset",
      data: [10, 20, 30, 40, 110, 190, 300, 201, 202, 0],
      borderColor: "rgba(54, 54, 54, 0.4)",
      backgroundColor: "rgba(54, 54, 54, 0.4)",
      borderWidth: 2,

      // pointRadius: 0,
    },
    {
      type: "line",
      label: "Line Dataset",
      data: [5, 10, 10, 40, 30, 60, 40, 30, 100, 20],
      fill: false,
      borderColor: "rgb(54, 162, 235)",
      borderWidth: 2,

      // pointRadius: 0,
    },
    {
      type: "line",
      label: "Line Dataset",
      data: [5, 10, 10, 40, 30, 60, 40, 30, 100, 20],
      fill: false,
      borderColor: "rgb(54, 162, 235)",
      borderWidth: 2,
      yAxisID: "y1",

      // pointRadius: 0,
    },
  ],
};
const Analytics = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const gameParameters = useSelector((state) => state.siteadmin.gameParameters);
  const mostPickedCharacter = useSelector(
    (state) => state.siteadmin.characterMostPicked
  );
  const systemRecords = useSelector((state) => state.siteadmin.systemRecords);
  const regions = useSelector((state) => state.siteadmin.regions);
  // set the first region as default region
  const [region, setRegion] = useState();
  const [school, setSchool] = useState([]);
  useEffect(() => {
    if (regions && regions.length > 0) {
      setRegion(regions[0]._id);
    }
  }, [regions]);
  // fetch all schools belonging to a particular region
  const getAllSchoolByRegions = async () => {
    try {
      const data = await api.get(
        `/api/v1/siteadmin/view/all/school/by-region/${region}`
      );
      if (data && data.ok) {
        setSchool(data.data);
        if (data.data.length > 0) {
          setSelectedSchool(data.data[0]._id);
        } else {
          setSelectedSchool();
          // setRegion()
          // setWeeklyData()
        }
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    if (region) {
      getAllSchoolByRegions();
    }
  }, [region]);
  // useEffect(() => {
  //   if (selectedSchool) {
  //     setWeeklyData()
  //   }
  // }, [selectedSchool])
  const [allClasses, setAllClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState();
  const [avgTime, setAvgTime] = useState(0);

  const getAvgtime = async () => {
    try {
      const res = await api.get(
        `api/v1/siteadmin/average/time/${selectedClass}`
      );
      if (res && res.ok) {
        function seconds_to_days_hours_mins_secs_str(seconds) {
          var days = Math.floor(seconds / (24 * 60 * 60));
          seconds -= days * (24 * 60 * 60);
          var hours = Math.floor(seconds / (60 * 60));
          seconds -= hours * (60 * 60);
          var minutes = Math.floor(seconds / 60);
          seconds -= minutes * 60;
          return (
            (0 < days ? days + " day, " : "") +
            (0 < hours ? hours + "hour, " : "") +
            (0 < minutes ? minutes + "m " : "") +
            seconds.toFixed(1) +
            "s"
          );
        }
        const time = seconds_to_days_hours_mins_secs_str(
          res.avgTimeSpent.toFixed(1)
        );
        setAvgTime(time);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const [grades, setGrades] = useState();
  const getoverallGrad = async () => {
    try {
      const res = await api.get(
        `api/v1/siteadmin/overall/grade/${selectedClass}`
      );
      if (res && res.ok) {
        setGrades(res.data.toFixed(2));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectRegion = (e) => {
    const { value } = e.target;
    setRegion(value);
  };
  const [selectedSchool, setSelectedSchool] = useState();
  const handleSelectSchool = (e) => {
    const { value } = e.target;
    setSelectedSchool(value);
  };
  const handleSelectClass = (e) => {
    const { value } = e.target;
    setSelectedClass(value);
  };

  useEffect(() => {
    adminService.getAverage(dispatch);
    adminService.getGameParameters(dispatch);
    adminService.getMostPickedCharacter(dispatch);

    adminService.getSystemRecords(dispatch);
    adminService.getRegions(dispatch);
  }, [dispatch]);
  // useEffect(() => {
  //   if (selectedClass) {
  //     getAvgtime()
  //     getoverallGrad()
  //   }
  // }, [selectedClass])

  const [weekIs, setWeekIs] = useState();
  const openModal = (id) => {
    setOpen(true);
    setWeekIs(id);
  };

  const closeModal = () => {
    setOpen(false);
  };

  // get weekly data results
  const [weeklyData, setWeeklyData] = useState();
  const getWeeklyData = async () => {
    try {
      const data = await api.get(
        `/api/v1/siteadmin/weekly/performance/${region}/${selectedSchool}/${selectedClass}`
      );
      if (data && data.ok) {
        setWeeklyData(data);
      }
    } catch (error) {
      console.error("error");
    }
  };
  useEffect(() => {
    if (region && selectedSchool && selectedClass) {
      getWeeklyData();
    } else {
      setWeeklyData();
    }
  }, [region, selectedSchool, selectedClass]);
  // bar data
  const barData = (data, index) => {
    let labels = [];
    let datas = [];
    if (data.length > 0) {
      data.map((datum, idx) => {
        labels.push(idx + 1);
        datas.push(parseInt(datum?.noOfCorrrect));
      });
      //     labels: labels1,
      // datasets: [
      //   {
      //     label: 'Dataset 1',
      //     data: [25, 10, 15, 50, 50, 40, 40, 20, 11, 5],
      //     borderColor: '#BCBCBC',
      //     backgroundColor: '#BCBCBC',
      //     tension: 0.5,
      //   },
      // ],
    }

    return {
      labels,
      datasets: [
        {
          label: "No Of Correct",
          data: datas,
          borderColor: "#0090FF",
          backgroundColor: "#0090FF",
          tension: 0.5,
          borderRadius: 35,
          borderSkipped: false,
        },
      ],
    };
  };
  // distribution
  const [avgDistributiondata, setavgDistributiondata] = useState({
    labels: [],
    datasets: [],
  });
  const averageCorrectDistribution = async () => {
    try {
      const data = await api.get(
        `/api/v1/siteadmin/average/correct-rate/curve/${selectedClass}`
      );
      if (data && data.ok) {
        let labels = [];
        let data1 = [];
        if (data?.data[0].length > 0 && data?.data[1].length > 0) {
          labels = [...data?.data[1]];
          data.data[0].map((datum) => {
            data1.push(datum !== null ? datum * 100 : 0);
          });
          // data1 = [...data?.data[0]]

          setavgDistributiondata({
            labels: labels,
            datasets: [
              {
                label: "Correct Rate",
                data: data1,
                borderColor: "transparent",
                // backgroundColor: gradientValue,
                borderColor: "#EF5B2C",

                fill: true,
                tension: 0.5,
              },
            ],
          });
        }
        // setavgDistributiondata(data.data)
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const [avgQuizPerformance, setavgQuizPerformance] = useState({
    labels: [],
    data: [],
  });
  const averageQuizPerformance = async () => {
    try {
      const data = await api.get(
        `/api/v1/siteadmin/overall/quiz/curve/${selectedClass}`
      );
      if (data && data.ok) {
        let labels = [];
        let data1 = [];
        if (data.data[0].length > 0) {
          // labels = [...data?.data[1]]

          data?.data[0].map((datum, idx) => {
            labels.push(
              datum?.user[0]?.name ? datum?.user[0]?.name : "someone"
            );
            data1.push(datum.avgGrade ? datum.avgGrade : 0);
          });
          // data1 = [...data?.data[0]]
          setavgQuizPerformance({
            labels: labels,
            data: data1,
          });
        } else {
          setavgQuizPerformance({ labels: [], data: [] });
        }
        // setavgDistributiondata(data.data)
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  const [weeklyModelData, setweeklyModelData] = useState({
    labels: [],
    datasets: [],
  });
  useEffect(() => {
    if (selectedClass) {
      averageCorrectDistribution();
      averageQuizPerformance();
      getAvgtime();
      getoverallGrad();
    } else {
      setavgDistributiondata({
        labels: [],
        datasets: [],
      });
      setavgQuizPerformance({
        labels: [],
        data: [],
      });
      setAvgTime();
      setGrades();
    }
  }, [selectedClass]);
  const weekPerformance = async () => {
    try {
      const data = await api.get(
        `/api/v1/siteadmin/weekly/performance/summary/analytics/${selectedSchool}/${weekIs}`
      );
      if (data && data.ok) {
        let labels = [];
        let data1 = [];
        let data2 = [];
        if (data?.averageTime.length > 0 && data?.totalAvgCorrect.length > 0) {
          data.averageTime.map((timus, index) => {
            labels.push(index + 1);
            data1.push(timus.time ? timus.time : 0);
          });
          data.totalAvgCorrect.map((question, idx) =>
            data2.push(question !== null ? question * 100 : 0)
          );
          // data2 = [...data?.totalAvgCorrect]

          setweeklyModelData({
            labels: labels,
            datasets: [
              {
                type: "line",
                label: "Time Taken",
                data: data1,
                fill: false,
                borderColor: "rgb(54, 162, 235)",
                backgroundColor: "rgb(54, 162, 235)",

                borderWidth: 2,
                yAxisID: "y1",

                pointRadius: 1,
              },
              {
                type: "line",
                label: "Correct Rate",
                data: data2,

                borderColor: "rgba(239, 91, 44, 0.4)",
                backgroundColor: "rgba(239, 91, 44, 0.4)",
                borderWidth: 2,
                fill: false,

                pointRadius: 1,
                yAxisID: "yAxes",
              },
            ],
          });
        } else {
          setweeklyModelData({
            labels: [],
            datasets: [],
          });
        }
        // setavgDistributiondata(data.data)
      }
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    if (selectedSchool && weekIs) {
      weekPerformance();
    }
  }, [selectedSchool, weekIs]);
  // get all classes

  const getAllClasses = async () => {
    try {
      const data = await api.get(
        `/api/v1/siteadmin/view/all/class/${selectedSchool}`
      );
      if (data && data.ok) {
        setAllClasses(data.classRooms);
        if (data.classRooms.length > 0) {
          setSelectedClass(data.classRooms[0]._id);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (selectedSchool) {
      getAllClasses();
    }
  }, [selectedSchool]);

  // change color
  const changeColor = (index) => {
    if (index % 2 === 0) {
      return { background: "#DB4C86" };
    } else {
      return { background: "#6A51BE" };
    }
  };

  const barData2 = (data) => {
    let labels = [];
    let datas = [];
    data.labels.map((labelData) => {
      labels.push(labelData);
    });
    data?.datasets[0]?.data.map((percentageData) => {
      datas.push(percentageData);
    });
    return {
      labels,
      datasets: [
        {
          label: "Average correct rate",
          data: datas,
          borderColor: "#0090FF",
          backgroundColor: "#0090FF",
        },
      ],
    };
  };

  const optionsForACRD = {
    plugins: {
      title: {
        display: false,
        text: "Average Correct Rate",
      },
      legend: {
        position: "bottom",
        display: false,
      },
    },
    responsive: true,
    scales: {
      y1: {
        display: true,
        position: "left",
        title: {
          display: true,
          text: "Average student results",
        },
        ticks: {
          display: true,
        },
        grid: {
          display: true,
          zeroLineColor: "transparent",
        },
      },
    },
  };

  const QOAPOption = {
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      width: 0.2,
    },
  };

  return (
    <>
      <Sidebar />

      <div className="main analyticsMain">
        <div className="heading-main">Analytics</div>
        <Box sx={{ width: "100%", padding: "10px 10px 30px 45px" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div className="tabs-section">
              <Tabs
                sx={{ width: "100%", textTransform: "lowercase" }}
                value={value}
                indicatorColor="secondary"
                onChange={handleChange}
              >
                <Tab label="Quiz" {...SetProps(0)} />
                <Tab label="Media" {...SetProps(1)} />
                <Tab label="Game" {...SetProps(2)} />
                <Tab label="System" {...SetProps(2)} />
              </Tabs>
            </div>
          </Box>
          <TabPanel value={value} index={0}>
            <div className="tabpanel-content">
              <span className="header">Quiz questions analytics</span>
              <div className="input-box-outermost">
                <select
                  onChange={handleSelectRegion}
                  className="input-box newSelect"
                  style={{ padding: 5 }}
                  value={region}
                >
                  <option value="">Select Country</option>
                  {regions?.map((region, idx) => {
                    return (
                      <Fragment key={idx}>
                        <option value={region?._id}>{region.name}</option>
                      </Fragment>
                    );
                  })}
                </select>

                <select
                  onChange={handleSelectSchool}
                  className="input-box newSelect"
                  style={{ padding: 5 }}
                  value={selectedSchool}
                >
                  <option key="-1" value="">Select School</option>
                  {school.length > 0 &&
                    school.map((schoolIs, idx) => {
                      return (
                        <Fragment key={idx}>
                          <option value={schoolIs?._id}>{schoolIs.name}</option>
                        </Fragment>
                      );
                    })}
                </select>
                <select
                  // onChange={handleSelectSchool}
                  onChange={handleSelectClass}
                  className="input-box newSelect"
                  style={{ padding: 5 }}
                  value={selectedClass}
                >
                  <option key="-1" value="">Select Class</option>
                  {allClasses.length > 0 &&
                    allClasses.map((schoolIs, idx) => {
                      return (
                        <Fragment key={idx}>
                          <option value={schoolIs?._id}>
                            {schoolIs?.className}
                          </option>
                        </Fragment>
                      );
                    })}
                </select>
              </div>
              <div className="top-section">
                <div className="top-section-left ">
                  <div className="top-section-left-top spacing">
                    <div className="upper">Average grade</div>
                    <div className="lower">{grades && grades} %</div>
                  </div>
                  <div className="top-section-left-bottom spacing ">
                    <div className="upper">
                      Average time spent on each module
                    </div>
                    <div className="lower">{avgTime && avgTime}</div>
                  </div>
                </div>
                <div className="top-section-middle spacing">
                  <div className="upper">Average correct rate</div>
                  <div className="lower">
                    {/* <Line
                      options={options2}
                      data={avgDistributiondata && avgDistributiondata}
                    /> */}
                    <Bar
                      options={optionsForACRD}
                      data={barData2(avgDistributiondata)}
                    />
                  </div>
                </div>
                <div className="top-section-right spacing">
                  <div className="upper">Overall Quiz Performance</div>
                  {/* <div className="lower"> */}

                  {/* <Line
                      options={options2}
                      data={avgQuizPerformance && avgQuizPerformance}
                      // data={data}
                    /> */}
                  <ReactApexChart
                    options={{
                      QOAPOption,
                      labels: avgQuizPerformance.labels,
                    }}
                    series={avgQuizPerformance.data}
                    type="pie"
                    width={400}
                  />
                  {/* </div> */}
                </div>
              </div>
            </div>

            <div className="bottom-section">
              <div className="left">
                <span className="performance">Module Peformance</span>
                {/* <button className='btn-analytics'>Summary</button> */}
              </div>
              <div className="right">
                {/* <div className='right-top'>
                  <div className='group'>
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: '#414141',
                        },
                      }}
                    />
                    <span>All Entries</span>
                  </div>
                  <div className='group'>
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: '#414141',
                        },
                      }}
                    />
                    <span>Region</span>
                  </div>{' '}
                  <div className='group'>
                    <Checkbox
                      sx={{
                        '&.Mui-checked': {
                          color: '#414141',
                        },
                      }}
                    />
                    <span>School</span>
                  </div>
                </div> */}
                <div className="right-bottom"></div>
              </div>
            </div>
            <AnalyticsModal
              // type='upload'
              open={open}
              close={closeModal}
              heading="week1 questions analytics"
            >
              <Bar
                data={weeklyModelData && weeklyModelData}
                options={optionsWeeklyModal}
              />
              <div className="back-btn-1">
                <button
                  className="btn-analytics"
                  onClick={closeModal}
                  style={{
                    background: "#625F5F",
                    borderRadius: "6px",
                    color: "white",
                  }}
                >
                  Back
                </button>
              </div>
            </AnalyticsModal>
            <div className="most-bottom">
              {weeklyData &&
                weeklyData.quizResponseNew &&
                weeklyData.quizResponseNew.length > 0 &&
                weeklyData.quizResponseNew.map((week, index) => (
                  <div key={index} className="most-bottom-left">
                    <div className="header-section">
                      <div className="header">{week?.title} </div>
                      <div className="middle-content">
                        <button
                          className={`btn-analytics ${
                            index % 2 === 0
                              ? "btn-analytics-new"
                              : "btn-analytics-new1"
                          }`}
                          onClick={() => openModal(week._id)}
                        >
                          Summary
                        </button>
                        <Link
                          to={`/analytics/quiz/${selectedSchool}/${week?.title}/${week._id}`}
                        >
                          <button
                            className={`btn-analytics ${
                              index % 2 === 0
                                ? "btn-analytics-new"
                                : "btn-analytics-new1"
                            }`}
                          >
                            Details
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="middle-section">
                      <div className="left">
                        <Chart
                          options={
                            index % 2 === 0
                              ? radialOptions
                              : {
                                  ...radialOptions,
                                  colors: ["#6A51BE", "#ffffff"],
                                }
                          }
                          series={[
                            (weeklyData?.totalAvgCorrect[index] * 100).toFixed(
                              1
                            ),
                          ]}
                          type="radialBar"
                          height={220}
                          width={220}
                        />
                        <div className="info">
                          <span>Average Time Spent</span>
                          <span>
                            {week?.time?.avgOverallTime
                              ? week?.time?.avgOverallTime.toFixed(1)
                              : 0}{" "}
                            seconds
                          </span>
                        </div>
                        <div className="info">
                          <span>Avg. time / question</span>
                          <span>
                            {week?.time?.avgQuizTime
                              ? (week?.time?.avgQuizTime - 1).toFixed(1)
                              : 0}{" "}
                            seconds
                          </span>
                        </div>
                      </div>
                      <div className="right">
                        <div className="heading">Questions Performance</div>

                        <Bar
                          options={options}
                          data={barData(week?.quiz, index)}
                          // data={data1}
                          width={500}
                          height={180}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <AnalyticsMedia />
          </TabPanel>{" "}
          <TabPanel value={value} index={2}>
            <AnalyticsGame
              gameParameter={gameParameters}
              mostPickedCharacter={mostPickedCharacter}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <AnalyticsSystem systemRecords={systemRecords} />
          </TabPanel>
        </Box>
      </div>
    </>
  );
};

export default Analytics;
