import React, { useState } from "react";
import audio from "../../../assets/audio.svg";
import camera from "../../../assets/camera.svg";
import removeIcon from "../../../assets/removeIcon.svg";
import uploadIconWhite from "../../../assets/uploadIconWhite.svg";
import imageIcon from "../../../assets/imageIcon.svg";
import plus from "../../../assets/plus.svg";
import { GlobalModal } from "../../Modal";
import warning from "../../../assets/warning.svg";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../Tabs";
import { SetProps } from "../../Tabs";
import api from "../../../services/api";

const MediaContainer = () => {
  const array = [
    {
      id: 1,
      type: "image",
    },
    {
      id: 2,
      type: "audio",
    },
    {
      id: 3,
      type: "image",
    },
    {
      id: 4,
      type: "image",
    },
    {
      id: 5,
      type: "audio",
    },
    {
      id: 6,
      type: "image",
    },
    {
      id: 7,
      type: "image",
    },
    {
      id: 8,
      type: "audio",
    },
    {
      id: 9,
      type: "image",
    },
    {
      id: 10,
      type: "image",
    },
    {
      id: 11,
      type: "audio",
    },
    {
      id: 12,
      type: "image",
    },
    {
      id: 13,
      type: "image",
    },
    {
      id: 14,
      type: "audio",
    },
    {
      id: 15,
      type: "image",
    },
  ];

  // ADD NEW MODEL STATE STARTS
  const [addModelState, setAddModelState] = useState(false);

  const [value, setValue] = React.useState(0);
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [uploadingImage, setUploadingImage] = useState(false); 

  const openModelHandler = () => {
    setAddModelState(true);
  };

  const closeModelHandler = () => {
    setAddModelState(false);
  };

  const [choosenImage, setChooseImage] = useState("");
  const [choosenMusic, setChoosenMusic] = useState("");
  const uploadImage = async (e) => {
    setChooseImage(e.target.files[0].name);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('bgImg', file);
    try {
      setUploadingImage(true);
      const res = await api.post(`/api/v1/siteadmin/upload/bg-img`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // values.image = image_New_Name;
      //setTempImage(image_New_Name);
      setUploadingImage(false);
    } catch (error) {
      console.error(error);
      setUploadingImage(false);
    }
  };

  const uploadMusic = async (e) => {
    setChoosenMusic(e.target.files[0].name);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('music', file);
    try {
      setUploadingImage(true);
      const res = await api.post(`/api/v1/siteadmin/upload/music`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      // values.image = image_New_Name;
      //setTempImage(image_New_Name);
      setUploadingImage(false);
    } catch (error) {
      console.error(error);
      setUploadingImage(false);
    }
  };
  // ADD NEW MODEL STATE ENDS

  // EDIT/UPLOAD NEW MEDIA MODEL STATE STARTS
  const [updateMediaModelState, setUpdateMediaModelState] = useState(false);

  const openUpdateModelHandler = () => {
    setUpdateMediaModelState(true);
  };

  const closeUpdateModelHandler = () => {
    setUpdateMediaModelState(false);
  };

  // EDIT/UPLOAD NEW MEDIA MODEL STATE ENDS

  return (
    <>
      <div className="btnDiv">
        <button onClick={openModelHandler}>
          <img src={uploadIconWhite} alt="upoadIcon" />
          Add New
        </button>
      </div>
      <div className="mediaContainerDiv">
        {array.map((d, index) => {
          return (
            <div
              className="cardDiv"
              key={index}
              onClick={openUpdateModelHandler}
            >
              <div className="removeMedia">
                <img src={removeIcon} alt="remove" />
              </div>
              <img
                className="mediaIcon"
                src={d.type === "image" ? camera : audio}
                alt="icon"
              />
            </div>
          );
        })}
      </div>

      {/* ADD MEDIA MODEL STARTS */}
      <GlobalModal
        type="upload"
        open={addModelState}
        close={closeModelHandler}
        heading="Media Upload"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div className="tabs-section tabs-section-upload">
              <Tabs
                sx={{
                  width: "80%",
                  marginBottom: "33px",
                  textTransform: "lowercase",
                }}
                value={value}
                indicatorColor="green"
                onChange={handleChange}
              >
                <Tab label="IMAGE" {...SetProps(0)} />
                <Tab label="BACKGROUND" {...SetProps(1)} />
                <Tab label="MUSIC" {...SetProps(2)} />
              </Tabs>
            </div>
          </Box>
          <TabPanel value={value} index={0} sx={{ position: "relative" }}>
            <div className="image-upload">
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={imageIcon} alt="" />
                </label>
                <span>Upload IMAGE</span>

                <input
                  onChange={(e) => uploadImage(e)}
                  type="file"
                  class="input-file"
                  id="input_file"
                />

                <span>{choosenImage}</span>
                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Elementum,
                </span>
              </div>
            </div>
            <div className="newEntryBottom-wrapper">
              <div disabled={uploadingImage} className="btn-next" onClick={() => setValue(1)}>
                Next
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} sx={{ position: "relative" }}>
            <div className="image-upload">
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={imageIcon} alt="Upload Video" />
                </label>
                <span>Uplode BACKGROUND</span>
                <input
                  onChange={(e) => uploadImage(e)}
                  type="file"
                  class="input-file"
                  id="input_file"
                />

                <span>{choosenImage}</span>
                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Elementum,
                </span>
              </div>
            </div>
            <div className="newEntryBottom-wrapper">
              <div
                className="btn-next"
                style={{ background: "#9D9D9D" }}
                onClick={() => setValue(2)}
              >
                Next
              </div>
            </div>
          </TabPanel>{" "}
          <TabPanel value={value} index={2} sx={{ position: "relative" }}>
            <div className="image-upload">
              <div className="image-upload-inner">
                <label htmlFor="input_file3">
                  {" "}
                  <img src={imageIcon} alt="" />
                </label>
                <span>Upload MUSIC</span>

                <input
                  onChange={(e) => uploadMusic(e)}
                  type="file"
                  class="input-file"
                  id="input_file3"
                />

                <span>{choosenMusic}</span>
                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Elementum,
                </span>
              </div>
            </div>
            <div className="newEntryBottom-wrapper">
              <div className="btn-next btn-done" onClick={closeModelHandler}>
                Done
              </div>
            </div>
          </TabPanel>
        </Box>
      </GlobalModal>
      {/* ENDS */}

      {/* UPDATE MEDIA MODEL STARTS */}
      <GlobalModal
        type="upload"
        open={updateMediaModelState}
        close={closeUpdateModelHandler}
        heading="Media Edit"
      >
        <div className="editMediaAddMediaMainDiv">
          <span className="modeltitle">Upload New Media</span>
          <div className="editMediaAddMediaMainDivInnerDiv">
            <img src={plus} alt="add icon" />
            <span className="subTitle">Add file</span>
          </div>
        </div>

        <div className="btn-groups">
          <button className="export">Done</button>
          <button className="cancel" onClick={closeUpdateModelHandler}>
            Back
          </button>
        </div>
      </GlobalModal>
      {/* UPDATE MEDIA MODEL ENDS */}
    </>
  );
};
export default MediaContainer;
