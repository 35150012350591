import React, { useState, useEffect } from "react";
import stemUnicornBig from "../../assets/stemUnicornBig.svg";
import hide from "../../assets/hide.svg";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";
import { useUser } from "../../context/User";
import { useHistory } from "react-router-dom";
import { consoleError } from "../../utils/helper";
import api from "../../services/api";

const Login = () => {
  const { user, setUser, isAuthenticated, setIsAuthenticated } = useUser();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  let history = useHistory();

  const [formState, setFormState] = useState({
    email: "",
    password: "",
    panel: "siteadmin",
  });

  const showPassword = () => {
    let field = document.getElementById("password");
    if (field.type === "password") {
      field.type = "text";
    } else {
      field.type = "password";
    }
    // field.addEventListener('click', () => {
    //   if (field.type === 'password') {
    //     field.type = 'text'
    //   } else {
    //     field.type = 'password'
    //   }
    // })
  };

  useEffect(() => {
    checkIfUserIsLoggedInOrNot();
  }, [isAuthenticated]);

  const checkIfUserIsLoggedInOrNot = () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      history.push("/dashboard");
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setErrorMsg("");
    setFormState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const clearFromData = () => {
    setFormState({
      email: "",
      password: "",
      panel: "siteadmin",
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await api.post(`/api/v1/auth/login`, formState);
      if (res && res.ok) {
        setUser(res.user);
        setIsAuthenticated(true);
        clearFromData();
        localStorage.setItem("accessToken", res.accessToken);
        history.push("/dashboard");
        setLoading(false);
      }
    } catch (error) {
      setErrorMsg(error.message);
      consoleError(error);
      setLoading(false);
    }
  };

  return (
    <div className='login-container'>
      <div className='login-left'>
        <div className='logo'>
          <img src={stemUnicornBig} alt='Stem Unicorn' />
        </div>
        <div className='msg'>
          <div className='welcome-msg'>Welcome to Admin Panel</div>
          <span>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus
            ullamcorper nisl erat, vel convallis elit fermentum pellentesque.
            Sed mollis velit facilisis facilisis viverra.
          </span>
        </div>
      </div>
      <form onSubmit={submitHandler} className='login-right'>
        <div className='heading'>Login To Your Account</div>
        <div className='form'>
          {errorMsg ? (
            <div
              style={{ width: "75%" }}
              class='alert alert-danger'
              role='alert'
            >
              {errorMsg}
            </div>
          ) : (
            ""
          )}
          <div className='form_control'>
            <label htmlFor=''>Email</label>
            <input
              type='text'
              name='email'
              value={formState.email}
              onChange={(e) => changeHandler(e)}
            />
          </div>
          <div className='form_control'>
            <label htmlFor=''>Password</label>
            <div className='password-field'>
              <input
                type='password'
                id='password'
                name='password'
                value={formState.password}
                onChange={(e) => changeHandler(e)}
              />
              <img onClick={showPassword} src={hide} alt='Hide' />
            </div>
          </div>
          <div className='forgot-section'>
            <div className='remember-me'>
              <Checkbox
                sx={{
                  "&.Mui-checked": {
                    color: "#414141",
                  },
                }}
              />

              <span>Remember Me</span>
            </div>
            <Link to='/forgot-password'>
              {" "}
              <span className='forgot-pwd'>Forgot Password?</span>
            </Link>
          </div>

          <button disabled={loading}>
            {loading ? "Please wait.." : "Login"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
