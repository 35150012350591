import React from "react";
import penciledit from "../../../assets/pencil-edit.svg";
import plus from "../../../assets/plus.svg";
import { useHistory } from "react-router-dom";
import removeIcon from "../../../assets/removeIcon.svg";
import api from "../../../services/api";

const GameCard = ({ noOfWeek, gameData }) => {
  const history = useHistory();

  const addContentPageHandler = (id, weekNo) => {
    history.push(`/platformManagement/addGameContent/${id}/${weekNo}`);
    //alert("Add Lesson Content First")
  };

  const editContentPageHandler = (id) => {
    history.push(`/platformManagement/editGameContent/${id}`);
    // history.push(`/platformManagement/addGameContent/${id}/${1}`);
  };

  const checkCondition = [];

  const removeContentHandler = async (e, contentId, decisionTreeId) => {
    e.stopPropagation();
    try {
      const res = await api.delete(
        `/api/v1/siteadmin/delete/decision-tree/${contentId}/${decisionTreeId}`
      );
      if (res && res.ok) {
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="lessondiv">
      {/* <div
        className="lessionContentAddCardMain"
        onClick={() => editContentPageHandler(1)}
      >
        <span className="weekTitle">Week 1</span>
        <div className="innerDivWithFooter">
          <div className="topDiv"></div>
          <div className="footerDiv">
            <span>Getting Started</span>
            <img className="icon calendarDates" src={penciledit} alt="" />
          </div>
        </div>
      </div> */}

      {Array.from({ length: noOfWeek }, (value, key) => {
        return (
          <>
            {gameData && gameData.length
              ? gameData.map((item, index) => (
                  <>
                    {key + 1 === item.week ? (
                      item.decisionTreeQuestions ? (
                        <div
                          key={index}
                          className="lessionContentAddCardMain"
                          onClick={() => editContentPageHandler(item._id)}
                        >
                          <span
                            className="weekTitle"
                            data-condition={checkCondition.push(key)}
                          >
                            Module {key + 1}
                          </span>
                          <div className="innerDivWithFooter">
                            <div className="topDiv"></div>
                            <div
                              className="removeMedia bg-danger"
                              onClick={(e) =>
                                removeContentHandler(
                                  e,
                                  item._id,
                                  item.decisionTreeQuestions._id
                                )
                              }
                            >
                              <img src={removeIcon} alt="remove" />
                            </div>
                            <div className="footerDiv">
                              <span>{item.title}</span>
                              <img
                                className="icon calendarDates"
                                src={penciledit}
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="lessionContentAddCardMain"
                          onClick={() =>
                            addContentPageHandler(item._id, key + 1)
                          }
                        >
                          <span
                            className="weekTitle"
                            data-condition={checkCondition.push(key)}
                          >
                            Module {key + 1}
                          </span>
                          <div className="innerDiv">
                            <div className="topDiv">
                              <img src={plus} alt="plus" />
                              <span className="addContentText">
                                Add game content
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                ))
              : // <div
                //  className='lessionContentAddCardMain'
                //  onClick={() => addContentPageHandler(key+1)}
                // >
                //   <span className='weekTitle'>Week {key+1}</span>
                //   <div className='innerDiv'>
                //     <div className='topDiv'>
                //       <img src={plus} alt='plus' />
                //       <span className='addContentText'>Add game content</span>
                //     </div>
                //   </div>
                // </div>
                ""}
            {!checkCondition.includes(key) && (
              <div
                className="lessionContentAddCardMain"
                style={{ opacity: 0.6 }}
                onClick={() =>
                  alert(`Please add Week ${key + 1} content first`)
                }
              >
                <span className="weekTitle">Week {key + 1}</span>
                <div className="innerDiv">
                  <div className="topDiv">
                    <img src={plus} alt="plus" />
                    <span className="addContentText">Add game content</span>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default GameCard;
