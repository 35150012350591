import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../components/Tabs";
import { SetProps } from "../../components/Tabs";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import adminService from "../../services/siteadminService";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableMain from "../../components/Table";
import SelectUnstyled, {
  selectUnstyledClasses,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, {
  optionUnstyledClasses,
} from "@mui/base/OptionUnstyled";
import OptionGroupUnstyled from "@mui/base/OptionGroupUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import ConfirmButton from "../../components/Buttons/ConfirmButton";
import CancelButton from "../../components/Buttons/CancelButton";
import { GlobalModal } from "../../components/Modal";
import Sidebar from "../../components/Sidebar";
import { consoleError, consoleLog } from "../../utils/helper";
import api from "../../services/api";
import { toast } from "react-toastify";
import siteadminService from "../../services/siteadminService";

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const StyledButton = styled("button")(
  ({ theme }) => `
  font-family: Inter;
  font-size: 0.875rem;
  box-sizing: border-box;
  width:250px;
 

  min-width: ${theme.breakpoints.up("md") ? "283px" : "220px"};;
  
  
  background:white;
  border: 1px solid rgba(0, 0, 0, 0.2);

  border-radius: 2px;
  margin: 0.5em;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 5px;
  margin: 10px 0;
  
 
  
  min-width: ${theme.breakpoints.up("md") ? "283px" : "220px"};;

  
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[800] : grey[300]};
  border-radius: 0.75em;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  `
);

const StyledOption = styled(OptionUnstyled)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 0.45em;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const StyledGroupRoot = styled("li")`
  list-style: none;
`;

const StyledGroupHeader = styled("span")`
  display: block;
  padding: 15px 0 5px 10px;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${grey[600]};
`;

const StyledGroupOptions = styled("ul")`
  list-style: none;
  margin-left: 0;
  padding: 0;

  > li {
    padding-left: 20px;
  }
`;

const StyledPopper = styled(PopperUnstyled)`
  z-index: 1;
`;

function CustomSelect(props) {
  const components = {
    Root: StyledButton,
    Listbox: StyledListbox,
    Popper: StyledPopper,
    ...props.components,
  };

  return <SelectUnstyled {...props} components={components} />;
}

CustomSelect.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Listbox: PropTypes.elementType,
    Popper: PropTypes.func,
    Root: PropTypes.elementType,
  }),
};

const CustomOptionGroup = React.forwardRef(function CustomOptionGroup(
  props,
  ref
) {
  const components = {
    Root: StyledGroupRoot,
    Label: StyledGroupHeader,
    List: StyledGroupOptions,
    ...props.components,
  };

  return <OptionGroupUnstyled {...props} ref={ref} components={components} />;
});

CustomOptionGroup.propTypes = {
  /**
   * The components used for each slot inside the OptionGroupUnstyled.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  components: PropTypes.shape({
    Label: PropTypes.elementType,
    List: PropTypes.elementType,
    Root: PropTypes.elementType,
  }),
};

const ManageAccounts = () => {
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const dispatch = useDispatch();

  const siteAdmin = useSelector((state) => state.siteadmin.site_admin);
  const siteSchoolAdmin = useSelector((state) => state.siteadmin.school_admin);
  const teacherAdmin = useSelector((state) => state.siteadmin.teacher_admin);
  const studentAdmin = useSelector((state) => state.siteadmin.student_admin);
  const allSchools = useSelector((state) => state.siteadmin.schools);
  const allClassrooms = useSelector((state) => state.siteadmin.classrooms);
  
  const [schoolId, setSchoolId] = useState("");
  const [classroomId, setClassroomId] = useState("");

  const [schoolIdSearch, setSchoolIdSearch] = useState("");
  const [classroomIdSearch, setClassroomIdSearch] = useState("");

  const [allSchoolClassrooms, setAllSchoolClassrooms]  = useState(null);

  const [allSchoolClassroomsSearch, setAllSchoolClassroomsSearch]  = useState(null);

  const modalChangeHandler = async (e, schoolId, classroomId) => {
    try {
  
      if (schoolId==null||schoolId.length <= 0) {
        schoolId = "";
      }
      if (classroomId==null||classroomId.length <= 0) {
        classroomId = "";
      }

      setSchoolId(schoolId);
      setClassroomId(classroomId);
      setSchoolUserFormState((prevValue) => {
        return {
          ...prevValue,
          ["schoolId"]: schoolId,
          ["classRoomId"]: classroomId
        };
      });
  

      await siteadminService.getShool(schoolId, dispatch).then(function(result) {
        setAllSchoolClassrooms(result?.classRooms);
     });
    } catch (error) {
      console.error("error", error);
    }
  };

  const filterListChangeHandler = async (e, role, schoolISearch, classroomIdSearch) => {
    try {
  
      if (schoolISearch==null||schoolISearch.length <= 0) {
        schoolISearch = "";
      }
      if (classroomIdSearch==null||classroomIdSearch.length <= 0) {
        classroomIdSearch = "";
      }

      setSchoolIdSearch(schoolISearch);
      setClassroomIdSearch(classroomIdSearch);

      await siteadminService.getShool(schoolISearch, dispatch).then(function(result) {
        setAllSchoolClassroomsSearch(result?.classRooms);
     });
     await checkBoxHandler(e, role, "", schoolISearch, classroomIdSearch);
    } catch (error) {
      console.error("error", error);
    }
  };
  
  const openModal = () => {
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    setClose(true);
    setSchoolUserFormState({
      name: "",
      email: "",
      password: "",
      role: "",
      schoolId: "",
      classRoomId: "",
    });
    setSiteAdminFormState({
      name: "",
      email: "",
      password: "",
      role: "siteadmin",
      subrole: "",
    });
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setExpiryDateLicense(false);
    setAscendingName(false);
    setDescendingName(false);
    setSchoolIdSearch("");
    setClassroomIdSearch("");
    setValue(newValue);
  };
  const [headCells, setHeadCells] = React.useState([
    {
      id: "level",
      label: "level",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "name",
      label: "name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "registeredEmail",
      label: "Registered Email",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "Edit",
      label: "Edit",
      numeric: false,
      disablePadding: false,
    },
  ]);
  const [headCellsSiteAdmin, setHeadCellsSiteAdmin] = React.useState([
    {
      id: "level",
      label: "level",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "name",
      label: "name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "registeredEmail",
      label: "Registered Email",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "subrole",
      label: "Access Level",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "Edit",
      label: "Edit",
      numeric: false,
      disablePadding: false,
    },
  ]);
  const [headCellsUser, setHeadCellsUser] = React.useState([
    {
      id: "level",
      label: "level",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "schoolName",
      label: "school Name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "classroomName",
      label: "Classroom Name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "name",
      label: "Name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "registeredEmail",
      label: "Registered Email",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "Edit",
      label: "Edit",
      numeric: false,
      disablePadding: false,
    },
  ]);
  const [headCellsSchoolAdmin, setHeadCellSchooldmin] = React.useState([
    {
      id: "level",
      label: "level",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "schoolName",
      label: "school Name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "name",
      label: "Name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "registeredEmail",
      label: "Registered Email",
      numeric: false,
      disablePadding: false,
    },
    {
      id: "Edit",
      label: "Edit",
      numeric: false,
      disablePadding: false,
    },
  ]);

  const [resultDataSiteAdmin, setResultDataSiteAdmin] = useState([]);
  const [resultDataSchool, setResultDataSchool] = useState([]);
  const [resultDataTeacher, setResultDataTeacher] = useState([]);
  const [resultDataStudent, setResultDataStudent] = useState([]);

  useEffect(() => {
    adminService.getSiteAdmin(dispatch);
    adminService.getSchoolAdmin(dispatch);
    adminService.getTeacherAdmin(dispatch);
    adminService.getStudentAdmin(dispatch);
    siteadminService.getSchools(dispatch);
    siteadminService.getClassrooms(dispatch);
  }, []);

  useEffect(() => {
    if (siteAdmin && siteAdmin.length) {
      const resSiteAdmin = siteAdmin.map((sadmin) => {
        return {
          _id: sadmin._id,
          level: sadmin?.role,
          name: sadmin?.name,
          registeredEmail: sadmin?.email,
          subrole: sadmin?.subrole
        };
      });
      setResultDataSiteAdmin(resSiteAdmin);
    }

    if (siteSchoolAdmin && siteSchoolAdmin.length) {
      const resSchool = siteSchoolAdmin.map((school) => {
        return {
          _id: school._id,
          level: school?.role,
          schoolName: school?.schoolId?.name,
          name: school?.name,
          registeredEmail: school?.email,
        };
      });
      setResultDataSchool(resSchool);
    }

    if (teacherAdmin && teacherAdmin.length) {
      const resTeacher = teacherAdmin.map((teacher) => {
        return {
          _id: teacher._id,
          level: teacher?.role,
          schoolName: teacher?.schoolId?.name,
          classroomName: teacher?.classroom?.className,
          name: teacher?.name,
          registeredEmail: teacher?.email,
        };
      });
      setResultDataTeacher(resTeacher);
    }

    if (studentAdmin && studentAdmin.length) {
      const resStudent = studentAdmin.map((student) => {
        return {
          _id: student._id,
          level: student?.role,
          schoolName: student?.schoolId?.name,
          classroomName: student?.classroom?.className,
          name: student?.name,
          registeredEmail: student?.email,
        };
      });
      setResultDataStudent(resStudent);
    }
  }, [siteAdmin, siteSchoolAdmin, teacherAdmin, studentAdmin]);

  // STATE OF ADD NEW SITEADMIN STARTS
  const [siteAdminFormState, setSiteAdminFormState] = useState({
    name: "",
    email: "",
    // password: "",
    role: "siteadmin",
    subrole: "",
    status: "unverified",
  });

  const siteAdminInputChangeHandler = (e) => {
    const { name, value } = e.target;

    setSiteAdminFormState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const siteAdminRegisterHandler = async () => {
    try {
      if (siteAdminFormState.name.trim() === "") {
        toast.error("Please input the user name");
        return;
      }
      if (siteAdminFormState.email.trim() === "") {
        toast.error("Please input the email");
        return;
      }
      if (
        siteAdminFormState.password == null ||
        siteAdminFormState.password === ""
      ) {
        toast.error("Please input the password");
        return;
      }
      if (siteAdminFormState.subrole.trim() === "") {
        toast.error("Please input the sub role");
        return;
      }
      const res = await api.post(
        "/api/v1/auth/user-register",
        siteAdminFormState
      );
      if (res && res.ok) {
        closeModal();
        adminService.getSiteAdmin(dispatch);
        setSiteAdminFormState({
          name: "",
          email: "",
          password: "",
          role: "siteadmin",
          subrole: "",
        });
      }
    } catch (err) {
      consoleError(err);
      toast.error(err.message);
    }
  };
  // STATE OF ADD NEW SITEADMIN ENDS

  // STATE OF ADD NEW SCHOOL USER STARTS
  const [schoolUserFormState, setSchoolUserFormState] = useState({
    name: "",
    email: "",
    // password: "",
    role: "",
    schoolId: "",
    classRoomId: "",
    status: "unverified",
  });

  const schoolUserInputChangeHandler = (e) => {
    const { name, value } = e.target;

    setSchoolUserFormState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const addSchoolUserHandler = async (role1) => {
    try {
      let values = [];
      let newList = { ...schoolUserFormState, role: role1 };
      // setSchoolUserFormState({ ...newList, role: role1 })
      for (var key in newList) {
        values.push(newList[key]);
      }
      let filtered = values.filter((value) => value !== "");
      if (filtered.length < 5) {
        toast.error("Please fill all the input fields");
        return;
      }
      const res = await api.post("/api/v1/siteadmin/add/new/user", newList);
      if (res && res.ok) {
        const isStudent = newList.role === "student";
        if (isStudent) {
          const newClass = {
            classRoomId: newList.classRoomId,
            studentEmail: newList.email,
            schoolId: newList.schoolId,
          };
          const data = await api.post(
            "/api/v1/siteadmin/add/studentsToParticularClass",
            newClass
          );
          if (data && data.ok) {
            toast.success(res.message);
          } else {
            toast.success(data.message);
          }
        } else {
          toast.success(res.message);
        }
        closeModal();
        adminService.getSchoolAdmin(dispatch);
        adminService.getTeacherAdmin(dispatch);
        adminService.getStudentAdmin(dispatch);

        setSchoolUserFormState({
          name: "",
          email: "",
          password: "",
          role: "",
          schoolId: "",
          classRoomId: "",
        });
      }
    } catch (err) {
      consoleError(err);
      toast.error(err.message);
    }
  };
  // STATE OF ADD NEW SCOOL USER ENDS
  const [expiryDateLicense, setExpiryDateLicense] = useState(false);
  const [ascendingName, setAscendingName] = useState(false);
  const [descendingName, setDescendingName] = useState(false);
  
  const checkBoxHandler = async (e, role, type, schoolId, classroomId) => {
    let expiryDate = 0;
    let ascSort = 0;
    let descSort = 0;
    if (type=="expiryDateLicense") {
      setExpiryDateLicense(true);
      setAscendingName(false);
      setDescendingName(false);
      expiryDate = 1;
    } else if (type=="ascendingName") {
      setExpiryDateLicense(false);
      setAscendingName(true);
      setDescendingName(false);
      ascSort = 1;
    } else if (type=="descendingName") {
      setExpiryDateLicense(false);
      setAscendingName(false);
      setDescendingName(true);
      descSort = 1;
    } else {
      if (expiryDateLicense) {
        expiryDate = 1;
      }
      if (ascendingName) {
        ascSort = 1;
      }
      if (descendingName) {
        descSort = 1;
      }
    }
    const data = await api.get(
      `/api/v1/siteadmin/filter/siteadmin/role?role=${role}&expiryDate=${expiryDate}&ascSort=${ascSort}&descSort=${descSort}&schoolId=${schoolId}&classroomId=${classroomId}`
    );
    if (data && data.ok) {
      const resUser = data.userList.map((user) => {
        return {
          _id: user._id,
          level: user?.role,
          schoolName: user?.schoolId?.name,
          classroomName: user?.classroom?.className,
          name: user?.name,
          registeredEmail: user?.email,
        };
      });
      switch (role) {
        case "siteadmin":
          setResultDataSiteAdmin(resUser);
          break;
        case "school":
          setResultDataSchool(resUser);
          break;
        case "teacher":
          setResultDataTeacher(resUser);
          break;
        case "student":
          setResultDataStudent(resUser);
          break;
      }
    }
  }

  // sieadmin filter
  const [owner, setOwner] = useState(false);

  const [viewer, setViewer] = useState(false);
  const [editor, setEditor] = useState(false);
  const [allEntries, setAllEntries] = useState(false);

  const checkBoxHandler0 = async (e, type) => {
    setViewer(false);
    setOwner(false);
    setEditor(false);
    setAllEntries(false);
    switch (type) {
      case "viewer":
        setViewer(true);
        break;
      case "editor":
        setEditor(true);
        break;
      case "owner":
        setOwner(true);
        break;
      case "allEntries":
        setAllEntries(true);
        break;
      }
      const data = await api.get(`/api/v1/siteadmin/filter/${type}`);
      if (data && data.ok) {
        const resSiteAdmin = data.data.map((siteadmin) => {
          return {
            _id: siteadmin._id,
            level: siteadmin?.role,
            name: siteadmin?.name,
            registeredEmail: siteadmin?.email,
            subrole: siteadmin.subrole
          };
        });
        setResultDataSiteAdmin(resSiteAdmin);
      }
  };

  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="heading-main">Manage Accounts</div>

        <div className="manage-accounts container-page">
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <div className="tabs-section">
                <Tabs
                  sx={{
                    width: "80%",
                    marginBottom: "33px",
                    textTransform: "lowercase",
                  }}
                  value={value}
                  indicatorColor="secondary"
                  onChange={handleChange}
                >
                  <Tab label="Site Admin" {...SetProps(0)} />
                  <Tab label="Sch Admin" {...SetProps(1)} />
                  <Tab label="Teacher" {...SetProps(2)} />
                  <Tab label="Student" {...SetProps(3)} />
                </Tabs>
              </div>
            </Box>

            <TabPanel value={value} index={0} sx={{ position: "relative" }}>
              {
                <GlobalModal
                  open={open}
                  close={close}
                  heading="
      New Account Site Admin"
                >
                  <div className="form-manage_accounts">
                    <div className="form-group">
                      <label htmlFor="userName">User name</label>
                      <input
                        type="text"
                        name="name"
                        value={siteAdminFormState.name}
                        onChange={(e) => siteAdminInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={siteAdminFormState.email}
                        onChange={(e) => siteAdminInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">Password</label>
                      <input
                        type="password"
                        name="password"
                        value={siteAdminFormState.password}
                        onChange={(e) => siteAdminInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="role">Sub Role</label>
                      <select
                        name="subrole"
                        value={siteAdminFormState.subrole}
                        onChange={(e) => siteAdminInputChangeHandler(e)}
                      >
                        <option>Select role</option>
                        <option value="owner">Owner</option>
                        <option value="editor">Editor</option>
                        <option value="viewer">Viewer</option>
                      </select>
                    </div>
                  </div>
                  <div className="confirm-close-btns">
                    <ConfirmButton>
                      <div
                        className="open-btn"
                        onClick={siteAdminRegisterHandler}
                      >
                        Confirm
                      </div>
                    </ConfirmButton>
                    <CancelButton>
                      <div className="close-btn" onClick={closeModal}>
                        Cancel
                      </div>
                    </CancelButton>
                  </div>
                </GlobalModal>
              }
              <div className="manage-account-btn">
                <button className="school-btn " onClick={openModal}>
                  Add New Account
                </button>
              </div>
              <div className="manage-accounts-inner">
                <div className="manage-account-left">
                  <TableMain
                    headCells={headCellsSiteAdmin}
                    resultData={resultDataSiteAdmin}
                    topic="manage-accounts-siteAdmin"
                  />
                </div>
                <div className="manage-account-right">
                  <div className="sorting">
                    <span className="header">Sorting</span>
                    <div className="data">
                      <input
                        value={allEntries}
                        onChange={(e) => checkBoxHandler0(e, "allEntries")}
                        checked={allEntries}
                        type="checkbox"
                        name="allEntries"
                        id=""
                      />{" "}
                      <span>All Entries</span>
                    </div>
                  </div>
                  <div className="access-levels">
                    <span className="header">Access Level</span>
                    <div className="access-levels-inner">
                      <div className="data">
                        <input
                          value={owner}
                          onChange={(e) => checkBoxHandler0(e, "owner")}
                          checked={owner}
                          type="checkbox"
                          name="owner"
                          id=""
                        />{" "}
                        <span>Owner</span>
                      </div>
                      <div className="data">
                        <input
                          value={viewer}
                          onChange={(e) => checkBoxHandler0(e, "viewer")}
                          checked={viewer}
                          type="checkbox"
                          name="viewer"
                          id=""
                        />{" "}
                        <span>Viewer</span>
                      </div>{" "}
                      <div className="data">
                        <input
                          value={editor}
                          onChange={(e) => checkBoxHandler0(e, "editor")}
                          checked={editor}
                          type="checkbox"
                          name="editor"
                          id=""
                        />{" "}
                        <span>Editor</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} sx={{ position: "relative" }}>
              {
                <GlobalModal
                  open={open}
                  close={close}
                  heading="
      Add school admin"
                >
                  <div className="form-manage_accounts">
                    <div className="form-group">
                      <label htmlFor="userName">User name</label>
                      <input
                        type="text"
                        name="name"
                        value={schoolUserFormState.name}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={schoolUserFormState.email}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">School</label>
                      <select
                        name="schoolId"
                        value={schoolUserFormState.schoolId}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        <option>Select School</option>
                        {allSchools && allSchools.length > 0
                          ? allSchools.map((school, index) => {
                              return (
                                <option key={index} value={school._id}>
                                  {school.name}
                                </option>
                              );
                            })
                          : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="accountType">Account Type</label>
                      <select
                        name="role"
                        value={schoolUserFormState.role}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        {/* <option>Select Role</option> */}
                        <option value="school">School Admin</option>
                      </select>
                    </div>
                  </div>
                  <div className="confirm-close-btns">
                    <ConfirmButton>
                      <div
                        className="open-btn"
                        onClick={(role) =>
                          addSchoolUserHandler((role = "school"))
                        }
                      >
                        Confirm
                      </div>
                    </ConfirmButton>
                    <CancelButton>
                      <div className="close-btn" onClick={closeModal}>
                        Cancel
                      </div>
                    </CancelButton>
                  </div>
                </GlobalModal>
              }
              <div className="manage-account-btn">
                <button className="school-btn" onClick={openModal}>
                  Add New Account
                </button>
              </div>
              <div className="manage-accounts-inner">
                <div className="manage-account-left">
                  <TableMain
                    headCells={headCellsSchoolAdmin}
                    resultData={resultDataSchool}
                    topic="manage-accounts-school"
                  />
                </div>
                <div className="manage-account-right">
                  <div className="sorting">
                    <span className="header">License</span>
                    <div className="data">
                      <input
                        value={expiryDateLicense}
                        onChange={(e) =>
                          checkBoxHandler(e, "school", "expiryDateLicense", schoolIdSearch, "")
                        }
                        type="checkbox"
                        checked={expiryDateLicense}
                        name="expiryDateLicense"
                        id=""
                      />
                      <span>List By Expiry Date</span>
                    </div>
                  </div>
                  <div className="access-levels">
                    <span className="header">Sort by school Admin Name</span>
                    <div className="access-levels-inner">
                      <div className="access-level-inner_left">
                        <div className="data">
                          <input
                            value={ascendingName}
                            onChange={(e) =>
                              checkBoxHandler(e, "school", "ascendingName", schoolIdSearch, "")
                            }
                            checked={ascendingName}
                            type="checkbox"
                            name="ascendingName"
                            id=""
                          />
                          <span>A - Z</span>
                        </div>
                        <div className="data">
                          <input
                            value={descendingName}
                            onChange={(e) =>
                              checkBoxHandler(e, "school", "descendingName", schoolIdSearch, "")
                            }
                            type="checkbox"
                            name="descendingName"
                            id=""
                            checked={descendingName}
                          />
                          <span>Z - A</span>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="sorting">
                    <br />
                    <div className="schoolIdSearch" style={{ marginTop: "13px" }}>
                      <span className="header">School</span>
                      <div>
                        <select
                          name="schoolIdSearch"
                          value={schoolIdSearch}
                          onChange={(e) => filterListChangeHandler(e, "school", e.target.value, "")}
                        >
                          <option key="-1" value="">Select School</option>
                          {allSchools && allSchools.length > 0
                            ? allSchools.map((school, index) => {
                                return (
                                  <option key={index} value={school._id}>
                                    {school?.name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

            {/* Teacher */}
            <TabPanel value={value} index={2} sx={{ position: "relative" }}>
              {
                <GlobalModal
                  open={open}
                  close={close}
                  heading="
    New Teacher"
                >
                  <div className="form-manage_accounts">
                    <div className="form-group">
                      <label htmlFor="userName">User name</label>
                      <input
                        type="text"
                        name="name"
                        value={schoolUserFormState.name}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={schoolUserFormState.email}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="schoolId">School</label>
                      <select
                          name="schoolId"
                          value={schoolId}
                          onChange={(e) => modalChangeHandler(e, e.target.value, "")}
                        >
                          <option key="-1" value="">Select School</option>
                          {allSchools && allSchools.length > 0
                            ? allSchools.map((school, index) => {
                                return (
                                  <option key={index} value={school._id}>
                                    {school?.name}
                                  </option>
                                );
                              })
                            : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="classroomId">Classroom</label>
                        <select
                          name="classroomId"
                          value={classroomId}
                          onChange={(e) => modalChangeHandler(e, schoolId, e.target.value)}
                        >
                          <option key="-1" value="">Select Classroom</option>
                          {allSchoolClassrooms && allSchoolClassrooms.length > 0
                            ? allSchoolClassrooms.map((classroom, index) => {
                                return (
                                  <option key={index} value={classroom._id}>
                                    {classroom?.className}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="accountType">Account Type</label>
                      <select
                        name="role"
                        value={schoolUserFormState.role}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        {/* <option>Select Role</option> */}
                        <option value="teacher">Teacher</option>
                      </select>
                    </div>
                  </div>
                  <div className="confirm-close-btns">
                    <ConfirmButton>
                      <div
                        className="open-btn"
                        onClick={(role) =>
                          addSchoolUserHandler((role = "teacher"))
                        }
                      >
                        Confirm
                      </div>
                    </ConfirmButton>
                    <CancelButton>
                      <div className="close-btn" onClick={closeModal}>
                        Cancel
                      </div>
                    </CancelButton>
                  </div>
                </GlobalModal>
              }
              <div className="manage-account-btn">
                <button className="school-btn" onClick={openModal}>
                  Add New Account
                </button>
              </div>
              <div className="manage-accounts-inner">
                <div className="manage-account-left">
                  <TableMain
                    headCells={headCellsUser}
                    resultData={resultDataTeacher}
                    topic="manage-accounts-teacher"
                  />
                </div>
                <div className="manage-account-right">
                  <div className="sorting">
                    <span className="header">License</span>
                    <div className="data">
                      <input
                        value={expiryDateLicense}
                        onChange={(e) =>
                          checkBoxHandler(e, "teacher", "expiryDateLicense", schoolIdSearch, classroomIdSearch)
                        }
                        type="checkbox"
                        checked={expiryDateLicense}
                        name="expiryDateLicense"
                        id=""
                      />
                      <span>List By Expiry Date</span>
                    </div>
                  </div>
                  <div className="access-levels">
                    <span className="header">Sort by Teacher Name</span>
                    <div className="access-levels-inner">
                      <div className="access-level-inner_left">
                        <div className="data">
                          <input
                            value={ascendingName}
                            onChange={(e) =>
                              checkBoxHandler(e, "teacher", "ascendingName", schoolIdSearch, classroomIdSearch)
                            }
                            checked={ascendingName}
                            type="checkbox"
                            name="ascendingName"
                            id=""
                          />{" "}
                          <span>A - Z</span>
                        </div>
                        <div className="data">
                          <input
                            value={descendingName}
                            onChange={(e) =>
                              checkBoxHandler(e, "teacher", "descendingName", schoolIdSearch, classroomIdSearch)
                            }
                            type="checkbox"
                            name="descendingName"
                            id=""
                            checked={descendingName}
                          />
                          <span>Z - A</span>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="sorting">
                    <br />
                    <div className="schoolIdSearch" style={{ marginTop: "13px" }}>
                      <span className="header">School</span>
                      <div>
                        <select
                          name="schoolIdSearch"
                          value={schoolIdSearch}
                          onChange={(e) => filterListChangeHandler(e, "teacher", e.target.value, "")}
                        >
                          <option key="-1" value="">Select School</option>
                          {allSchools && allSchools.length > 0
                            ? allSchools.map((school, index) => {
                                return (
                                  <option key={index} value={school._id}>
                                    {school?.name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    </div>
                    <br />
                    <div className="classroomIdSearch" style={{ marginTop: "13px" }}>
                    <span className="header">Classroom</span>
                      <div>
                        <select
                          name="classroomIdSearch"
                          value={classroomIdSearch}
                          onChange={(e) => filterListChangeHandler(e, "teacher", schoolIdSearch, e.target.value)}
                        >
                          <option key="-1" value="">Select Classroom</option>
                          {allSchoolClassroomsSearch && allSchoolClassroomsSearch.length > 0
                            ? allSchoolClassroomsSearch.map((classroom, index) => {
                                return (
                                  <option key={index} value={classroom._id}>
                                    {classroom?.className}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            </TabPanel>
            {/* Teacher ends */}

            {/* Student */}
            <TabPanel value={value} index={3} sx={{ position: "relative" }}>
              {
                <GlobalModal
                  open={open}
                  close={close}
                  heading="
    Add student"
                >
                  <div className="form-manage_accounts">
                    <div className="form-group">
                      <label htmlFor="userName">User name</label>
                      <input
                        type="text"
                        name="name"
                        value={schoolUserFormState.name}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        name="email"
                        value={schoolUserFormState.email}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="schoolId">School</label>
                      <select
                          name="schoolId"
                          value={schoolId}
                          onChange={(e) => modalChangeHandler(e, e.target.value, "")}
                        >
                          <option key="-1" value="">Select School</option>
                          {allSchools && allSchools.length > 0
                            ? allSchools.map((school, index) => {
                                return (
                                  <option key={index} value={school._id}>
                                    {school?.name}
                                  </option>
                                );
                              })
                            : ""}
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="classroomId">Classroom</label>
                        <select
                          name="classroomId"
                          value={classroomId}
                          onChange={(e) => modalChangeHandler(e, schoolId, e.target.value)}
                        >
                          <option key="-1" value="">Select Classroom</option>
                          {allSchoolClassrooms && allSchoolClassrooms.length > 0
                            ? allSchoolClassrooms.map((classroom, index) => {
                                return (
                                  <option key={index} value={classroom._id}>
                                    {classroom?.className}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="accountType">Account Type</label>
                      <select
                        name="role"
                        value={schoolUserFormState.role}
                        onChange={(e) => schoolUserInputChangeHandler(e)}
                      >
                        {/* <option>Select Role</option> */}
                        <option value="student">Student</option>
                      </select>
                    </div>
                  </div>
                  <div className="confirm-close-btns">
                    <ConfirmButton>
                      <div
                        className="open-btn"
                        onClick={(role) =>
                          addSchoolUserHandler((role = "student"))
                        }
                      >
                        Confirm
                      </div>
                    </ConfirmButton>
                    <CancelButton>
                      <div className="close-btn" onClick={closeModal}>
                        Cancel
                      </div>
                    </CancelButton>
                  </div>
                </GlobalModal>
              }
              <div className="manage-account-btn">
                <button className="school-btn" onClick={openModal}>
                  Add New Account
                </button>
              </div>
              <div className="manage-accounts-inner">
                <div className="manage-account-left">
                  <TableMain
                    headCells={headCellsUser}
                    resultData={resultDataStudent}
                    topic="manage-accounts-student"
                  />
                </div>
                <div className="manage-account-right">
                  <div className="sorting">
                    <span className="header">License</span>
                    <div className="data">
                      <input
                        value={expiryDateLicense}
                        onChange={(e) =>
                          checkBoxHandler(e, "student", "expiryDateAdmin", schoolId, classroomId)
                        }
                        type="checkbox"
                        checked={expiryDateLicense}
                        name="expiryDateAdmin"
                        id=""
                      />
                      <span>List By Expiry Date</span>
                    </div>
                  </div>
                  <div className="access-levels">
                    <span className="header">Sort by Student Name</span>
                    <div className="access-levels-inner">
                      <div className="access-level-inner_left">
                        <div className="data">
                          <input
                            value={ascendingName}
                            onChange={(e) =>
                              checkBoxHandler(e, "student", "ascendingName", schoolId, classroomId)
                            }
                            checked={ascendingName}
                            type="checkbox"
                            name="ascendingName"
                            id=""
                          />{" "}
                          <span>A - Z</span>
                        </div>
                        <div className="data">
                          <input
                            value={descendingName}
                            onChange={(e) =>
                              checkBoxHandler(e, "student", "descendingName", schoolId, classroomId)
                            }
                            checked={descendingName}
                            type="checkbox"
                            name="descendingName"
                            id=""
                          />{" "}
                          <span>Z - A</span>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="sorting">
                    <br />
                    <div className="schoolIdSearch" style={{ marginTop: "13px" }}>
                      <span className="header">School</span>
                      <div>
                        <select
                          name="schoolIdSearch"
                          value={schoolIdSearch}
                          onChange={(e) => filterListChangeHandler(e, "student", e.target.value, "")}
                        >
                          <option key="-1" value="">Select School</option>
                          {allSchools && allSchools.length > 0
                            ? allSchools.map((school, index) => {
                                return (
                                  <option key={index} value={school._id}>
                                    {school?.name}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    </div>
                    <br />
                    <div className="classroomIdSearch" style={{ marginTop: "13px" }}>
                    <span className="header">Classroom</span>
                      <div>
                        <select
                          name="classroomIdSearch"
                          value={classroomIdSearch}
                          onChange={(e) => filterListChangeHandler(e, "student", schoolIdSearch, e.target.value)}
                        >
                          <option key="-1" value="">Select Classroom</option>
                          {allSchoolClassroomsSearch && allSchoolClassroomsSearch.length > 0
                            ? allSchoolClassroomsSearch.map((classroom, index) => {
                                return (
                                  <option key={index} value={classroom._id}>
                                    {classroom?.className}
                                  </option>
                                );
                              })
                            : ""}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            {/* Student ends */}
          </Box>
        </div>
      </div>
    </>
  );
};

export default ManageAccounts;
