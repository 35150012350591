import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import DatePicker from "react-datepicker";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../components/Tabs";
import { SetProps } from "../../components/Tabs";
import "react-datepicker/dist/react-datepicker.css";
import { GlobalModal } from "../../components/Modal";
import warning from "../../assets/warning.svg";
import uploadIcon from "../../assets/uploadIcon.svg";
import uploadIconSm from "../../assets/uploadIconSm.svg";
import videoUpload from "../../assets/videoUpload.svg";
import plusIcon from "../../assets/plusIcon.svg";
import completedImg from "../../assets/completed.svg";
import api from "../../services/api";
import {
  consoleError,
  consoleLog,
  getOnlyFolderStructure,
} from "../../utils/helper";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import siteadminService from "../../services/siteadminService";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/Sidebar";
import QuestionList from "../../components/QuestionList";
import { toast } from "react-toastify";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import BreadCrumb from "../../components/BreadCrumb";
import BreadCrumbList from "../../components/BreadCrumbList";
import { set } from "date-fns";
import ConfirmButton from "../../components/Buttons/ConfirmButton";
import CancelButton from "../../components/Buttons/CancelButton";
import axios from "axios";
import store from "../../store";
import { beginApiCall, endApiCall } from "../../store/actions/siteadminActions";
import MuiAlert from "@mui/material/Alert";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const NewSchoolEntry = ({ match, history }) => {
  const [value, setValue] = React.useState(0);

  // breadcrumb list
  const breadcrumbList = [
    {
      name: "Dashboard",
      to: "/dashboard",
    },
    {
      name: "New School Entry",
      to: `/dashboard/new-school-entry`,
    },
  ];
  const [choosenImage, setChooseImage] = useState("");
  const finalTheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: ({ theme }) =>
            theme.unstable_sx({
              borderBottom: "4px solid #202020 !important",
            }),
        },
      },
    },
  });

  // GET ALL REGIONS

  useEffect(() => {
    fetchAllRegions();
  }, []);

  const dispatch = useDispatch();
  const allRegions = useSelector((state) => state.siteadmin.regions);
  const fetchAllRegions = async () => {
    try {
      siteadminService.getRegions(dispatch);
    } catch (err) {
      consoleError(err);
    }
  };
  // GET ALL REGIONS ENDS

  // ADD NEW SCHOOL

  // GET ALL LANGUAGE
  const [allLanguage, setAllLanguage] = useState([]);

  useEffect(() => {
    fetchAllLanguage();
  }, []);

  const fetchAllLanguage = async () => {
    try {
      const langRes = await api.get("/api/v1/siteadmin/view/all/language");
      if (langRes && langRes.ok) {
        setAllLanguage(langRes.data);
      }
    } catch (err) {
      consoleError(err);
    }
  };
  // GET ALL LANGUAGE ENDS

  // FORM STATE STARTS
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [schoolAdminPrimaryDetailPhone, setschoolAdminPrimaryDetailPhone] =
    useState();
  const [schoolAdminSecondaryDetailPhone, setschoolAdminSecondaryDetailPhone] =
    useState();

  const [formState, setFormState] = useState({
    name: "",
    country: "",
    region: "",
    packageType: "4",
    language: "",
    subTitleLanguage: "",
    schoolAdminPrimaryDetailName: "",
    schoolAdminPrimaryDetailEmail: "",
    // schoolAdminPrimaryDetailPhone: '',
    schoolAdminSecondaryDetailName: "",
    schoolAdminSecondaryDetailEmail: "",
    // schoolAdminSecondaryDetailPhone: '',
    defaultPackageType: "",
    registeredStudent: "",
  });

  const selectCountry = (val) => {
    setFormState((prevValue) => {
      return {
        ...prevValue,
        ["country"]: val,
        ["region"]: null,
      };
    });
  };

  const selectRegion = (val) => {
    setFormState((prevValue) => {
      return {
        ...prevValue,
        ["region"]: val,
      };
    });
  };

  const [schoolId, setSchoolId] = useState("");
  const [contentId, setContentId] = useState("");

  const onChangeHandler = (e) => {
    const { name, value } = e.target;

    setFormState((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const startDateChangeHandler = (date) => {
    setStartDate(new Date(date));
    // setEndDate(
    //   new Date(
    //     new Date().setDate(new Date(date).getDate() + formState.packageType * 7)
    //   )
    // )
  };
  const endDateChangeHandler = (date) => {
    setEndDate(new Date(date));
    // setEndDate(
    //   new Date(
    //     new Date().setDate(new Date(date).getDate() + formState.packageType * 7)
    //   )
    // );
  };

  const [subtitle1, setSubtitle1] = useState([
    {
      language: "",
      url: "",
    },
  ]);
  const [subtitle2, setSubtitle2] = useState([
    {
      language: "",
      url: "",
    },
  ]);

  const [addedSuccessMsg, setAddedSuccessMsg] = useState("");
  const [msgState, setMsgState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [positionState, setPositionState] = useState({
    vertical: "top",
    horizontal: "center",
  });

  const { vertical, horizontal } = positionState;
  const [errorfirst, setErrorFirst] = useState(false);

  //Phone and Email Validation
  const validateEmail = (email) => {
    //Validates the email address
    const emailRegex =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    //Validates the phone number
    const phoneRegex = /^\d{10}$/; // Change this regex based on requirement
    const phoneRegex1 = /^\d{11}$/; // Change this regex based on requirement
    const phoneRegex2 = /^\d{12}$/; // Change this regex based on requirement
    const phoneRegex3 = /^\d{13}$/; // Change this regex based on requirement

    return (
      phoneRegex.test(phone) ||
      phoneRegex1.test(phone) ||
      phoneRegex2.test(phone) ||
      phoneRegex3.test(phone)
    );
  };
  //add contents to school
  const [saving, setSaving] = useState(false);
  const formSubmitHandler = async () => {
    const primaryEmail = formState.schoolAdminPrimaryDetailEmail;
    const secondaryEmail = formState.schoolAdminSecondaryDetailEmail;
  if (      
      ((primaryEmail!=null&&primaryEmail!=""&&!validateEmail(primaryEmail))||
      (secondaryEmail!=null&&secondaryEmail!=""&&!validateEmail(secondaryEmail)))
    ) {
      setErrorFirst(true);
      toast.error("Invalid Email");
      return;
    }
    /***
    if (!schoolAdminPrimaryDetailPhone || !schoolAdminSecondaryDetailPhone) {
      setErrorFirst(true);
      toast.error("Please input Phone numbers");
      return;
    }
    ***/
    if (startDate.getTime() >= endDate.getTime()) {
      toast.error("Start Date Cannot be greater than end Date");
      return;
    }
    try {
      setSaving(true);

      const data = {
        name: formState.name,
        country: formState.country,
        region: formState.region,
        packageType: parseInt(formState.packageType),
        language: formState.language,
        // language: 'Eng',
        contentType: formState.defaultPackageType,
        subTitleLanguage: formState.subTitleLanguage,
        schoolAdminPrimaryDetail: {
          name: formState.schoolAdminPrimaryDetailName,
          email: formState.schoolAdminPrimaryDetailEmail,
          phone: schoolAdminPrimaryDetailPhone,
        },
        schoolAdminSecondaryDetail: {
          name: formState.schoolAdminSecondaryDetailName,
          email: formState.schoolAdminSecondaryDetailEmail,
          phone: schoolAdminSecondaryDetailPhone,
        },
        liscenseExpiryDate: endDate,
        registeredStudent: formState.registeredStudent,
        startDate: startDate,
        endDate: endDate,
      };

      // if(startDate.getTime()>=endDate.getTime()){
      //   toast.error("Start Date Cannot be greater than end Date")
      //   return
      // }
      const res = await api.post("/api/v1/siteadmin/add/school", data);

      if (res && res.ok) {
        setCompleted(0);
        setMsgState(true);
        setAddedSuccessMsg("School added sucessfully");
        setSchoolId(res.schoolId);
        setInterval(() => {
          window.location.href = "/";
        }, 3000);
        if (formState.defaultPackageType === "default") {
          window.location.href = "/";
          return;
        }
        // setPageState(pageState + 1);
        setSaving(false);
        // console.log(res)
      }
    } catch (err) {
      setSaving(false);

      setPageState(0);
      consoleError(err);
      setErrorFirst(true);
      toast.error(err.message);
    }
  };

  //add contents for pdf
  const formPdfHandler = async (pdfFile) => {
    setUploading(true);
    try {
      const data = new FormData();
      data.append("docs", pdfFile);
      const res = await api.post(
        `api/v1/teacher/classroomContents/upload/pdf`,
        data
      );
      if (res && res.ok) {
        const res1 = await api.post(
          `api/v1/teacher/classroomContents/editPDF/${contentId}`,
          {
            pdf: res.url,
          }
        );
        if (res1 && res1.ok) {
          setUploading(false);
          setMsgState(true);
          setAddedSuccessMsg("PDF added sucessfully");
        }
      }
    } catch (err) {
      setUploading(false);
      setPageState(1);
      consoleError(err);
      toast.error(err.message);
    }
  };

  const [uploading, setUploading] = useState(false);

  const handleClose = () => {
    setMsgState(false);
    setAddedSuccessMsg("");
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  // FORM STATE ENDS
  const [completed, setCompleted] = useState(-1);
  const [pageState, setPageState] = useState(0);

  //error message

  // Add language State ends

  // Add region state starts
  const [addRegionModel, setAddRegionModel] = useState(false);
  const [regionName, setRegionName] = useState({
    country: "",
    name: "",
  });
  const closeAddRegionModelHandler = () => {
    setAddRegionModel(false);
  };

  const regionInputChangeHandler = (e) => {
    const { name, value } = e.target;

    setRegionName((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const addRegionHandler = async () => {
    try {
      const res = await api.post("/api/v1/siteadmin/add/region", regionName);
      if (res && res.ok) {
        fetchAllRegions();
        closeAddRegionModelHandler();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Add region state ends

  // const [decisionTree, setDecisionTree] = useState([]);

  // useEffect(() => {
  //   fetchGlobalDecisionTree();
  // }, []);

  // const fetchGlobalDecisionTree = async () => {
  //   try {
  //     const res = await api.get("/api/v1/siteadmin/default/decision-tree");
  //     if (res && res.data) {
  //       setDecisionTree(res.data);
  //     }
  //   } catch (err) {
  //     consoleError(err);
  //   }
  // };

  return (
    <>
      <ThemeProvider theme={finalTheme}>
        <Sidebar />
        <div className="main newSchoolEntry">
          <BreadCrumbList values={breadcrumbList} />
          {/* {decisionTree && decisionTree.length <= 0 ? (
            <Alert severity="error" style={{ marginTop: "10px" }}>
              You must add default decision tree before adding school. Please
              add it by navigating to platform management{" "}
            </Alert>
          ) : (
            ""
          )} */}
          {pageState === 0 ? (
            <div className="entrySection">
              <div className="entrySection-left">
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    School Name <span>*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="school name"
                    name="name"
                    value={formState.name}
                    onChange={(e) => onChangeHandler(e)}
                  />
                </div>
                <div className="form-group-inner">
                  <label htmlFor="country">
                    Country <span>*</span>
                  </label>
                  <CountryDropdown
                    value={formState.country}
                    onChange={(val) => selectCountry(val)}
                  />
                </div>
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    Region <span>*</span>
                    {/***
                    <button
                      className="school-btn mx-2"
                      style={{ width: "100px" }}
                      onClick={() => setAddRegionModel(true)}
                    >
                      Add Region
                    </button>
                    ***/}
                  </label>
                  {/* <input type='text' /> */}
                  {/****
                  <select
                    name="region"
                    value={formState.region}
                    onChange={(e) => onChangeHandler(e)}
                  >
                    {" "}
                    <option>Choose region</option>
                    {allRegions && allRegions.length > 0 ? (
                      allRegions.map((regData, index) => {
                        return (
                          <option
                            value={regData._id}
                            key={index}
                          >{`${regData.name},${regData.country}`}</option>
                        );
                      })
                    ) : (
                      <option value="">No data available</option>
                    )}
                  </select>
                    ****/}
                  <RegionDropdown
                    country={formState.country}
                    value={formState.region}
                    onChange={(val) => selectRegion(val)}
                  />
                </div>
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    Package Type <span>*</span>
                  </label>
                  <div className="package-type-group">
                    <select
                      name="packageType"
                      value={formState.packageType}
                      onChange={(e) => onChangeHandler(e)}
                    >
                      <option>Choose package type</option>
                      <option value={4}>4-week Package</option>
                      <option value={8}>8-week Package</option>
                      <option value={12}>12-week Package</option>
                    </select>
                  </div>
                </div>
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    Subscription Period <span>*</span>
                  </label>
                  <div className="subscriptionPeriod">
                    <div>
                      <span>From </span>
                      <DatePicker
                        selected={startDate}
                        dateFormat="yyyy-MM-dd"
                        // onChange={(date) => setStartDate(date)}
                        onChange={(date) => startDateChangeHandler(date)}
                      />
                    </div>
                    <div>
                      <span>To </span>
                      <DatePicker
                        selected={endDate}
                        dateFormat="yyyy-MM-dd"
                        onChange={(date) => endDateChangeHandler(date)}

                        // readOnly={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    Max Students per Subscription* <span>*</span>
                  </label>
                  <input
                    type="number"
                    name="registeredStudent"
                    value={formState.registeredStudent}
                    onChange={(e) => onChangeHandler(e)}
                  />
                </div>
              </div>
              <div className="entrySection-right">
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    Language For Content <span>*</span>
                  </label>
                  <select
                    name="language"
                    value={formState.language}
                    onChange={(e) => onChangeHandler(e)}
                  >
                    {" "}
                    <option>Choose Language</option>
                    {allLanguage && allLanguage.length > 0 ? (
                      allLanguage.map((langData, index) => {
                        return (
                          <option
                            value={langData._id}
                            key={index}
                          >{`${langData.name} (${langData.isoCode})`}</option>
                        );
                      })
                    ) : (
                      <option value="">No data available</option>
                    )}
                  </select>
                </div>
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    Subtitle Language <span>*</span>
                  </label>
                  {/* <input type='text' /> */}
                  <select
                    name="subTitleLanguage"
                    value={formState.subTitleLanguage}
                    onChange={(e) => onChangeHandler(e)}
                  >
                    {" "}
                    <option>Choose Subtitle language</option>
                    {allLanguage && allLanguage.length > 0 ? (
                      allLanguage.map((langData, index) => {
                        return (
                          <option
                            value={langData._id}
                            key={index}
                          >{`${langData.name} (${langData.isoCode})`}</option>
                        );
                      })
                    ) : (
                      <option value="">No data available</option>
                    )}
                  </select>
                </div>
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    School Contact Information (primary)
                  </label>
                  <div className="schoolAdmin">
                    <input
                      type="text"
                      placeholder="name"
                      name="schoolAdminPrimaryDetailName"
                      value={formState.schoolAdminPrimaryDetailName}
                      onChange={(e) => onChangeHandler(e)}
                    />

                    <input
                      type="email"
                      placeholder="email"
                      name="schoolAdminPrimaryDetailEmail"
                      value={formState.schoolAdminPrimaryDetailEmail}
                      onChange={(e) => onChangeHandler(e)}
                    />

                    <div className="contact">
                      <PhoneInput
                        placeholder="Enter phone number"
                        // value={value}
                        // onChange={setValue}
                        value={schoolAdminPrimaryDetailPhone}
                        onChange={setschoolAdminPrimaryDetailPhone}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group-inner">
                  <label htmlFor="schoolName">
                    School Contact Information (secondary)
                  </label>
                  <div className="schoolAdmin">
                    <input
                      type="text"
                      placeholder="name"
                      name="schoolAdminSecondaryDetailName"
                      value={formState.schoolAdminSecondaryDetailName}
                      onChange={(e) => onChangeHandler(e)}
                    />

                    <input
                      type="email"
                      placeholder="email"
                      name="schoolAdminSecondaryDetailEmail"
                      value={formState.schoolAdminSecondaryDetailEmail}
                      onChange={(e) => onChangeHandler(e)}
                    />

                    <div className="contact">
                      <PhoneInput
                        placeholder="Enter phone number"
                        // value={value}
                        // onChange={setValue}
                        value={schoolAdminSecondaryDetailPhone}
                        onChange={setschoolAdminSecondaryDetailPhone}
                      />
                    </div>
                  </div>
                </div>
                <div className="button-entry-school">
                  {/* <button onClick={formSubmitHandler}>Continue</button> */}
                  <button
                    className={saving && "disabled-btn"}
                    // onClick={() => {
                    //   changePageStateHandler(0);
                    // }}
                    onClick={formSubmitHandler}
                    disabled={saving}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          open={msgState}
          onClose={handleClose}
          action={action}
          style={{ marginTop: "50px" }}
          message={addedSuccessMsg}
        />
      </ThemeProvider>

      <GlobalModal
        open={addRegionModel}
        close={closeAddRegionModelHandler}
        heading="
      Add new Region"
      >
        <div className="addLanguageModelMain py-4">
          <div className="d-flex flex-column my-2">
            <label htmlFor="userName">Country</label>
            <input
              className="py-1 my-2"
              type="text"
              name="country"
              value={regionName.country}
              onChange={(e) => regionInputChangeHandler(e)}
            />
          </div>
          <div className="d-flex flex-column my-2">
            <label htmlFor="userName">Region</label>
            <input
              className="py-1 my-2"
              type="text"
              name="name"
              value={regionName.name}
              onChange={(e) => regionInputChangeHandler(e)}
            />
          </div>
          <div className="confirm-close-btns">
            <ConfirmButton>
              <div className="open-btn" onClick={addRegionHandler}>
                Add
              </div>
            </ConfirmButton>
            <CancelButton>
              <div className="close-btn" onClick={closeAddRegionModelHandler}>
                Cancel
              </div>
            </CancelButton>
          </div>
        </div>
      </GlobalModal>
    </>
  );
};

export default NewSchoolEntry;
