import React from "react";
import TableMain from "../Table";
import Box from "@mui/material/Box";

const AnalyticsSystem = ({ systemRecords }) => {
  const headCells = [
    {
      id: "contentTitle",
      label: "Name",
      numeric: false,
      disablePadding: false,
    },

    {
      id: "fileLocation",
      label: "File Location",
      numeric: false,
      disablePadding: false,
    },

    // {
    //   id: "filesize",
    //   label: "File Size",
    //   numeric: true,
    //   disablePadding: false,
    // },
    // {
    //   id: "createdAt",
    //   label: "Action",
    //   numeric: false,
    //   disablePadding: false,
    // },
    {
      id: "download",
      label: "",
      numeric: false,
      disablePadding: false,
    },
  ];

  return (
    <div className="tabpanel-content">
      <div className="header-system">Upload record</div>
      <div className="analyticsSystem-outer">
        <Box sx={{ width: "70%" }}>
          <TableMain
            headCells={headCells}
            resultData={systemRecords}
            topic="AnalyticsSystem"
          />
        </Box>
        {/* <Box
          sx={{
            marginTop: "25px",
            width: "30%",
            background: "#F5F5F5",
            borderRadius: "10px",
            maxHeight: "355px",
            padding: "21px",
          }}
        >
          <div className='header'>Sort By</div>
          <div className='group'>
            <input type='checkbox' id='allRecord' />
            <label htmlFor='allRecord'>All Record</label>
          </div>
          <div className='header-sm'>School</div>
          <div className='selectGroup'>
            <select className='select-1' name='' id=''>
              <option value='sdfds'>sdf</option>
            </select>
            <input type='text' />
          </div>
          <div className='header-sm'>Region</div>
          <div className='checkbox-containers'>
            <div className='group'>
              <input type='checkbox' id='allRecord' />
              <label htmlFor='allRecord'>Hongkong</label>
            </div>
            <div className='group'>
              <input type='checkbox' id='allRecord' />
              <label htmlFor='allRecord'>Indonesia</label>
            </div>
            <div className='group'>
              <input type='checkbox' id='allRecord' />
              <label htmlFor='allRecord'>Japan</label>
            </div>
            <div className='group'>
              <input type='checkbox' id='allRecord' />
              <label htmlFor='allRecord'>Korea</label>
            </div>
            <div className='group'>
              <input type='checkbox' id='allRecord' />
              <label htmlFor='allRecord'>Mainland China</label>
            </div>
            <div className='group'>
              <input type='checkbox' id='allRecord' />
              <label htmlFor='allRecord'>Taiwan</label>
            </div>
            <div className='group'>
              <input type='checkbox' id='allRecord' />
              <label htmlFor='allRecord'>Singapore</label>
            </div>
            <div className='group'>
              <input type='checkbox' id='allRecord' />
              <label htmlFor='allRecord'>Thailand</label>
            </div>
          </div>
        </Box> */}
      </div>
    </div>
  );
};

export default AnalyticsSystem;
