import React, { useState, useEffect } from 'react'
import TableMain from '../../components/Table'
import search from '../../assets/search.svg'
import api from '../../services/api'
import { jsPDF } from 'jspdf'
import Sidebar from '../../components/Sidebar'
import { debounceFunction } from '../../utils/helper'
import 'jspdf-autotable'

const resultData = [
  {
    rank: 1,
    school: 'B High School',
    name: 'Prerit Bhandari',
    value: 300000,
    reputation: 400,
    number: 50,
    satisfaction: '100%',
    remaining: 20000,
  },
]
const headCells = [
  {
    id: 'rank',
    label: 'Rank',
    numeric: false,
    disablePadding: false,
  },
  { id: 'school', label: 'School', numeric: false, disablePadding: false },
  {
    id: 'name',
    label: 'Student name',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'value',
    label: 'Company value',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'reputation',
    label: 'Company reputation',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'number',
    label: 'Employee number',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'satisfaction',
    label: 'Employee satisfaction',
    numeric: false,
    disablePadding: false,
  },
  {
    id: 'cash',
    label: 'Remaining cash',
    numeric: false,
    disablePadding: false,
  },
]
export default function Leaderboard() {
  const downloadPdfDocument = () => {
    const option = {
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    }
    const newDoc = new jsPDF(option)
    newDoc.setFontSize(16)
    const title = 'Leaderboard'
    const headers = [headCells.map((item) => item.label)]
    const data = selectedLeaderboard.map((item) => {
      return [
        item?.rank,
        item?.schoolId?.name,
        item?.name,
        item?.totalCampnayValuation,
        item?.totalcompanyReputation,
        item?.totalemployNumber,
        item?.totalemploySatisfaction,
        item?.totalCash,
      ]
    })
    let content = {
      startY: 20,
      head: headers,
      body: data,
    }
    newDoc.text(title, 40, 40)
    newDoc.autoTable(content)
    newDoc.save('leaderboard.pdf')
    return
  }
  const [leaderboard, setLeaderboard] = useState([])
  const [selectedLeaderboard, setSelectedLeaderBoard] = useState([])
  const [entries, setEntries] = useState('all')
  const [region, setRegion] = useState('all')
  const [schoolName, setSchoolName] = useState('')
  const [className, setClassName] = useState('')

  const getLeaderboard = async () => {
    try {
      const res = await api.get('/api/v1/siteadmin/leaderboard')
      if (res && res.ok) {
        setLeaderboard(res.data)
        setSelectedLeaderBoard(res.data)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const checkSchoolName = (item, schoolName) => {
    if (
      schoolName.toUpperCase() ===
      item?.schoolId?.name?.toUpperCase()?.substring(0, schoolName.length)
    ) {
      return true
    } else {
      return false
    }
  }

  const checkClassName = (item, className) => {
    if (
      className.toUpperCase() ===
      item?.classroom?.className?.toUpperCase()?.substring(0, className.length)
    ) {
      return true
    } else {
      return false
    }
  }

  const checkRegion = (item, region) => {
    if (region.toUpperCase() === item?.schoolId?.region?.name?.toUpperCase()) {
      return true
    } else {
      return false
    }
  }

  const filterSearch = (region, schoolName, className) => {
    if (region === 'all') {
      if (!schoolName && !className) {
        setSelectedLeaderBoard(leaderboard)
        return
      }
      if (schoolName && !className) {
        let newLeaderboard = []
        newLeaderboard = leaderboard.filter((item) =>
          checkSchoolName(item, schoolName)
        )
        setSelectedLeaderBoard(newLeaderboard)
        return
      }
      if (!schoolName && className) {
        let newLeaderboard = []
        newLeaderboard = leaderboard.filter((item) =>
          checkClassName(item, className)
        )
        setSelectedLeaderBoard(newLeaderboard)
        return
      }
      if (schoolName && className) {
        let newLeaderboard = []
        newLeaderboard = leaderboard.filter(
          (item) =>
            checkSchoolName(item, schoolName) && checkClassName(item, className)
        )
        setSelectedLeaderBoard(newLeaderboard)
        return
      }
    }

    if (region !== 'all') {
      if (!schoolName && !className) {
        let newLeaderboard = []
        newLeaderboard = leaderboard.filter((item) => checkRegion(item, region))
        setSelectedLeaderBoard(newLeaderboard)
        return
      }
      if (schoolName && !className) {
        let newLeaderboard = []
        newLeaderboard = leaderboard.filter(
          (item) =>
            checkRegion(item, region) && checkSchoolName(item, schoolName)
        )
        setSelectedLeaderBoard(newLeaderboard)
        return
      }
      if (!schoolName && className) {
        let newLeaderboard = []
        newLeaderboard = leaderboard.filter(
          (item) => checkRegion(item, region) && checkClassName(item, className)
        )
        setSelectedLeaderBoard(newLeaderboard)
        return
      }
      if (schoolName && className) {
        let newLeaderboard = []
        newLeaderboard = leaderboard.filter(
          (item) =>
            checkRegion(item, region) &&
            checkClassName(item, className) &&
            checkSchoolName(item, schoolName)
        )
        setSelectedLeaderBoard(newLeaderboard)
        return
      }
    }
  }

  useEffect(() => {
    filterSearch()
  }, [])

  const handleSelectChange = (e) => {
    const { value } = e.target
    setRegion(value)
    filterSearch(value, schoolName, className)
  }

  const handleSchoolName = (...args) => {
    const { value } = args[0].target
    setSchoolName(value)
    filterSearch(region, value, className)
  }

  const handleClassName = (...args) => {
    const { value } = args[0].target
    setClassName(value)
    filterSearch(region, schoolName, value)
  }

  const optimisedSchoolNameHandler = debounceFunction(handleSchoolName, 300)
  const optimisedClassNameHandler = debounceFunction(handleClassName, 300)

  useEffect(() => {
    getLeaderboard()
    getAlllocations()
  }, [])
  const [allLocations, setAllLocations] = useState([])
  const getAlllocations = async () => {
    try {
      const data = await api.get(`/api/v1/siteadmin/view/all/region`)
      if (data && data.ok) {
        setAllLocations(data.data)
      }
    } catch (error) {
      console.error('error', error)
    }
  }
  return (
    <>
      <Sidebar />
      <div className='main'>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}
        >
          <div className='heading-main'>Leaderboard</div>
          <div>
            <button
              onClick={downloadPdfDocument}
              style={{
                marginRight: '2rem',
              }}
              className='school-btn school-btn-sm'
            >
              Export{' '}
            </button>
          </div>
        </div>
        <div className='student container-page'>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              width: '100%',
              marginBottom: 10,
            }}
          >
            <div>
              <strong>Region</strong>
              <div className='input-box-outer'>
                {console.error('all locations', allLocations)}
                <select
                  style={{ padding: 5 }}
                  className='input-box'
                  value={region}
                  onChange={handleSelectChange}
                >
                  <option value='all'>Select Region</option>
                  {allLocations.map((location, index) => (
                    <option value={location.name}>{location.name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div style={{ marginLeft: '0.5rem' }}>
              <div>
                {/* <input type='checkbox' name='' id='' /> */}
                <strong>School</strong>
              </div>
              <div className='input-box-outer'>
                <input
                  className='input-box'
                  placeholder='School name'
                  type='text'
                  // value={schoolName}
                  onChange={optimisedSchoolNameHandler}
                />
                <img src={search} alt='School' />
              </div>
              <div></div>
            </div>
            <div style={{ marginLeft: '0.5rem' }}>
              <div>
                {/* <input type='checkbox' name='' id='' /> */}
                <strong>Class</strong>
              </div>
              <div className='input-box-outer'>
                <input
                  className='input-box'
                  placeholder='Class name'
                  type='text'
                  // value={schoolName}
                  onChange={optimisedClassNameHandler}
                />
                <img src={search} alt='School' />
              </div>
              <div></div>
            </div>
            <div style={{ marginLeft: '0.2rem' }}>
              <div>
                {/* <input type='checkbox' name='' id='' /> */}
                <strong>List showing</strong>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '120%',
                  maxWidth: '120%',
                  justifyContent: 'flex-start',
                  marginTop: '0.4rem',
                  overflowX: 'hidden',
                }}
              >
                <div style={{ flexBasis: '100%' }}>
                  <input
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEntries('all')
                      }
                    }}
                    type='checkbox'
                    name='entries'
                    id=''
                    value='all'
                    checked={entries === 'all' ? true : false}
                  />
                  <span style={{ marginRight: 20 }}> All entries</span>
                </div>
                <div style={{ flexBasis: '100%' }}>
                  <input
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEntries(10)
                      }
                    }}
                    type='checkbox'
                    name=''
                    id=''
                    value={10}
                    checked={entries === 10 ? true : false}
                  />
                  <span style={{ marginRight: 20 }}> Top 10</span>
                </div>
                <div style={{ flexBasis: '100%' }}>
                  <input
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEntries(30)
                      }
                    }}
                    type='checkbox'
                    name=''
                    id=''
                    value={30}
                    checked={entries === 30 ? true : false}
                  />
                  <span style={{ marginRight: 20 }}> Top 30</span>
                </div>
                <div style={{ flexBasis: '100%' }}>
                  <input
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEntries(50)
                      }
                    }}
                    type='checkbox'
                    name=''
                    id=''
                    value={50}
                    checked={entries === 50 ? true : false}
                  />
                  <span style={{ marginRight: 20 }}> Top 50</span>
                </div>
              </div>
            </div>
          </div>

          <div id='leaderboardTable'>
            <TableMain
              headCells={headCells}
              resultData={
                entries === 'all'
                  ? selectedLeaderboard
                  : selectedLeaderboard.slice(0, entries)
              }
              topic='leaderboard'
            />
          </div>
        </div>
      </div>
    </>
  )
}
