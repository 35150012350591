import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Link } from 'react-router-dom'
const BreadCrumb = (props) => {
  const url = props.url.split('/')
  let modified = url.filter((url) => url !== '')
  let againModified = modified.map((item, key) => item.split('-').join(' '))
  return (
    <div className='breadcrumb-outer'>
      {againModified.map((item, key) => (
        <div className='breadcrumb-title' key={key}>
          {key !== 0 && <NavigateNextIcon />} {item}
        </div>
      ))}
    </div>
  )
}

export default BreadCrumb
