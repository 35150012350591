import React, { useState, useEffect } from "react";
import removeIcon from "../../../assets/removeIcon.svg";
import uploadIconWhite from "../../../assets/uploadIconWhite.svg";
import avatar from "../../../assets/avatar.svg";
import imageIcon from "../../../assets/imageIcon.svg";
import { GlobalModal } from "../../Modal";
import warning from "../../../assets/warning.svg";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../Tabs";
import { SetProps } from "../../Tabs";
import plus from "../../../assets/plus.svg";
import config from "../../../config";
import api from "../../../services/api";
import Alert from "@mui/material/Alert";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { consoleError, consoleLog } from "../../../utils/helper";
import { useParams } from "react-router-dom";

const CharacterContainer = ({
  gameCharacters,
  LoadCharacters,
  setLoadCharacters,
}) => {
  const { language } = useParams();
  const [languageId, setLanguageId] = useState("");

  const [success, setSuccess] = useState(false);

  const [characterId, setCharacterId] = useState("");

  const [characterUploadScreen, setCharcterUploadScreen] = useState(false);

  const [addModelState, setAddModelState] = useState(false);

  const [value, setValue] = React.useState(0);

  const [charaName, setCharaName] = useState({
    language: languageId,
    name: "",
  });
  const [allLanguage, setAllLanguage] = useState([]);

  const [charaSpeciality, setCharaSpeciality] = useState("");
  const [charaDesc, setCharaDesc] = useState({
    language: languageId,
    dialog: "",
  });
  const [conversation, setConversation] = useState("");
  const [characterImg, setCharacterImg] = useState("");
  const [bgImg, setBgImg] = useState("");

  const [uploadingConversation, setUploadingConversation] = useState(false);
  const [uploadingCharaImage, setUploadingCharaImage] = useState(false);
  const [uploadingBgImg, setUploadingBgImg] = useState(false);
  const [addingCharacter, setAddingCharacter] = useState(false);

  const [choosenImage, setChooseImage] = useState("");
  const [choosenBgImage, setChooseBgImage] = useState("");
  const [choosenConv, setChooseConv] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [delId, setDelId] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNameChange = (e) => {
    const { name, value } = e.target;
    setCharaName((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };
  const handleSpChange = (val) => {
    setCharaSpeciality(val);
  };
  // const handleDescChange = (val) => {
  //   setCharaDesc(val);
  // };

  const handleDescChange = (e) => {
    const { name, value } = e.target;
    setCharaDesc((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  };

  const uploadImage = async (e) => {
    // console.log(e.target.files[0]);
    //console.log("reached here");
    setChooseImage(e.target.files[0].name);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("character", file);
    try {
      setUploadingCharaImage(true);
      const res = await api.post(`/api/v1/siteadmin/upload/chara`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res && res.ok) {
        setCharacterImg(res.url);
        setUploadingCharaImage(false);
      }
    } catch (error) {
      console.error(error);
      setUploadingCharaImage(false);
    }
  };

  const uploadBgImage = async (e) => {
    //console.log("reached here");
    setChooseBgImage(e.target.files[0].name);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("bgImg", file);
    try {
      setUploadingBgImg(true);
      const res = await api.post(`/api/v1/siteadmin/upload/bg-img`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res && res.ok) {
        setBgImg(res.url);
        setUploadingBgImg(false);
      }
    } catch (error) {
      console.error(error);
      setUploadingBgImg(false);
    }
  };

  const uploadConvo = async (e) => {
    //console.log("reached here");
    setChooseConv(e.target.files[0].name);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("conversation", file);
    formData.append("language", languageId);
    try {
      setUploadingConversation(true);
      const res = await api.post(
        `/api/v1/siteadmin/upload/character/conversation`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        setConversation(res.data);
        setUploadingConversation(false);
      }
    } catch (error) {
      console.error(error);
      setUploadingConversation(false);
    }
  };

  const [isInvestor, setIsInvestor] = useState(false);

  const addCharacter = async () => {
    let res = {};
    try {
      setAddingCharacter(true);
      if (characterId.length > 1) {
        res = await api.patch(
          `/api/v1/siteadmin/edit/game-character/${characterId}`,
          {
            name: [charaName],
            desc: [charaDesc],
            speciality: charaSpeciality,
            img: characterImg,
            bgImg: bgImg,
            welcomeDialogue: [charaDesc],
            conversation: conversation,
            isInvestor: isInvestor,
          }
        );
      } else {
        res = await api.post("/api/v1/siteadmin/add/game-character", {
          name: charaName,
          desc: charaDesc,
          speciality: charaSpeciality,
          largeImg: characterImg,
          bgImg: bgImg,
          welcomeDialogue: charaDesc,
          conversation: conversation,
          isInvestor: isInvestor,
        });
      }
      if (res && res.ok) {
        clearUpdateData();
        setAddingCharacter(false);
        setLoadCharacters(!LoadCharacters);
        setSuccess(true);
        setTimeout(() => {
          setCharcterUploadScreen(false);
          setSuccess(false);
        }, 1000);
      }
    } catch (error) {
      setAddingCharacter(false);
      console.error(error);
    }
  };

  const fetchSingleCharacter = async () => {
    try {
      const res = await api.get(
        `/api/v1/siteadmin/view/game-character/${characterId}/${language}`
      );
      if (res && res.ok) {
        setCharaName({
          language:
            res?.data?.name &&
            res?.data?.name.length > 0 &&
            res?.data?.name[0]?.language
              ? res?.data?.name[0]?.language
              : languageId,
          name: res?.data?.name[0]?.name,
        });
        // setAllLanguage(res.data.name);
        setCharaSpeciality(res.data.speciality);
        // setCharaDesc(res.data.desc[0].dialog);
        setCharaDesc({
          language:
            res?.data?.desc &&
            res?.data?.desc.length > 0 &&
            res?.data?.desc[0]?.language
              ? res?.data?.desc[0]?.language
              : languageId,
          dialog: res?.data?.desc[0]?.dialog,
        });
        setCharacterImg(res.data.largeImg);
        setBgImg(res.data.bgImg);
        setConversation(res.data.conversation);
        setIsInvestor(res.data.isInvestor);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLanguageId();
  }, []);

  const fetchLanguageId = async () => {
    try {
      const resData = await api.get(
        `/api/v1/siteadmin/view/language/details/${language}`
      );
      if (resData && resData.ok) {
        setLanguageId(resData.data._id);
        setCharaDesc((prevValue) => {
          return {
            ...prevValue,
            language: resData.data._id,
          };
        });

        setCharaName((prevValue) => {
          return {
            ...prevValue,
            language: resData.data._id,
          };
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const deleteCharacter = async () => {
    try {
      const res = await api.delete(
        `/api/v1/siteadmin/delete/game-character/${delId}`
      );
      if (res && res.ok) {
        setLoadCharacters(!LoadCharacters);
        setDelId("");
        setDeleteModal(false);
      }
    } catch (error) {
      console.error(error);
      setDeleteModal(false);
    }
  };

  const clearUpdateData = () => {
    setCharacterId("");
    setCharaName("");
    setCharaSpeciality("");
    setCharaDesc("");
    setCharacterImg("");
    setBgImg("");
    setConversation("");
  };

  useEffect(() => {
    if (characterId.length) {
      fetchSingleCharacter();
    }
  }, [characterId]);

  const openModelHandler = () => {
    setAddModelState(true);
  };

  const closeModelHandler = () => {
    setAddModelState(false);
  };

  // ADD NEW MODEL STATE ENDS

  // EDIT/UPLOAD NEW MEDIA MODEL STATE STARTS
  const [updateMediaModelState, setUpdateMediaModelState] = useState(false);

  const openUpdateModelHandler = () => {
    setUpdateMediaModelState(true);
  };

  const closeUpdateModelHandler = () => {
    setUpdateMediaModelState(false);
  };

  // EDIT/UPLOAD NEW MEDIA MODEL STATE ENDS

  // Get platform language

  useEffect(() => {
    if (characterId != "") {
      fetchAllLanguage();
    }
  }, []);

  const fetchAllLanguage = async () => {
    try {
      const langRes = await api.get("/api/v1/siteadmin/view/all/language");
      if (langRes && langRes.ok) {
        setAllLanguage(langRes.data);
      }
    } catch (err) {
      consoleError(err);
    }
  };
  // Language ends

  const getCharacterNameOfThisLanguage = (name, id) => {
    if (document.getElementById(id)) {
      for (var i = 0; i < name.length; i++) {
        if (
          JSON.stringify(languageId) === JSON.stringify(name[i].language._id)
        ) {
          document.getElementById(id).innerText = name[i].name;
        }
      }
    }
  };

  return characterUploadScreen ? (
    <div className="characterUploadScreen">
      <div className="container-fluid">
        <div className="row gx-5">
          <div className="col-md-6">
            <div className="inputField">
              <label>Character Name</label>
              <input
                // value={allLanguage[index].charName}
                value={charaName.name}
                name={"name"}
                onChange={(e) => {
                  handleNameChange(e);
                }}
                type="text"
              />
            </div>

            <div className="inputField">
              <label>Character Specialty</label>
              <input
                value={charaSpeciality}
                onChange={(e) => {
                  handleSpChange(e.target.value);
                }}
                type="text"
              />
            </div>
            <div className="inputField">
              <label>Description language</label>
              {/* <input type="text" className="desc" /> */}
              <textarea
                value={charaDesc.dialog}
                name="dialog"
                onChange={(e) => {
                  handleDescChange(e);
                }}
              ></textarea>
            </div>
          </div>
          <div className="col-md-6">
            <div className="image-upload">
              <div className="inputField">
                <label>Character Image</label>
              </div>
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={imageIcon} alt="" />
                </label>
                <span>Upload IMAGE</span>

                <input
                  onChange={(e) => uploadImage(e)}
                  type="file"
                  className="input-file"
                  accept="image/png, image/jpeg"
                  id="input_file"
                />

                <span>{choosenImage}</span>
                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
              {characterImg ? (
                <div>
                  <img
                    width="100px"
                    height="100px"
                    src={
                      config.app.awsBaseUrl +
                      "uploads/admin/character/" +
                      characterImg
                    }
                    alt="img"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="image-upload">
              <div className="inputField">
                <label>Character Background</label>
              </div>
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={imageIcon} alt="" />
                </label>
                <span>Upload IMAGE</span>

                <input
                  onChange={(e) => uploadBgImage(e)}
                  type="file"
                  className="input-file"
                  id="input_file2"
                  accept="image/png, image/jpeg"
                />

                <span>{choosenBgImage}</span>
                <label className="change-img" htmlFor="input_file2">
                  Browse{" "}
                </label>
              </div>
              {bgImg ? (
                <div>
                  <img
                    width="100px"
                    height="100px"
                    src={config.app.awsBaseUrl + bgImg}
                    alt="img"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="image-upload">
              <FormControlLabel
                control={
                  <Switch
                    checked={isInvestor}
                    onChange={() => setIsInvestor(!isInvestor)}
                    name="isInvestor"
                  />
                }
                label="Is this character a investor?"
              />
            </div>
            {isInvestor ? (
              <div className="image-upload">
                <div className="inputField">
                  <label>Conversation</label>
                </div>
                <div className="image-upload-inner">
                  <label htmlFor="input_file3">
                    {" "}
                    {/* <img src={imageIcon} alt="" /> */}
                  </label>
                  <span>Upload Conversation</span>

                  <input
                    onChange={(e) => uploadConvo(e)}
                    type="file"
                    className="input-file"
                    id="input_file3"
                  />

                  <span>{choosenConv}</span>
                  <label className="change-img" htmlFor="input_file3">
                    Browse{" "}
                  </label>
                </div>
                {conversation.length > 0 ? <div>File Selected</div> : ""}
              </div>
            ) : (
              ""
            )}

            {success ? (
              <Alert severity="success">Character Successfully Added.</Alert>
            ) : (
              ""
            )}
            <div className="footerBar">
              <button
                disabled={addingCharacter}
                onClick={addCharacter}
                className="create"
              >
                {characterId.length ? "Update" : "Create"}
              </button>
              <button
                onClick={() => {
                  setCharcterUploadScreen(false);
                  clearUpdateData();
                }}
                className="cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="btnDiv">
        <button
          onClick={() => {
            setCharcterUploadScreen(true);
          }}
        >
          <img src={uploadIconWhite} alt="upoadIcon" />
          Add New
        </button>
      </div>
      <div className="mediaContainerDiv">
        {gameCharacters &&
          gameCharacters.length &&
          gameCharacters.map((d, index) => {
            return (
              <div
                className="cardDiv"
                key={index}
                style={{ display: "flex" }}
                onClick={() => {
                  setCharacterId(d._id);
                  setCharcterUploadScreen(true);
                }}
              >
                <div
                  className="removeMedia"
                  style={{ zIndex: 2 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteModal(true);
                    setDelId(d._id);
                  }}
                >
                  <img src={removeIcon} alt="remove" />
                </div>
                <div className="imageDiv">
                  <img
                    src={
                      d.largeImg
                        ? config.app.awsBaseUrl +
                          "uploads/admin/character/" +
                          d.largeImg
                        : avatar
                    }
                    alt="avatar"
                  />
                </div>
                <divv className="descDiv">
                  <span className="title" id={`characterName-${d._id}`}>
                    {getCharacterNameOfThisLanguage(
                      d.name,
                      `characterName-${d._id}`
                    )}
                  </span>
                  <span className="subTitle">
                    {d.speciality ? d.speciality : ""}
                  </span>
                </divv>
              </div>
            );
          })}
      </div>

      {/* ADD MEDIA MODEL STARTS */}
      <GlobalModal
        type="upload"
        open={addModelState}
        close={closeModelHandler}
        heading="Media Upload"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div className="tabs-section tabs-section-upload">
              <Tabs
                sx={{
                  width: "80%",
                  marginBottom: "33px",
                  textTransform: "lowercase",
                }}
                value={value}
                indicatorColor="green"
                onChange={handleChange}
              >
                <Tab label="IMAGE" {...SetProps(0)} />
                <Tab label="BACKGROUND" {...SetProps(1)} />
                <Tab label="MUSIC" {...SetProps(2)} />
              </Tabs>
            </div>
          </Box>
          <TabPanel value={value} index={0} sx={{ position: "relative" }}>
            <div className="image-upload">
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={imageIcon} alt="" />
                </label>
                <span>Upload IMAGE</span>

                <input
                  onChange={(e) => uploadImage(e)}
                  type="file"
                  class="input-file"
                  id="input_file"
                />

                <span>{choosenImage}</span>
                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Elementum,
                </span>
              </div>
            </div>
            <div className="newEntryBottom-wrapper">
              <div className="btn-next" onClick={() => setValue(1)}>
                Next
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} sx={{ position: "relative" }}>
            <div className="image-upload">
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={imageIcon} alt="Upload Video" />
                </label>
                <span>Uplode BACKGROUND</span>
                <input
                  onChange={(e) => uploadImage(e)}
                  type="file"
                  class="input-file"
                  id="input_file"
                />

                <span>{choosenImage}</span>
                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Elementum,
                </span>
              </div>
            </div>
            <div className="newEntryBottom-wrapper">
              <div
                className="btn-next"
                style={{ background: "#9D9D9D" }}
                onClick={() => setValue(2)}
              >
                Next
              </div>
            </div>
          </TabPanel>{" "}
          <TabPanel value={value} index={2} sx={{ position: "relative" }}>
            <div className="image-upload">
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={imageIcon} alt="" />
                </label>
                <span>Upload MUSIC</span>

                <input
                  onChange={(e) => uploadImage(e)}
                  type="file"
                  class="input-file"
                  id="input_file"
                />

                <span>{choosenImage}</span>
                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Elementum,
                </span>
              </div>
            </div>
            <div className="newEntryBottom-wrapper">
              <div className="btn-next btn-done" onClick={closeModelHandler}>
                Done
              </div>
            </div>
          </TabPanel>
        </Box>
      </GlobalModal>
      {/* ENDS */}

      {/* UPDATE MEDIA MODEL STARTS */}
      <GlobalModal
        type="upload"
        open={updateMediaModelState}
        close={closeUpdateModelHandler}
        heading="Character Edit"
      >
        <div className="editCharacterMediaAddMediaMainDiv">
          <span className="modeltitle">Character name</span>
          <input type="text" />

          <span className="modeltitle">Upload Chartacter Image</span>
          <div className="editMediaAddMediaMainDivInnerDiv">
            <img src={plus} alt="add icon" />
            <span className="subTitle">Add file</span>
          </div>
        </div>

        <div className="btn-groups">
          <button className="export">Done</button>
          <button className="cancel" onClick={closeUpdateModelHandler}>
            Back
          </button>
        </div>
      </GlobalModal>
      {/* UPDATE MEDIA MODEL ENDS */}

      <GlobalModal
        type="delete"
        open={deleteModal}
        close={closeUpdateModelHandler}
        heading="Delete character"
      >
        <p>Are you sure you want to delete this character ?</p>

        <div className="btn-groups">
          <button className="export" onClick={deleteCharacter}>
            Yes
          </button>
          <button
            className="cancel"
            onClick={() => {
              setDeleteModal(false);
            }}
          >
            Back
          </button>
        </div>
      </GlobalModal>
    </>
  );
};
export default CharacterContainer;
