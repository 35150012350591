import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import stemUnicorn from "../assets/stemUnicorn.svg";
import globe from "../assets/globe.svg";
import globeActive from "../assets/globeActive.svg";

import calendardates from "../assets/calendardates.svg";
import calendardatesActive from "../assets/calendardatesActive.svg";

import rocket from "../assets/rocket.svg";
import glasses from "../assets/glasses.svg";
import book from "../assets/book.svg";
import bookActive from "../assets/bookActive.svg";
import settings from "../assets/settings.svg";
import analytics from "../assets/analytics.svg";
import analyticsActive from "../assets/analyticsActive.svg";
import { useUser } from "../context/User";

import { useLocation } from "react-router-dom";

const Sidebar = () => {
  const { setIsAuthenticated } = useUser();

  const location = useLocation();
  const [currentPage, setCurrentPage] = useState("");

  const logOut = () => {
    localStorage.removeItem("accessToken");
    window.location.reload();
  };

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="sidebar-top">
          <div className="sidebar-item sidebar-item-1">
            <Link to="/dashboard">
              <img
                className="icon icon-1 stemUnicornIcon"
                src={stemUnicorn}
                alt=""
              />
              <span className="span-1">Site Admin</span>
            </Link>
          </div>
          <div
            className={`sidebar-item sidebar-item-2 ${
              currentPage === "/dashboard" ? "active" : ""
            }`}
          >
            <Link to="/dashboard">
              <img
                className="icon globeIcon"
                src={currentPage === "/dashboard" ? globeActive : globe}
                alt=""
              />
              <span className="span-2">Dashboard</span>
            </Link>
          </div>
        </div>
        <div className="sidebar-middle">
          <div
            className={`sidebar-item sidebar-item-3 ${
              currentPage === "/calendar" ? "active" : ""
            }`}
          >
            <Link to="/calendar">
              <img
                className="icon calendarDates"
                src={
                  currentPage === "/calendar"
                    ? calendardatesActive
                    : calendardates
                }
                alt=""
              />
              <span className="span-3">Calendar</span>
            </Link>
          </div>
          <div
            className={`sidebar-item sidebar-item-4 ${
              currentPage === "/leaderboard" ? "active" : ""
            }`}
          >
            <Link to="/leaderboard">
              <img src={rocket} className="icon rocket" alt="" />
              <span className="span-4">LeaderBoard</span>
            </Link>
          </div>
          {/* <div className='sidebar-item sidebar-item-5'>
            <Link to='/'>
              <img src={glasses} className='icon glasses' alt='' />
              <span className='span-5'>Classroom Management</span>
            </Link>
          </div> */}
          <div
            className={`sidebar-item sidebar-item-5 ${
              currentPage === "/platformManagement" ? "active" : ""
            }`}
          >
            <Link to="/platformManagement">
              <img
                src={currentPage === "/platformManagement" ? bookActive : book}
                className="icon book"
                alt=""
              />
              <span className="span-5">Platform Management</span>
            </Link>
          </div>
          <div
            className={`sidebar-item sidebar-item-6 ${
              currentPage === "/analytics" ? "active" : ""
            }`}
          >
            <Link to="/analytics">
              <img
                src={currentPage === "/analytics" ? analyticsActive : analytics}
                className="icon book"
                alt=""
              />
              <span className="span-6">Analytics</span>
            </Link>
          </div>
        </div>
        <div className="sidebar-bottom" onClick={logOut}>
          <div className="logoutDiv">
            <span>Logout</span>
          </div>
          <div className="sidebar-item">
            <a href="#">
              <img src={settings} className="icon settings" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
