import React, { useContext, useState } from "react";
import TableCell from "@mui/material/TableCell";
import { Typography } from "@mui/material";
import { GlobalModal } from "../../components/Modal";
import api from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { WarningModal } from "../Modal";
import { toast } from "react-toastify";
import siteadminService from "../../services/siteadminService";

import config from "../../config";
export default function TeacherCell({ row, school }) {

  const dispatch = useDispatch();

  const [removeTeacherModelState, setRemoveTeacherModelState] = useState(false)

  const removeTeacherHandler = (row) => {
    setRemoveTeacherModelState(true)
  }

  const closeRemoveTeacherModel = () => {
    setRemoveTeacherModelState(false)
  }

  const deleteTeacher = async () => {
  try {


    const data = await api.delete(
      `/api/v1/siteadmin/all/delete/${row?._id}/${row?.email}`
    )
    if (data && data.ok) {
      await siteadminService.getShoolTeacher(school, dispatch);
      closeRemoveTeacherModel();
    }
  } catch (error) {
    toast.error(error.message);
    console.error('error', error);
  }
};

return (
  <>
  <TableCell
    style={{ cursor: 'pointer' }}
    onClick={(e) => InspectOn(e, row._id)}
    align='left'
  >
    {row.name}
  </TableCell>
  <TableCell align="left">{row?.classroom?.className}</TableCell>
  <TableCell align='left'>
    <Typography>
      <button
        className='remove-btn'
        onClick={() => removeTeacherHandler(row)}
      >
        Remove
      </button>
    </Typography>
  </TableCell>

    <GlobalModal
        open={removeTeacherModelState}
        close={closeRemoveTeacherModel}
        heading=''
      >
        <Typography style={{ width: '390px' }}>
          Are you sure you want to delete this teacher ?
        </Typography>

        <div className='btn-groups'>
          <button onClick={deleteTeacher} className='export'>
            Confirm
          </button>
          <button className='cancel' onClick={closeRemoveTeacherModel}>
            Cancel
          </button>
        </div>
      </GlobalModal>
  </>
);
}
