import React, { useState, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import CircularProgress from '@mui/material/CircularProgress'

const Progress = ({ value }) => {
  return (
    <div
      style={{
        width: `${value}%`,
        height: '1.2rem',
        backgroundColor: '#EF5B2C',
        borderRadius: '1.2rem',
      }}
    >
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          color: 'white',
          fontSize: '0.7em',
          position: 'relative',
          top: '10%',
        }}
      >
        {value.toFixed(2) + '%'}
      </div>
    </div>
  )
}

const CollapsibleTable = ({ qNum, row }) => {
  const [open, setOpen] = useState(false)

  return (
    <React.Fragment>
      <TableRow>
        <TableCell align='left' onClick={() => setOpen(!open)}>
          {qNum || 'n/a'}
        </TableCell>
        <TableCell align='left' colSpan={2} onClick={() => setOpen(!open)}>
          {row?.name || 'n/a'}
        </TableCell>
        <TableCell
          align='right'
          onClick={() => setOpen(!open)}
          style={{ paddingRight: '5rem' }}
        >
          {row?.timeTaken || '0'}
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell colSpan={4}>
            <Collapse in={open} timeout='auto' unmountOnExit>
              <div
                style={{
                  fontSize: '0.8em',
                  // position: 'relative',
                  // left: '104%',
                }}
              >
                {row?.options?.map((option, idx) => {
                  return (
                    <div
                      style={{
                        padding: '1rem',
                        paddingLeft: '3rem',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                        }}
                      >
                        <div style={{ fontSize: '1em' }}>{option?.options}</div>
                        <Progress
                          variant='determinate'
                          value={
                            ((option?.noOfChoose || 0) /
                              (row?.attemptedBy || 1)) *
                            100
                          }
                        />
                      </div>
                    </div>
                  )
                })}
              </div>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}

const TableDecisionTree = ({ resultData, loaded }) => {
  const [decisionTree, setDecisionTree] = useState([])

  useEffect(() => {
    if (resultData && Array.isArray(resultData?.decisionTree)) {
      const data = resultData.decisionTree.map((res) => res.questionData)

      // let newData = data.flat(Infinity);
      if (data[0] && data[0].length) {
        data[0].map((question) => {
          resultData.totalStudent.length &&
            resultData.totalStudent.map((student) => {
              if (question._id === student._id) {
                question.attemptedBy = student.count
                return
              }
            })
        })
      }
      if (data[0] && data[0].length) {
        data[0].map((question) => {
          resultData.averageTime.length &&
            resultData.averageTime.map((time) => {
              if (question._id === time._id) {
                question.timeTaken = time.avgTime
                return
              }
            })
        })
      }
      setDecisionTree(data[0])
    }
  }, [resultData])
  // console.log('result data:', resultData)
  // console.log('new Data:', decisionTree)

  return (
    <>
      {loaded ? (
        <Table
          aria-label='collapsible table'
          sx={{
            '& .MuiTableCell-head': {
              fontSize: '18px',
              color: 'black',

              fontFamily: 'Inter',
              borderTopColor: 'transparent',
              fontWeight: 400,
              letterSpacing: '0.0327273px',
              lineHeight: '22px',
              background: '#F5F5F5',
            },
          }}
        >
          <TableHead>
            <TableRow style={{ fontSize: '0.8em' }}>
              <TableCell align='left'>Number</TableCell>
              <TableCell align='left' colSpan={2}>
                Question
              </TableCell>
              <TableCell align='left'>Average Reading Time(s)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              '& .MuiTableCell-body': {
                fontFamily: 'Inter',
              },
              '& .MuiTableCell-body:hover': {
                backgroundColor: '#F5F5F5',
              },
            }}
          >
            {decisionTree && decisionTree.length
              ? decisionTree.map((row, index) => {
                  return (
                    <CollapsibleTable
                      key={row?._id}
                      qNum={index + 1}
                      row={row}
                    />
                  )
                })
              : null}
          </TableBody>
        </Table>
      ) : (
        ''
      )}
    </>
  )
}

export default TableDecisionTree
