import React from 'react'
import plusIcon from '../assets/ExpandIconPlus.svg'

const questions = [
  {
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum, ',
  },
  {
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum, ',
  },
  {
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum, ',
  },
  {
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum, ',
  },
  {
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum, ',
  },
]
const QuestionList = (props) => {
  return (
    <div className='questionList'>
      {props.questions.length > 0 &&
        props.questions.map((question, index) => (
          <div className='question'>
            <div className='heading'>Question #{index + 1}</div>
            <div className='question-content'>{question.question}</div>
          </div>
        ))}
      <div className='uploadMore_here'>
        <img src={plusIcon} alt='plus' />
      </div>
    </div>
  )
}

export default QuestionList
