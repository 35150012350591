import React, { useState, useEffect } from "react";
import tick1 from "../../../assets/tick1.svg";
import { useHistory, useParams } from "react-router-dom";
import api from "../../../services/api";
import produce from "immer";
import { GlobalModal } from "../../Modal";
import uploadIcon from "../../../assets/uploadIcon.svg";
import warning from "../../../assets/warning.svg";
import Box from "@mui/material/Box";

const EditGameContent = () => {
  const { id } = useParams();
  const history = useHistory();

  const [gameData, setGameData] = useState({});

  const [courseModal, setCourseModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const openCourseHandler = () => {
    setCourseModal(true);
  };

  const closeCourseHandler = () => {
    setCourseModal(false);
  };

  const getGameData = async () => {
    try {
      const res = await api.get(
        `api/v1/siteadmin/decision-tree/bycontentid/${id}`
      );
      if (res && res.ok) {
        setGameData(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getGameData();
  }, []);

  const updateQuizQuestion = (index, value, level = 0, optInd = 0) => {
    const nextState = produce(gameData, (drafts) => {
      if (level) {
        drafts.questionData[index].options[optInd][`${value.target.name}`] =
          value.target.value;
      } else {
        drafts.questionData[index][`${value.target.name}`] = value.target.value;
      }
    });
    setGameData(nextState);
  };

  const updateDecisionTree = async () => {
    setLoading(true);
    try {
      const res = await api.patch(
        `api/v1/teacher/classroom/decisionTreeQuestion/update/${gameData.classroomContentId}`,
        {
          questionData: gameData.questionData,
        }
      );
      if (res && res.ok) {
        setLoading(false);
        history.goBack();
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  // ADD NEW MODEL STATE FOR LUCKY DRAW STARTS
  const [addModelForLuckDrawState, setAddModelForLuckyDrawState] =
    useState(false);

  const openModelForCSVLuckyDrawHandler = () => {
    setAddModelForLuckyDrawState(true);
  };

  const closeModelHandler = () => {
    setAddModelForLuckyDrawState(false);
  };

  const [luckySpinFileName, setLuckySpinFileName] = useState("");
  const [luckySpinFile, setLuckySpinFile] = useState("");
  const [errSuccMessage, setErrSuccMessage] = useState("");
  const uploadImage = (e) => {
    setLuckySpinFileName(e.target.files[0].name);
    setLuckySpinFile(e.target.files[0]);
  };

  const uploadLuckySpinHandler = async () => {
    const formData = new FormData();
    formData.append("file", luckySpinFile);
    try {
      const res = await api.post(
        "/api/v1/siteadmin/upload/luckyspinByCsv",
        formData
      );
      if (res && res.ok) {
        setErrSuccMessage(res.message);
        setInterval(() => {
          closeModelHandler();
        }, 3000);
      }
    } catch (err) {
      setErrSuccMessage(err.message);
    }
  };

  // ADD NEW MODEL STATE FOR LUCKY DRAW ENDS

  // ADD NEW MODEL STATE FOR Shop STARTS
  const [addModelForShopState, setAddModelShopState] = useState(false);

  const openModelForCSVShopHandler = () => {
    setAddModelShopState(true);
  };

  const closeModelHandlerShop = () => {
    setAddModelShopState(false);
  };

  const [choosenImageShop, setChooseImageShop] = useState("");
  const [shopFile, setShopFile] = useState("");
  const [shopErrSuccMsg, setShopErrSuccMsg] = useState("");
  const uploadFileShop = async (e) => {
    setChooseImageShop(e.target.files[0].name);
    const file = e.target.files[0];
    setShopFile(file);
  };

  const uploadShopHandler = async () => {
    const formData = new FormData();
    formData.append("shopitem-file", shopFile);
    try {
      const res = await api.post(
        "/api/v1/siteadmin/upload/shop-item",
        formData
      );
      if (res && res.ok) {
        setShopErrSuccMsg(res.message);
        setInterval(() => {
          closeModelHandlerShop();
        }, 3000);
      }
    } catch (err) {
      setShopErrSuccMsg(err.message);
    }
  };

  // ADD NEW MODEL STATE FOR Shop ENDS

  return (
    <>
      <div className="editContentMainDiv">
        <div className="topBar">
          <span>Decision Tree Questions</span>
          <div>
            <span className="acionBtn" onClick={openCourseHandler}>
              Edit
            </span>

            <button
              onClick={openModelForCSVLuckyDrawHandler}
              style={{
                background: "#ef5b2c",
                border: "none",
                borderRadius: "7px",
                color: "#fff",
                fontSize: "10px",
                height: "34px",
                width: "150px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              {/* <img
                src={uploadIcon}
                alt="upoadIcon"
                style={{
                  width: "25px",
                }}
              /> */}
              Edit Lucky Draw
            </button>
            <button
              onClick={openModelForCSVShopHandler}
              style={{
                background: "#ef5b2c",
                border: "none",
                borderRadius: "7px",
                color: "#fff",
                fontSize: "10px",
                height: "34px",
                width: "100px",
              }}
            >
              {/* <img
                src={uploadIcon}
                alt="upoadIcon"
                style={{
                  width: "25px",
                }}
              /> */}
              Edit Shop
            </button>
          </div>
        </div>

        <div className="quizDivMain" style={{ border: "0px", padding: "0px" }}>
          <div className="questionContentDiv">
            {/* QUESTIONS */}
            {gameData &&
              gameData.questionData &&
              gameData.questionData.length &&
              gameData.questionData.map((ques, index) => {
                return (
                  <div className="questionDiv" key={index}>
                    <div className="topdiv">
                      <span>Question#{index + 1}</span>
                      <img src={tick1} alt="icon" />
                    </div>
                    <div className="innerDiv" style={{ height: "auto" }}>
                      <div className="question">{ques.name}</div>
                      <div className="options" style={{ flexWrap: "wrap" }}>
                        {ques.options.map((opt, ind) => {
                          return (
                            <div
                              key={ind}
                              style={{
                                width: "100%",
                                marginTop: "10px",
                                height: "auto",
                                padding: "8px",
                                display: "block",
                              }}
                            >
                              {opt.options || ""}
                              <br />
                              <label>Cash: {opt.cash || ""}</label>
                              <br />
                              <label>
                                Company Reputation:{" "}
                                {opt.companyReputation || ""}
                              </label>
                              <br />
                              <label>
                                Company Valuation: {opt.companyValuation || ""}
                              </label>
                              <br />
                              <label>
                                Employees Satisfications:{" "}
                                {opt.employeesSatisfications || ""}
                              </label>
                              <br />
                              <label>
                                No Of Employee: {opt.noOfEmployee || ""}
                              </label>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                );
              })}
            {/* QUESTIONS ENDS */}
          </div>

          <div className="footerBar">
            <button className="create" onClick={updateDecisionTree}>
              Update
            </button>
            <button
              className="cancel"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <GlobalModal
        type="upload"
        open={courseModal}
        close={closeCourseHandler}
        heading="Course Question"
      >
        <div
          className="course-question-modal-wrapper"
          style={{ paddingBottom: "50px" }}
        >
          {gameData &&
            gameData.questionData &&
            gameData.questionData.length &&
            gameData.questionData.map((item, index) => {
              return (
                <div
                  className="modalQuestionDiv"
                  key={index}
                  style={{
                    marginBottom: "20px",
                    padding: "8px",
                    background: "#efefef",
                  }}
                >
                  <div className="form-group mt-3">
                    <label>Question#{index + 1}</label>
                    <input
                      className="form-control"
                      name="name"
                      value={gameData.questionData[index].name}
                      onChange={(e) => {
                        updateQuizQuestion(index, e);
                      }}
                    />
                    <br />
                    Options
                    <br />
                    {item.options.map((opt, ind) => {
                      return (
                        <div
                          style={{
                            border: "1px solid #ccc",
                            padding: "10px",
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: "10px",
                          }}
                        >
                          <label>Option {ind + 1}</label>
                          <input
                            className="form-control"
                            name={`options`}
                            value={opt.options}
                            onChange={(e) => {
                              updateQuizQuestion(index, e, 1, ind);
                            }}
                          />
                          <label>Cash</label>
                          <input
                            className="form-control"
                            name={`cash`}
                            value={opt.cash}
                            onChange={(e) => {
                              updateQuizQuestion(index, e, 1, ind);
                            }}
                          />
                          <label>Company Reputation</label>
                          <input
                            className="form-control"
                            name={`companyReputation`}
                            value={opt.companyReputation}
                            onChange={(e) => {
                              updateQuizQuestion(index, e, 1, ind);
                            }}
                          />
                          <label>Company Valuation</label>
                          <input
                            className="form-control"
                            name={`companyValuation`}
                            value={opt.companyValuation}
                            onChange={(e) => {
                              updateQuizQuestion(index, e, 1, ind);
                            }}
                          />
                          <label>Employee Satisfaction</label>
                          <input
                            className="form-control"
                            name={`employeesSatisfications`}
                            value={opt.employeesSatisfications}
                            onChange={(e) => {
                              updateQuizQuestion(index, e, 1, ind);
                            }}
                          />
                          <label>No of Employee</label>
                          <input
                            className="form-control"
                            name={`noOfEmployee`}
                            value={opt.noOfEmployee}
                            onChange={(e) => {
                              updateQuizQuestion(index, e, 1, ind);
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          <div
            className="newEntryBottom-wrapper"
            style={{
              position: "fixed",
              width: "100%",
              padding: "15px",
              background: "#fff",
              bottom: 0,
            }}
          >
            <div className="btn-next btn-done" onClick={closeCourseHandler}>
              Done
            </div>
          </div>
        </div>
      </GlobalModal>

      {/* ADD MEDIA MODEL For Lucky draw STARTS */}
      <GlobalModal
        type="upload"
        open={addModelForLuckDrawState}
        close={closeModelHandler}
        heading="Upload CSV For Lucky Draw"
      >
        <Box sx={{ width: "100%" }}>
          <div className="image-upload">
            <div className="image-upload-inner">
              <label htmlFor="input_file">
                {" "}
                <img src={uploadIcon} alt="" />
              </label>
              <input
                onChange={(e) => uploadImage(e)}
                type="file"
                class="input-file"
                id="input_file"
              />

              <span>{luckySpinFileName}</span>
              <span>CSV Upload</span>

              <label className="change-img" htmlFor="input_file">
                Browse{" "}
              </label>
            </div>
            {errSuccMessage ? (
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>{errSuccMessage}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="newEntryBottom-wrapper"
            style={{ marginBottom: "-20px" }}
          >
            <div className="btn-next" onClick={uploadLuckySpinHandler}>
              Done
            </div>
          </div>
        </Box>
      </GlobalModal>
      {/* ENDS For Lucky draw */}

      {/* ADD MEDIA MODEL For Shop STARTS */}
      <GlobalModal
        type="upload"
        open={addModelForShopState}
        close={closeModelHandlerShop}
        heading="Upload CSV For Shop"
      >
        <Box sx={{ width: "100%" }}>
          <div className="image-upload">
            <div className="image-upload-inner">
              <label htmlFor="input_file">
                {" "}
                <img src={uploadIcon} alt="" />
              </label>
              <input
                onChange={(e) => uploadFileShop(e)}
                type="file"
                class="input-file"
                id="input_file"
              />

              <span>{choosenImageShop}</span>
              <span>CSV Upload</span>

              <label className="change-img" htmlFor="input_file">
                Browse{" "}
              </label>
            </div>
            {shopErrSuccMsg ? (
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>{shopErrSuccMsg}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="newEntryBottom-wrapper"
            style={{ marginBottom: "-20px" }}
          >
            <div className="btn-next" onClick={uploadShopHandler}>
              Done
            </div>
          </div>
        </Box>
      </GlobalModal>
      {/* ENDS For Shop */}
    </>
  );
};
export default EditGameContent;
