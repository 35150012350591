import React, { useState, useEffect } from "react";
import { Divider } from "@mui/material";
import { SetProps, TabPanel } from "../../components/Tabs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import siteadminService from "../../services/siteadminService";
import EditContent from "../../components/Platform/AddContent/EditContent";
import Lesson from "../../components/Platform/Lesson/lesson";
import Game from "../../components/Platform/Game/game";
import Sidebar from "../../components/Sidebar";
import api from "../../services/api";
import { usePlatform } from "../../context/PlatformManagement";

const PlatformManagementContentEdit = ({ match }) => {
  const { id } = match.params;
  const { lessonMgmtTabValue, setLessonMgmtValue } = usePlatform();
  // const dispatch = useDispatch()
  const [value, setValue] = useState(0);

  const [lessonContent, setLessonContent] = useState({
    status: "unlocked",
    videos: ["628c4ef377556136108614f4", "628c4ef377556136108614f5"],
    _id: "628c51ecb0138b0410c6cc49",
    classroomId: "628b580170abc81920536300",
    title: "Week 1",
    description: "/public/admin/files/docs-1653362531275pdffile.pdf",
    quiz: [
      {
        watch: 4,
        _id: "628c51ecb0138b0410c6cc4a",
        question: "What is color of apple",
        answer: "red",
        options: [
          {
            noOfChoose: 1,
            _id: "628c51ecb0138b0410c6cc4b",
            name: "red",
          },
          {
            noOfChoose: 0,
            _id: "628c51ecb0138b0410c6cc4c",
            name: "blue",
          },
          {
            noOfChoose: 0,
            _id: "628c51ecb0138b0410c6cc4d",
            name: "yello",
          },
          {
            noOfChoose: 0,
            _id: "628c51ecb0138b0410c6cc4e",
            name: "white",
          },
        ],
        noOfCorrrect: 4,
      },
      {
        watch: 4,
        _id: "628c51ecb0138b0410c6cc4f",
        question: "who is color of my dog",
        answer: "white",
        options: [
          {
            noOfChoose: 1,
            _id: "628c51ecb0138b0410c6cc50",
            name: "white",
          },
          {
            noOfChoose: 0,
            _id: "628c51ecb0138b0410c6cc51",
            name: "black",
          },
          {
            noOfChoose: 0,
            _id: "628c51ecb0138b0410c6cc52",
            name: "blue",
          },
          {
            noOfChoose: 0,
            _id: "628c51ecb0138b0410c6cc53",
            name: "yello",
          },
        ],
        noOfCorrrect: 2,
      },
    ],
    __v: 0,
    schoolId: "627e0a9b53fe5f413c8cb630",
  });

  const getLessonContent = async () => {
    try {
      const res = await api.get(`api/v1/siteadmin/content/${id}`);
      if (res && res.ok) {
        setLessonContent(res.data[0]);
      }
    } catch (error) {
      console.error(error, "error");
    }
  };
  useEffect(() => {
    getLessonContent();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="heading-main">
          Platform Management
          <Divider sx={{ width: "95%", borderBottomWidth: 2, mt: 1 }} />
        </div>

        <div className="container-page">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }} className='editContentTabWrapper'>
              <Tabs
                sx={{
                  "& .MuiButtonBase-root": {
                    backgroundColor: "#DADADA ",
                    width: 1007,
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#EF5B2C",
                    color: "white !important",
                    borderRadius: 1,
                  },

                  "& .MuiTabs-indicator": {
                    borderBottomColor: "#EF5B2C !important",
                  },
                }}
                value={value}
                onChange={handleChange}
              >
                <Tab label="Lesson Management" {...SetProps(0)} />
                <Tab label="Game Management" {...SetProps(1)} />
                <Tab label="Database" {...SetProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <EditContent
                val={value}
                setVal={setValue}
                content={lessonContent}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Lesson content={lessonContent} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Game content={lessonContent} />
            </TabPanel>
          </Box>
        </div>
      </div>
    </>
  );
};
export default PlatformManagementContentEdit;
