export const START_API_CALL = 'START_API_CALL'
export const END_API_CALL = 'END_API_CALL'
export const CANCEL_API_CALL = 'CANCEL_API_CALL'

const initialState = {
  apiStatus: 0,
}
export default function loadingReducer(state = initialState, action) {
  switch (action.type) {
    case START_API_CALL:
      return {
        apiStatus: state.apiStatus + 1,
      }
    case END_API_CALL:
      return {
        apiStatus: state.apiStatus > 0 ? state.apiStatus - 1 : 0,
      }
    case CANCEL_API_CALL:
      return {
        apiStatus: 0,
      }      
    default:
      return state
  }
}
