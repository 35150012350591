import React from "react";
import penciledit from "../../../assets/pencil-edit.svg";
import plus from "../../../assets/plus.svg";
import { useHistory } from "react-router-dom";
import api from "../../../services/api";
import removeIcon from "../../../assets/removeIcon.svg";
import { useParams } from "react-router-dom";

export default function Tab1Lesson({ noOfWeek, content, refresh, setRefresh }) {
  const history = useHistory();
  const { language } = useParams();

  const addContentPageHandler = (weekNo) => {
    history.push(
      `/platformManagement/addContent/${language}/${noOfWeek}/${weekNo}`
    );
  };

  const editContentPageRedirectHandler = (id) => {
    history.push(`/platformManagement/editContent/${id}`);
  };

  const checkCondition = [];

  const removeContentHandler = async (e, id) => {
    e.stopPropagation();
    const res = await api.delete(
      `/api/v1/teacher/classroomContentBySiteAdmin/remove/${id}`
    );
    if (res && res.ok) {
      setRefresh(!refresh);
    }
  };

  return (
    <div className="lessondiv">
      {Array.from({ length: noOfWeek }, (value, key) => {
        return (
          <>
            {content && content.length
              ? content.map((item, index) => (
                  <>
                    {key + 1 === item.week ? (
                      <div
                        key={index}
                        className="lessionContentAddCardMain"
                        onClick={() => editContentPageRedirectHandler(item._id)}
                      >
                        <span
                          className="weekTitle"
                          data-condition={checkCondition.push(key)}
                        >
                          Module {key + 1}
                        </span>
                        <div className="innerDivWithFooter">
                          <div className="topDiv"></div>
                          <div
                            className="removeMedia bg-danger"
                            onClick={(e) => removeContentHandler(e, item._id)}
                          >
                            <img src={removeIcon} alt="remove" />
                          </div>
                          <div className="footerDiv">
                            <span>{item.title}</span>
                            <img
                              className="icon calendarDates"
                              src={penciledit}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ))
              : ""}
            {!checkCondition.includes(key) && (
              <div
                className="lessionContentAddCardMain"
                onClick={() => addContentPageHandler(key + 1)}
              >
                <span className="weekTitle">Module {key + 1}</span>
                <div className="innerDiv">
                  <div className="topDiv">
                    <img src={plus} alt="plus" />
                    <span className="addContentText">Add lesson content</span>
                  </div>
                </div>
              </div>
            )}
          </>
        );
      })}
    </div>
  );
}
