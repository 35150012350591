import axios from "axios";
import config from "../config";
import { toast } from "react-toastify";
import { beginApiCall, endApiCall } from "../store/actions/siteadminActions";
import store from "../store";
const api = axios.create({
  baseURL: config.app.apiUrl,
});
api.defaults.headers.post["Content-Type"] = "application/json";
// eslint-disable-next-line no-shadow
api.interceptors.request.use(
  (config) => {
    store.dispatch(beginApiCall());
    // siteadminService.beginApiCallSiteadmin()
    const accessToken = localStorage.getItem("accessToken");

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    store.dispatch(endApiCall());
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  // eslint-disable-next-line consistent-return
  async (response) => {
    store.dispatch(endApiCall());

    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      if (data && data.user && data.user.role) {
        if (!["siteadmin"].includes(data.user.role)) {
          localStorage.removeItem("accessToken");
          return Promise.reject(
            new Error({
              status: "error",
              message: "User doesn't have enough permission",
            })
          );
        }
      }
      return Promise.resolve(response.data);
    }
  },
  async (error) => {
    store.dispatch(endApiCall());

    console.error("error is this", error);
    // response.status === 403 ||
    if (error && error.message === "Network Error") {
      window.location.href = "/500";
    }
    const { response, request } = error;
    if (response) {
      if (response.status === 401) {
        localStorage.removeItem("accessToken");
        //window.location.href = "/";
      }
      if (response.status === 403) {
        toast.error(response?.data?.message);
      }
      if (response.status >= 400 && response.status < 500) {
        return Promise.reject(response.data);
      }
    } else if (request) {
      return null;
    }
    return Promise.reject(error);
  }
);

export default api;
