import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uploadIcon from "../../../assets/uploadIcon.svg";
import { GlobalModal } from "../../Modal";
import warning from "../../../assets/warning.svg";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../Tabs";
import { SetProps } from "../../Tabs";
import uploadIconSm from "../../../assets/uploadIconSm.svg";
import videoUpload from "../../../assets/videoUpload.svg";
import fileIcon from "../../../assets/fileIcon.svg";
import tick1 from "../../../assets/tick1.svg";
import produce from "immer";
import api from "../../../services/api";
import { useHistory } from "react-router-dom";
import axios from "axios";
import store from "../../../store";
import {
  beginApiCall,
  endApiCall,
  cancelApiCall,
} from "../../../store/actions/siteadminActions";
import { consoleLog, getOnlyFolderStructure } from "../../../utils/helper";
import MuiAlert from "@mui/material/Alert";
import config from "../../../config";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const EditContentByWeek = ({ setVal, val, noOfWeek, content }) => {

  const dispatch = useDispatch();

  const history = useHistory();

  const [contents, setContents] = useState(content);
  // ADD NEW MODEL STATE STARTS
  const [addModelState, setAddModelState] = useState(false);

  const [courseModal, setCourseModal] = useState(false);

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (noOfWeek === 4) {
      setVal(0);
    }
    if (noOfWeek === 8) {
      setVal(1);
    }
    if (noOfWeek === 12) {
      setVal(2);
    }
  }, [noOfWeek, setVal]);

  const [pdfUrl, setPdfUrl] = useState(contents.description);
  const [pdfTitle, setPdfTitle] = useState(contents?.pdfTitle);

  const [video1, setVideo1] = useState(contents.videos[0]);
  const [video2, setVideo2] = useState(contents.videos[1]);

  const [video1Id, setVideo1Id] = useState(contents?.videos[0]?._id);
  const [video2Id, setVideo2Id] = useState(contents?.videos[1]?._id);

  const [video1Title, setVideo1Title] = useState(contents?.videos[0]?.videoTitle);
  const [video2Title, setVideo2Title] = useState(contents?.videos[1]?.videoTitle);

  const [video1Url, setVideo1Url] = useState(contents?.videos[0]?.url);
  const [video2Url, setVideo2Url] = useState(contents?.videos[1]?.url);

  const [subtitle1, setSubtitle1] = useState([{ language: "", url: "" }]);
  const [subtitle2, setSubtitle2] = useState([{ language: "", url: "" }]);

  const [quiz, setQuiz] = useState(contents?.quiz);
  const [quizId, setQuizId] = useState(contents?.quiz?._id);

  const [choosenImage, setChooseImage] = useState("");
  const [choosenImageFile, setChoosenImageFile] = useState();

  const [choosenVideo1, setChooseVideo1] = useState("");
  const [choosenVideoFile1, setChoosenVideoFile1] = useState();
  const [choosenSub1, setChooseSub1] = useState("");
  const [choosenSubFile1, setChoosenSubFile1] = useState();

  const [choosenVideo2, setChooseVideo2] = useState("");
  const [choosenVideoFile2, setChoosenVideoFile2] = useState();
  const [choosenSub2, setChooseSub2] = useState("");
  const [choosenSubFile2, setChoosenSubFile2] = useState();

  const [choosenCSV, setChooseCSV] = useState("");
  const [choosenCsvFile, setChoosenCsvFile] = useState();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setContents(content);
  }, [content]);

  useEffect(() => {
    setPdfTitle(contents?.pdfTitle);
    setPdfUrl(contents?.description);
    setQuizId(contents?.quiz?._id);
    setQuiz(contents?.quiz);
    setVideo1(contents?.videos[0]);
    setVideo2(contents?.videos[1]);
    setVideo1Id(contents?.videos[0]._id);
    setVideo2Id(contents?.videos[1]._id);
    setVideo1Title(contents?.videos[0].videoTitle);
    setVideo2Title(contents?.videos[1].videoTitle);
    setVideo1Url(contents?.videos[0].url);
    setVideo2Url(contents?.videos[1].url);
    setSubtitle1(
      contents?.videos[0]?.subtitle &&
        contents?.videos[0]?.subtitle.length > 0 &&
        contents?.videos[0]?.subtitle[0].url
        ? contents?.videos[0]?.subtitle
        : [{ language: "", url: "" }]
    );
    setSubtitle2(
      contents?.videos[1]?.subtitle &&
        contents?.videos[1]?.subtitle.length > 0 &&
        contents?.videos[1]?.subtitle[0].url
        ? contents?.videos[1]?.subtitle
        : [{ language: "", url: "" }]
    );
    // setSubtitle2(contents?.videos[1]?.subtitle);
  }, [contents]);

 const updatePdfTitle = (pdfTitle) => {
  setPdfTitle(pdfTitle);
 }

 const updatePdfUrl = (pdfUrl) => {
  setPdfUrl(pdfUrl);
 }

 const updateVideo1 = (video1) => {
  setVideo1(video1);
  setVideo1Id(video1._id);
  setVideo1Url(video1.url);
 }

 const updateVideo2 = (video2) => {
  setVideo2(video2);
  setVideo2Id(video2._id);
  setVideo2Url(video2.url);
 }

 const updateQuiz = (quiz) => {
  setQuiz(quiz);
  setQuizId(quiz._id)
 }

 const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const openModelHandler = () => {
    setAddModelState(true);
  };

  const closeModelHandler = () => {
    setAddModelState(false);
  };

  const openCourseHandler = () => {
    setCourseModal(true);
  };

  const closeCourseHandler = () => {
    setCourseModal(false);
  };

  const uploadPdf = async (e) => {
    setLoading(true);
    setChooseImage(e.target.files[0].name);
    const file = e.target.files[0];
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/upload/video/preSignedUrl`,
        {
          fileName: `uploads/admin/files/${e.target.files[0].name}`,
        }
      );
      if (res.ok && res.urls) {
        store.dispatch(beginApiCall());
        await axios.put(res.urls, file);
        store.dispatch(endApiCall());
        const onlyFolderLink = getOnlyFolderStructure(res.urls.split("?")[0]);
        updatePdfUrl(onlyFolderLink);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      alert("error uploading PDF");
    }
  };

  const uploadVideo1 = async (e) => {
    setLoading(true);
    setChooseVideo1(e.target.files[0].name);
    setChoosenVideoFile1(e.target.files[0]);
    const file = e.target.files[0];

    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/upload/video/preSignedUrl`,
        {
          fileName: `uploads/admin/videos/${e.target.files[0].name}`,
          contentType: "video/mp4",
        }
      );

      if (res.ok && res.urls) {
        store.dispatch(beginApiCall());
        await axios.put(res.urls, file);
        store.dispatch(endApiCall());
        const onlyFolderLink = getOnlyFolderStructure(res.urls.split("?")[0]);
        const uploadToDb = await api.post(
          `/api/v1/teacher/classroomContents/upload/video`,
          { videoUrl: onlyFolderLink }
        );
        if (uploadToDb && uploadToDb.ok) {
          updateVideo1(uploadToDb.urls);
          setLoading(false);
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      alert("erorr uploading video 1");
    }
  };

  const uploadVideo2 = async (e) => {
    setLoading(true);

    setChooseVideo2(e.target.files[0].name);
    setChoosenVideoFile2(e.target.files[0]);
    const file = e.target.files[0];
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/upload/video/preSignedUrl`,
        {
          fileName: `uploads/admin/videos/${e.target.files[0].name}`,
        }
      );

      if (res.ok && res.urls) {
        store.dispatch(beginApiCall());
        await axios.put(res.urls, file);
        store.dispatch(endApiCall());
        const onlyFolderLink = getOnlyFolderStructure(res.urls.split("?")[0]);
        const uploadToDb = await api.post(
          `/api/v1/teacher/classroomContents/upload/video`,
          { videoUrl: onlyFolderLink }
        );
        if (uploadToDb && uploadToDb.ok) {
          updateVideo2(uploadToDb.urls);
          setLoading(false);
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      alert("erorr uploading video 2");
    }
  };

  const videoTitle1ChangeHandler = (videoTitle) => {
    setVideo1Title(videoTitle);
  };

  const videoTitle2ChangeHandler = (videoTitle) => {
    setVideo2Title(videoTitle);
  };

  const addNewSubtitle1Handler = () => {
    const oldData = [];
    for (var i = 0; i < subtitle1.length; i++) {
      oldData.push(subtitle1[i]);
    }
    oldData.push({ language: "", url: "" });
    setSubtitle1(oldData);
  };

  const changeSubtitle1LanguageHandler = (e, index) => {
    const { value } = e.target;
    const oldData = [];
    for (var i = 0; i < subtitle1.length; i++) {
      oldData.push(subtitle1[i]);
    }
    oldData[index].language = value;
    setSubtitle1(oldData);
  };

  const uploadSub1 = async (e, index) => {
    setLoading(true);
    setChooseSub1(e.target.files[0].name);
    setChoosenSubFile1(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("subtiles", file);
    //setUploadingPdf(true);
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/uploadSingle/subtitle`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        console.error(res.url);
        // setSubtitle1(res.url)
        const oldData = [];
        for (var i = 0; i < subtitle1.length; i++) {
          oldData.push(subtitle1[i]);
        }
        oldData[index].url = res.url;
        setSubtitle1(oldData);
        setLoading(false);
        //setUploadingCharaImage(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);

      //setUploadingCharaImage(false);
    }
  };

  const changeSubtitle2LanguageHandler = (e, index) => {
    const { name, value } = e.target;
    // setSubtitle2({ language: value });
    const oldData = [];
    for (var i = 0; i < subtitle2.length; i++) {
      oldData.push(subtitle2[i]);
    }
    oldData[index].language = value;
    setSubtitle2(oldData);
  };

  const addNewSubtitle2Handler = () => {
    // video2.subtitle.push({ language: "", url: "" });
    const oldData = [];
    for (var i = 0; i < subtitle2.length; i++) {
      oldData.push(subtitle2[i]);
    }
    oldData.push({ language: "", url: "" });
    setSubtitle2(oldData);
  };

  const uploadSub2 = async (e, index) => {
    setLoading(true);
    //setChooseSub2(e.target.files[0].name);
    //setChoosenSubFile2(e.target.files[0]);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("subtiles", file);
    //setUploadingPdf(true);
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/uploadSingle/subtitle`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        // setSubtitle2(res.urls[0])
        const oldData = [];
        for (var i = 0; i < subtitle2.length; i++) {
          oldData.push(subtitle2[i]);
        }
        oldData[index].url = res.url;
        setSubtitle2(oldData);
        setLoading(false);
        //setUploadingCharaImage(false);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);

      //setUploadingCharaImage(false);
    }
  };

  const [configName, setConfigName] = useState("");

  const loadQuizFromJoyStick = async (event)  => {
    setLoading(true);
    event.preventDefault();

    try {
      store.dispatch(beginApiCall());
      var data = {
        u: "",
        p: {},
      };
      var configData = {
        method: "post",
        url: `${config.app.joystickApiBaseUrl}/${configName}/dynamic`,
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": `${config.app.joystickAPIKey}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        },
        data: data,
      };

      const {data: response} = await axios(configData);
      await uploadQuizCsv(response.data.questions);
    } catch (error) {
      console.error(error);
      alert("Invalid Quiz Code");
    } finally {
      setLoading(false);
      store.dispatch(cancelApiCall());
    }
  };

  const uploadQuizCsv = async (data) => {
    try {
      const res = await api.patch(
        `/api/v1/teacher/upload/defaultContentQuizExcel`,
        {
          quizQuestions: data,
          classroomContentId: contents._id,
        }
      );
      if (res && res.ok) {
        setLoading(false);
        alert(res.message);
      }
    } catch (err) {
      console.error(err);
      alert("Failed to save Quiz Code");
    }
  };

  const updateLesson = async () => {
    setLoading(true);
    // video1.subtitle = subtitle1;
    // video2.subtitle = subtitle2;
    // console.log({
    //   pdfTitle: pdfTitle,
    //   description: pdfUrl,
    //   videos: [video1, video2],
    //   subtitle: [subtitle1, subtitle2],
    //   // thumbnail: ["", ""],
    //   quiz: quiz ? quiz : quizId,
    //   // week: parseInt(noOfWeek),
    // });
    try {
      const newContent = {
        pdfTitle: pdfTitle,
        description: pdfUrl,
        videos: [
          { _id: video1Id, videoTitle: video1Title },
          { _id: video2Id, videoTitle: video2Title },
        ],
        subtitles: [subtitle1, subtitle2],
        thumbnail: ["", ""],
        quiz: contents.quiz,
      }
      const res = await api.patch(
        `api/v1/teacher/classroomContents/update/${contents._id}`,
        newContent,
      );
      if (res && res.ok) {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          history.goBack();
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccess(false);
    }
    const data = {
      pacakageType: contents.pacakageType,
      week: contents.week,
      title: contents.title,
      description: contents.description,
      videos: [
        {
          videoTitle: contents.title + "1",
          videoDesc: contents.description,
          _id: video1,
        },
        {
          videoTitle: contents.title + "2",
          videoDesc: contents.description,
          _id: video2,
        },
      ],
      pdfUpload: pdfUrl,
      subtitles: [subtitle1, subtitle2],
      thumbnail: ["", ""],
      quiz: quizId,
    };
  };

  const updateQuizQuestion = (index, value) => {
    const nextState = produce(contents, (drafts) => {
      drafts.quiz[index].question = value;
    });
    setContents(nextState);
  };
  const updateQuizAnswer = (index, value) => {
    const nextState = produce(contents, (drafts) => {
      drafts.quiz[index].answer = value;
    });
    setContents(nextState);
  };
  const updateQuizOption = (index, optIndex, value) => {
    const nextState = produce(contents, (drafts) => {
      drafts.quiz[index].options[optIndex].name = value;
    });
    setContents(nextState);
  };

  const exportQuizCSVHandler = async () => {
    try {
      const resData = await api.get(
        `/api/v1/siteadmin/quiz/csv/${contents._id}`
      );
      startDownloadQuiz(resData.location, "quiz.xlsx");
    } catch (err) {
      consoleLog(err);
    }
  };

  async function startDownloadQuiz(file, fileName) {
    const link = document.createElement("a");
    link.href = `${config.app.apiUrl}/api/public/admin/quizDownloaded/${file}`;
    link.setAttribute("download", file);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  return (
    <>
      <div className="editContentMainDiv">
        <div className="topBar">
          <span>Lesson mangement/ {contents?.title}</span>
          <div className="btnDiv">
            <button onClick={openModelHandler}>
              <img src={uploadIcon} alt="upoadIcon" />
              CSV Upload For Lesson
            </button>
          </div>
        </div>

        <div className="fileDiv">
          {/* VIDEO DIV CONTAINER STARTS */}
          <div className="courseVideoDivMain">
            <div className="top">
              <span className="title">Course Video</span>
              <span
                className="acionBtn"
                onClick={() => {
                  openModelHandler();
                  setValue(1);
                }}
              >
                Edit
              </span>
            </div>
            <div className="videoContainer">
              <div className="videoDiv">
                <img src={videoUpload} alt="Upload Video" />
                <span>{video1Url || "video1.mp4"}</span>
              </div>
              <div className="videoDiv">
                <img src={videoUpload} alt="Upload Video" />
                <span>{video2Url || "video2.mp4"}</span>
              </div>
            </div>
          </div>
          {/* VIDEO DIV CONTAINER ENDS */}

          {/* COURSE PDF DIV CONTAINER STARTS */}
          <div className="courseVideoDivMain">
            <div className="top">
              <span className="title">Course PDF</span>
              <span
                className="acionBtn"
                onClick={() => {
                  openModelHandler();
                  setValue(0);
                }}
              >
                Edit
              </span>
            </div>
            <div className="pdfContainer">
              <div className="pdfDiv">
                <img src={fileIcon} alt="Upload Video" />
                <div className="fileName">{pdfUrl}</div>
              </div>
            </div>
          </div>
          {/* COURSE PDF CONTAINER ENDS */}

          {/* COURSE PDF DIV CONTAINER STARTS */}
          <div className="courseVideoDivMain">
            <div className="top">
              <span className="title">CSV Quiz</span>
              <span
                className="acionBtn"
                onClick={() => {
                  openModelHandler();
                  setValue(2);
                }}
              >
                Edit
              </span>
            </div>
            <div className="pdfContainer">
              <div className="pdfDiv">
                <img src={fileIcon} alt="Upload Video" />
                <div className="fileName">{"quiz CSV"}</div>
              </div>
            </div>
          </div>
          {/* COURSE PDF CONTAINER ENDS */}
        </div>

        {/* Course quiz div starts */}
        <div className="quizDivMain">
          <div className="top">
            <span className="title">Course Questions</span>
            <span className="acionBtn" onClick={openCourseHandler}>
              Edit
            </span>
          </div>
          <div className="questionContentDiv">
            {contents && contents?.quiz && contents?.quiz.length
              ? contents.quiz.map((item, index) => (
                  <div className="questionDiv" key={index}>
                    <div className="topdiv">
                      <span>Question#{index + 1}</span>
                      <img src={tick1} alt="icon" />
                    </div>
                    <div className="innerDiv">
                      <div className="question">
                        {item.question} (Answer: {item.answer})
                      </div>
                      <div className="options">
                        {item.options.map((opt, ind) => {
                          return <div key={ind}>{opt.name}</div>;
                        })}
                      </div>
                    </div>
                  </div>
                ))
              : null}
            {/* QUESTIONS ENDS */}
          </div>
        </div>
        {success ? (
          <div class="alert alert-success" role="alert">
            data updated successfully
          </div>
        ) : (
          ""
        )}
        <div className="footerBar">
          <button className="create" onClick={updateLesson}>
            Update
          </button>
          <button
            className="cancel"
            onClick={() => {
              history.goBack();
            }}
          >
            Cancel
          </button>
        </div>
        {/* Course quiz div ends */}

        {/* Lucky Draw Questions div starts */}
        {/* Lucky Draw Questions div ends */}
      </div>
      {/* ADD MEDIA MODEL STARTS */}
      <GlobalModal
        type="upload"
        open={addModelState}
        close={closeModelHandler}
        heading="CSV Upload"
      >
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <div className="tabs-section tabs-section-upload">
              <Tabs
                sx={{
                  width: "80%",
                  marginBottom: "33px",
                  textTransform: "lowercase",
                }}
                value={value}
                indicatorColor="green"
                onChange={handleChange}
              >
                <Tab label="PDF" {...SetProps(0)} />
                <Tab label="VIDEOS" {...SetProps(1)} />
                <Tab label="QUIZ QUESTIONS" {...SetProps(2)} />
              </Tabs>
            </div>
          </Box>
          <TabPanel value={value} index={0} sx={{ position: "relative" }}>
            <div className="image-upload">
              <input
                className="title-content"
                placeholder="Enter Title..."
                type="text"
                value={pdfTitle}
                onChange={(e) => updatePdfTitle(e.target.value)}
              />
              <div className="image-upload-inner">
                <label htmlFor="input_file">
                  {" "}
                  <img src={uploadIcon} alt="" />
                </label>
                <input
                  onChange={(e) => uploadPdf(e)}
                  type="file"
                  accept=".pdf"
                  class="input-file"
                  id="input_file"
                />

                <span>{pdfUrl}</span>
                <span>PDF Upload</span>

                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label>
              </div>
            </div>
            <div
              className="newEntryBottom-wrapper"
              style={{ marginBottom: "-20px" }}
            >
              <div className="btn-next" onClick={() => setValue(1)}>
                Next
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1} sx={{ position: "relative" }}>
            <div className="image-upload image-upload-video">
              <div className="image-upload-video-1">
                <input
                  className="title-content video-title"
                  placeholder="Enter video 1 Title..."
                  type="text"
                  value={video1Title}
                  onChange={(e) => videoTitle1ChangeHandler(e.target.value)}
                />
                <div className="image-upload-inner">
                  <label htmlFor="input_file">
                    {" "}
                    <img src={videoUpload} alt="Upload Video" />
                  </label>
                  <input
                    onChange={(e) => uploadVideo1(e)}
                    type="file"
                    accept=".mp4"
                    class="input-file"
                    id="input_file"
                  />
                  <span>{video1Url}</span>
                  <label className="change-img" htmlFor="input_file">
                    Change{" "}
                  </label>
                </div>
                {/* <div className='error-upload' style={{ marginTop: '-20px' }}>
                  <div className='subtitle'>
                    <label for='subtitle-0'>
                      <img src={uploadIconSm} />
                      <span>subtitle</span>
                    </label>
                    <input
                      id='subtitle-0'
                      type='file'
                      accept='.vtt,.srt'
                      onChange={(e) => {
                        uploadSub1(e)
                      }}
                    />
                  </div>
                </div> */}
                {subtitle1 && subtitle1.length > 0
                  ? subtitle1.map((data, index) => {
                      return (
                        <div
                          className="error-upload"
                          style={{
                            // marginTop: "-20px",
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            alignItems: "flex-start",
                          }}
                          key={index}
                        >
                          <label>Language</label>
                          <input
                            type="text"
                            // name={`language-${index}`}
                            name={`subtitle[${index}].language`}
                            value={subtitle1[index].language}
                            onChange={(e) =>
                              changeSubtitle1LanguageHandler(e, index)
                            }
                          />
                          <div className="subtitle">
                            <label for={`subtitle-${index}`}>
                              <img src={uploadIconSm} />
                              <span>
                                {data[index]?.url
                                  ? "Subtitle selected"
                                  : "subtitle"}
                              </span>
                            </label>
                            <input
                              onChange={(e) => uploadSub1(e, index)}
                              // id="`${"subtitle-0}
                              id={`subtitle-${index}`}
                              accept=".vtt,.srt"
                              type="file"
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <span
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={addNewSubtitle1Handler}
                >
                  +Add new subtitle
                </span>
              </div>
              <div className="image-upload-video-1">
                <input
                  className="title-content video-title"
                  placeholder="Enter video 2 Title..."
                  type="text"
                  value={video2Title}
                  onChange={(e) => videoTitle2ChangeHandler(e.target.value)}
                />
                <div className="image-upload-inner">
                  <label htmlFor="video2">
                    {" "}
                    <img src={videoUpload} alt="" />
                  </label>
                  <input
                    onChange={(e) => uploadVideo2(e)}
                    type="file"
                    accept=".mp4"
                    class="input-file"
                    id="video2"
                  />

                  <span>{video2Url}</span>
                  <label className="change-img" htmlFor="video2">
                    Change{" "}
                  </label>
                </div>
                {/* <div className='error-upload' style={{ marginTop: '-20px' }}>
                  <div className='subtitle'>
                    <label for='uploadSubtitle'>
                      <img src={uploadIconSm} />
                      <span>subtitle</span>
                    </label>
                    <input
                      type='file'
                      id='uploadSubtitle'
                      accept='.vtt,.srt'
                      onChange={(e) => {
                        uploadSub2(e)
                      }}
                    />
                  </div>
                </div> */}
                {subtitle2 && subtitle2.length > 0
                  ? subtitle2.map((data, index) => {
                      return (
                        <div
                          className="error-upload"
                          style={{
                            // marginTop: "-20px",
                            display: "flex",
                            flexDirection: "column",
                            padding: "20px",
                            alignItems: "flex-start",
                          }}
                          key={index}
                        >
                          <label>Language</label>
                          <input
                            type="text"
                            name={`subtitle[${index}].language`}
                            value={subtitle2[index].language}
                            onChange={(e) =>
                              changeSubtitle2LanguageHandler(e, index)
                            }
                          />
                          <div className="subtitle">
                            <label htmlFor={`uploadSubtitle-${index}`}>
                              <img src={uploadIconSm} />
                              <span>
                                {data[index]?.url
                                  ? "Subtitle selected"
                                  : "subtitle"}
                              </span>
                            </label>
                            <input
                              onChange={(e) => uploadSub2(e, index)}
                              type="file"
                              accept=".vtt,.srt"
                              // id="uploadSubtitle"
                              id={`uploadSubtitle-${index}`}
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <span
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={addNewSubtitle2Handler}
                >
                  +Add new subtitle
                </span>
              </div>
            </div>
            <div
              className="newEntryBottom-wrapper"
              style={{
                position: "relative",
                bottom: 0,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 1,
                padding: "10px",
              }}
            >
              <div
                className="btn-next"
                style={{ background: "#9D9D9D", marginBottom: "-20px" }}
                onClick={() => setValue(2)}
              >
                Next
              </div>
            </div>
          </TabPanel>{" "}
          <TabPanel value={value} index={2} sx={{ position: "relative" }}>
            <div className="image-upload">
              {/* <Alert severity="warning">
                Please export the latest data and give a flag such as
                edit,remove,add in the updated column if the questions are
                edited, removed or added
              </Alert> */}
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <button
                  className="btn-next"
                  style={{
                    background: "#ed6c02",
                    color: "white",
                    width: "150px",
                    border: "none",
                    outline: "0ch",
                    padding: "5px",
                    borderRadius: "5px",
                    margin: "5px",
                  }}
                  onClick={exportQuizCSVHandler}
                >
                  Export CSV
                </button>
              </div> */}
              <div className="image-upload-inner">
                <input
                  type="text"
                  name="configName"
                  placeholder="Enter config file name"
                  value={configName}
                  onChange={(e) => setConfigName(e.target.value)}
                />
                <button className="school-btn" onClick={(e) => loadQuizFromJoyStick(e)}>
                  Load Data
                </button>
                {/* <label htmlFor="input_file">
                  {" "}
                  <img src={uploadIcon} alt="" />
                </label>
                <input
                  onChange={(e) => uploadQuizCsv(e)}
                  type="file"
                  accept=".xls,.xlsx"
                  class="input-file"
                  id="input_file"
                />

                <span>{choosenCSV}</span>
                <span>Excel Upload</span>

                <label className="change-img" htmlFor="input_file">
                  Browse{" "}
                </label> */}
              </div>
            </div>
            <div
              className="newEntryBottom-wrapper"
              style={{ marginBottom: "-20px" }}
            >
              <div className="btn-next btn-done" onClick={closeModelHandler}>
                Done
              </div>
            </div>
          </TabPanel>
        </Box>
      </GlobalModal>
      {/* ENDS */}

      <GlobalModal
        type="upload"
        open={courseModal}
        close={closeCourseHandler}
        heading="Course Question"
      >
        <div
          className="course-question-modal-wrapper"
          style={{ paddingBottom: "30px" }}
        >
          {contents && contents.quiz && contents.quiz.length
            ? contents.quiz.map((item, index) => {
                return (
                  <div className="modalQuestionDiv" key={index}>
                    <div className="form-group mt-3">
                      <label>Question#{index + 1}</label>
                      <input
                        className="form-control"
                        value={contents.quiz[index].question}
                        onChange={(e) => {
                          updateQuizQuestion(index, e.target.value);
                        }}
                      />
                      <label className="mt-2">Answer</label>
                      <input
                        className="form-control"
                        value={contents.quiz[index].answer}
                        onChange={(e) => {
                          updateQuizAnswer(index, e.target.value);
                        }}
                      />
                      <label className="mt-2">Options</label>
                      {contents.quiz[index].options &&
                      contents.quiz[index].options.length
                        ? contents.quiz[index].options.map((opt, optIndex) => {
                            return (
                              <input
                                className="form-control mt-2 d-block"
                                value={opt.name}
                                onChange={(e) => {
                                  updateQuizOption(
                                    index,
                                    optIndex,
                                    e.target.value
                                  );
                                }}
                              />
                            );
                          })
                        : ""}
                    </div>
                    <hr className="my-4" />
                  </div>
                );
              })
            : ""}
          <div
            className="newEntryBottom-wrapper"
            style={{
              padding: "10px",
              backgroundColor: "white",
              position: "fixed",
              bottom: 0,
            }}
          >
            <div className="btn-next btn-done" onClick={closeCourseHandler}>
              Done
            </div>
          </div>
        </div>
      </GlobalModal>
    </>
  );
};
export default EditContentByWeek;
