import React, { useEffect, useState } from "react";
import plus from "../../../assets/plus.svg";
import removeIcon from "../../../assets/removeIcon.svg";
import api from "../../../services/api";
import penciledit from "../../../assets/pencil-edit.svg";
import { consoleError, consoleLog } from "../../../utils/helper";
import uploadIcon from "../../../assets/uploadIcon.svg";
import { GlobalModal } from "../../Modal";
import warning from "../../../assets/warning.svg";
import Box from "@mui/material/Box";
import config from "../../../config";
import MuiAlert from "@mui/material/Alert";
import { useParams } from "react-router-dom";
import axios from "axios";
import store from "../../../store";
import {
  beginApiCall,
  endApiCall,
  cancelApiCall,
} from "../../../store/actions/siteadminActions";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GlobalGameMgmt = () => {
  const [shopData, setShopData] = useState([]);
  const [luckySpinData, setLuckySpinData] = useState([]);
  const [decisionTree, setDecisionTree] = useState([]);
  const { language } = useParams();

  useEffect(() => {
    fetchGlobalShop();
    fetchLuckySpin();
    fetchGlobalDecisionTree();
  }, []);

  const fetchGlobalDecisionTree = async () => {
    try {
      const res = await api.get(
        `/api/v1/siteadmin/default/decision-tree/${language}`
      );
      if (res && res.data) {
        setDecisionTree(res.data);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  const fetchGlobalShop = async () => {
    try {
      const res = await api.get(`/api/v1/siteadmin/all/shop-item/${language}`);
      if (res && res.data) {
        setShopData(res.data);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  const fetchLuckySpin = async () => {
    try {
      const res = await api.get(
        `/api/v1/siteadmin/all/luckyspinQuestions/${language}`
      );
      if (res && res.data) {
        setLuckySpinData(res.data);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  //   State for shop
  const [addModelForShopState, setAddModelShopState] = useState(false);
  const [shopUploadAction, setShopUploadAction] = useState("");
  const openModelForCSVShopHandler = (action) => {
    setShopUploadAction(action);
    setAddModelShopState(true);
  };

  const closeModelHandlerShop = () => {
    setAddModelShopState(false);
  };

  const [choosenImageShop, setChooseImageShop] = useState("");
  const [shopFile, setShopFile] = useState("");
  const [shopErrSuccMsg, setShopErrSuccMsg] = useState("");
  const [configName, setConfigName] = useState("");
  const uploadFileShop = async (e) => {
    setChooseImageShop(e.target.files[0].name);
    const file = e.target.files[0];
    setShopFile(file);
  };

  const loadShopItemFromJoyStick = async (event) => {
    try {
      store.dispatch(beginApiCall());
      var data = {
        u: "",
        p: {},
      };
      var configData = {
        method: "post",
        url: `${config.app.joystickApiBaseUrl}/${configName}/dynamic`,
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": `${config.app.joystickAPIKey}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        },
        data: data,
      };

      await axios(configData)
        .then(function (response) {
          store.dispatch(endApiCall());
          uploadShopHandler(response.data.data.shopItems);
        })
        .catch(function (error) {
          console.error(error);
          alert("Invalid Shop Items Code");
        });
      } finally {
        store.dispatch(cancelApiCall());
      }
  };

  const uploadShopHandler = async (data) => {
    try {
      const res = await api.post("/api/v1/siteadmin/upload/shopItem", {
        shopitems: data,
        language: language,
      });
      if (res && res.ok) {
        setShopErrSuccMsg(res.message);
        closeModelHandlerShop();
        fetchGlobalShop();
        setConfigName("");
      }
    } catch (err) {
      setShopErrSuccMsg(err.message);
    }
  };

  // Shop state ends

  // Decision tree state
  const [addModelForDecisionTree, setAddModelForDecisionTree] = useState(false);
  const [choosenImageDecision, setChoosenImageDecision] = useState("");
  const [decisonTreeFile, setDecisionTreeFile] = useState("");
  const closeModelHandlerDecsionTree = () => {
    setAddModelForDecisionTree(false);
  };

  const openModelHandlerDecsionTree = () => {
    setAddModelForDecisionTree(true);
  };

  const loadDecisionTreeFromJoyStick = async (event) => {
    try {
      store.dispatch(beginApiCall());
      var data = {
        u: "",
        p: {},
      };
      var configData = {
        method: "post",
        url: `${config.app.joystickApiBaseUrl}/${configName}/dynamic`,
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": `${config.app.joystickAPIKey}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        },
        data: data,
      };

      await axios(configData)
        .then(function (response) {
          store.dispatch(endApiCall());
          uploadDecisionTreeHandler(response.data.data.questions);
        })
        .catch(function (error) {
          console.error(error);
          alert("Invalid Decision Tree Code");
        });
      } finally {
        store.dispatch(cancelApiCall());
      }
  };

  const uploadDecisionTreeHandler = async (data) => {
    try {
      const addObj = {
        questions: data,
        language: language,
      };
      const updateObj = {
        questions: data,
        language: language,
        decisionTreeId:
          decisionTree && decisionTree.length > 0 ? decisionTree[0]?._id : "",
      };
      const res = await api.post(
        "/api/v1/siteadmin/upload/default/decisionTree",
        decisionTree && decisionTree.length > 0 ? updateObj : addObj
      );
      if (res && res.ok) {
        setShopErrSuccMsg(res.message);
        closeModelHandlerDecsionTree();
        fetchGlobalDecisionTree();
        setConfigName("");
      }
    } catch (err) {
      console.error(err);
      setShopErrSuccMsg(err.message);
    }
  };
  // Decison tree state ends

  // Luckyspin state
  const [addModelForLuckDrawState, setAddModelForLuckyDrawState] =
    useState(false);

  const openModelForCSVLuckyDrawHandler = () => {
    setAddModelForLuckyDrawState(true);
  };

  const closeModelHandler = () => {
    setAddModelForLuckyDrawState(false);
  };

  const [luckySpinFileName, setLuckySpinFileName] = useState("");
  const [luckySpinFile, setLuckySpinFile] = useState("");
  const [errSuccMessage, setErrSuccMessage] = useState("");

  const uploadImage = (e) => {
    setLuckySpinFileName(e.target.files[0].name);
    setLuckySpinFile(e.target.files[0]);
  };

  const loadLuckySpinFromJoyStick = async (event) => {
    try {
      store.dispatch(beginApiCall());
      var data = {
        u: "",
        p: {},
      };
      var configData = {
        method: "post",
        url: `${config.app.joystickApiBaseUrl}/${configName}/dynamic`,
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": `${config.app.joystickAPIKey}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        },
        data: data,
      };

      await axios(configData)
        .then(function (response) {
          store.dispatch(endApiCall());
          uploadLuckySpinHandler(response.data.data.questions);
        })
        .catch(function (error) {
          console.error(error);
          alert("Invalid Lucky Spin Code");
        });
    } finally {
      store.dispatch(cancelApiCall());
    }
  };

  const uploadLuckySpinHandler = async (data) => {
    try {
      const addObj = {
        questions: data,
        language: language,
      };
      const updateObj = {
        questions: data,
        language: language,
        update: luckySpinData && luckySpinData.length > 0 ? true : false,
      };
      const res = await api.post(
        "/api/v1/siteadmin/upload/luckyspin",
        luckySpinData && luckySpinData.length > 0 ? updateObj : addObj
      );
      if (res && res.ok) {
        setErrSuccMessage(res.message);
        fetchLuckySpin();
        closeModelHandler();
        setConfigName("");
      }
    } catch (err) {
      console.error(err);
    }
    // const formData = new FormData();
    // formData.append("file", luckySpinFile);
    // formData.append("language", language);
    // try {
    //   const res = await api.post(
    //     "/api/v1/siteadmin/upload/luckyspinByCsv",
    //     formData
    //   );
    //   if (res && res.ok) {
    //     setErrSuccMessage(res.message);
    //     fetchLuckySpin();
    //     closeModelHandler();
    //   }
    // } catch (err) {
    //   setErrSuccMessage(err.message);
    // }
  };

  // Luckyspin state ends

  const exportCSVHandler = async () => {
    try {
      const resData = await api.get(`/api/v1/siteadmin/decisionTreeCsv/global`);
      startDownload(resData.location, "decisionTree.xlsx");
    } catch (err) {
      consoleLog(err);
    }
  };
  async function startDownload(file, fileName) {
    const link = document.createElement("a");
    link.href = `${config.app.apiUrl}/api/public/admin/decisionTreeDownloaded/${file}`;
    link.setAttribute("download", file);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  return (
    <div className="globalGameManagementMain">
      {decisionTree && decisionTree.length > 0 ? (
        <div className="cardMain" onClick={openModelHandlerDecsionTree}>
          <span className="weekTitle">Decision Tree Question</span>
          <div className="innerDiv">
            <img src={penciledit} alt="pen" />
            <span className="addContentText">Edit decision tree</span>
          </div>
        </div>
      ) : (
        <div className="cardMain" onClick={openModelHandlerDecsionTree}>
          <span className="weekTitle">Decision Tree Question</span>
          <div className="innerDiv">
            <img src={plus} alt="plus" />
            <span className="addContentText">Add decision tree</span>
          </div>
        </div>
      )}

      {shopData && shopData.length > 0 ? (
        <div
          className="cardMain"
          onClick={() => openModelForCSVShopHandler("edit")}
        >
          <span className="weekTitle">Shop Items</span>
          <div className="innerDiv">
            <img src={penciledit} alt="plus" />
            <span className="addContentText">Eit shop item</span>
          </div>
        </div>
      ) : (
        <div
          className="cardMain"
          onClick={() => openModelForCSVShopHandler("add")}
        >
          <span className="weekTitle">Shop Items</span>
          <div className="innerDiv">
            <img src={plus} alt="plus" />
            <span className="addContentText">Add shop item</span>
          </div>
        </div>
      )}

      {luckySpinData && luckySpinData.length > 0 ? (
        <div className="cardMain" onClick={openModelForCSVLuckyDrawHandler}>
          <span className="weekTitle">Lucky Spin</span>
          <div className="innerDiv">
            <img src={penciledit} alt="plus" />
            <span className="addContentText">Eit lucky spin</span>
          </div>
        </div>
      ) : (
        <div className="cardMain" onClick={openModelForCSVLuckyDrawHandler}>
          <span className="weekTitle">Lucky Spin</span>
          <div className="innerDiv">
            <img src={plus} alt="plus" />
            <span className="addContentText">Add lucky spin</span>
          </div>
        </div>
      )}

      {/* ADD MEDIA MODEL For Shop STARTS */}
      <GlobalModal
        type="upload"
        open={addModelForShopState}
        close={closeModelHandlerShop}
        heading="Upload CSV For Shop"
      >
        <Box sx={{ width: "100%" }}>
          <div className="image-upload">
            <div className="image-upload-inner">
              <input
                type="text"
                name="configName"
                placeholder="Enter config file name"
                value={configName}
                onChange={(e) => setConfigName(e.target.value)}
              />
              <button className="school-btn" onClick={(e) => loadShopItemFromJoyStick(e)}>
                Load Data
              </button>
            </div>
            {shopErrSuccMsg ? (
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>{shopErrSuccMsg}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="newEntryBottom-wrapper"
            style={{ marginBottom: "-20px" }}
          >
            <div className="btn-next" onClick={uploadShopHandler}>
              Done
            </div>
          </div>
        </Box>
      </GlobalModal>
      {/* ENDS For Shop */}

      {/* ADD MEDIA MODEL For Decsion tree STARTS */}
      <GlobalModal
        type="upload"
        open={addModelForDecisionTree}
        close={closeModelHandlerDecsionTree}
        heading="Upload CSV For Decision Tree"
      >
        <Box sx={{ width: "100%" }}>
          <div className="image-upload">
            <div className="image-upload-inner">
              <input
                type="text"
                name="configName"
                placeholder="Enter config file name"
                value={configName}
                onChange={(e) => setConfigName(e.target.value)}
              />
              <button
                className="school-btn"
                onClick={(e) => loadDecisionTreeFromJoyStick(e)}
              >
                Load Data
              </button>
            </div>
            {shopErrSuccMsg ? (
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>{shopErrSuccMsg}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="newEntryBottom-wrapper"
            style={{ marginBottom: "-20px" }}
          >
            <div className="btn-next" onClick={uploadDecisionTreeHandler}>
              Done
            </div>
          </div>
        </Box>
      </GlobalModal>
      {/* ENDS For Decsion tree */}

      {/* ADD MEDIA MODEL For Lucky draw STARTS */}
      <GlobalModal
        type="upload"
        open={addModelForLuckDrawState}
        close={closeModelHandler}
        heading="Upload CSV For Lucky Draw"
      >
        <Box sx={{ width: "100%" }}>
          <div className="image-upload">
            <div className="image-upload-inner">
              <input
                type="text"
                name="configName"
                placeholder="Enter config file name"
                value={configName}
                onChange={(e) => setConfigName(e.target.value)}
              />
              <button
                className="school-btn"
                onClick={(e) => loadLuckySpinFromJoyStick(e)}
              >
                Load Data
              </button>
            </div>
            {errSuccMessage ? (
              <div className="error-upload">
                <img src={warning} alt="Warning" />
                <span>{errSuccMessage}</span>
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className="newEntryBottom-wrapper"
            style={{ marginBottom: "-20px" }}
          >
            <div className="btn-next" onClick={uploadLuckySpinHandler}>
              Done
            </div>
          </div>
        </Box>
      </GlobalModal>
      {/* ENDS For Lucky draw */}
    </div>
  );
};
export default GlobalGameMgmt;
