import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
// import MoreVertIcon from '@mui/icons-material/MoreVert'
import { Iron, MoreHoriz, MoreVert, SpaOutlined } from "@mui/icons-material";
import { visuallyHidden } from "@mui/utils";
import { Card, CardActionArea, CardContent, CardMedia } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import api from "../services/api";
import { toast } from "react-toastify";
import { GlobalModal } from "./Modal";
import ConfirmButton from "./Buttons/ConfirmButton";
import CancelButton from "./Buttons/CancelButton";
import { consoleLog } from "../utils/helper";
import {
  CountryDropdown,
  RegionDropdown,
  CountryRegionData,
} from "react-country-region-selector";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding='checkbox'>
          <Checkbox
            color='primary'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all ',
            }}
          />
        </TableCell> */}
        {headCells &&
          headCells.length > 0 &&
          headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              sx={{ background: "#F5F5F5" }}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.array.isRequired,
};

export default function Tab1Banner({
  banners,
  resultData,
  headCells,
  renewBtn = false,
}) {
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("date");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(500000);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = banners.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - banners.length) : 0;

  const navigation = useHistory();
  const GotoSchoolHome = (id, name) => {
    localStorage.removeItem("query");
    localStorage.setItem("query", id);
    navigation.push({
      pathname: `/dashboard/school/${id}`,
    });
  };

  const togglePauseBar = (id) => {
    if (document.getElementById(id).style.visibility === "hidden") {
      document.getElementById(id).style.visibility = "visible";
    } else {
      document.getElementById(id).style.visibility = "hidden";
    }
  };

  const changeSchoolStatusHandler = async (id) => {
    // try {
    //   const data = await api.patch(
    //     `/api/v1/siteadmin/changeSchoolStatus/${id}`
    //   );
    //   if (data) {
    //     toast.success(data.message);
    //     window.location.reload();
    //   }
    // } catch (err) {
    //   toast.error(err.message);
    // }
    try {
      const res = await api.delete(`/api/v1/siteadmin/delete/school/${id}`);
      if (res && res.ok) {
        toast.success(res.message);
        window.location.reload();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const resumeSchoolHandler = async (id) => {
    try {
      const res = await api.patch(
        `/api/v1/siteadmin/recover/archive/school/${id}`
      );
      if (res && res.ok) {
        toast.success(res.message);
        window.location.reload();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const deleteSchoolHandler = async (id) => {
    // try {
    //   const res = await api.delete(`/api/v1/siteadmin/delete/school/${id}`);
    //   if (res && res.ok) {
    //     toast.success(res.message);
    //     window.location.reload();
    //   }
    // } catch (err) {
    //   toast.error(err.message);
    // }
    try {
      const res = await api.delete(
        `/api/v1/siteadmin/delete/schoolFromArchive/${id}`
      );
      if (res && res.ok) {
        toast.success(res.message);
        window.location.reload();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  // Add package handlers
  const [packageType, setPacakgeType] = useState("");
  const [allLanguage, setAllLanguage] = useState([]);
  const [language, setLanguage] = useState("");
  const [addPackageModelState, setAddPackageModelState] = useState(false);
  const [changeRegionModelState, setChangeRegionModelState] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState();
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");

  const setSelectedCountry = async (country) => {
    setCountry(country);
    setRegion("");
  };

  const setSelectedRegion = async (region) => {
    setRegion(region);
  };

  useEffect(() => {
    fetchAllLanguage();
  }, []);

  const fetchAllLanguage = async () => {
    try {
      const langRes = await api.get("/api/v1/siteadmin/view/all/language");
      if (langRes && langRes.ok) {
        setAllLanguage(langRes.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const addPackageModelHandler = (schoolId) => {
    setSelectedSchool(schoolId);
    setAddPackageModelState(!addPackageModelState);
  };

  const changeRegionModelHandler = (schoolId) => {
    setSelectedSchool(schoolId);
    setChangeRegionModelState(!changeRegionModelState);
  };

  const addPackageHandler = async () => {
    try {
      const res = await api.patch(
        `/api/v1/siteadmin/addPackage/${selectedSchool}`,
        { packageType: packageType, language: language }
      );
      if (res && res.ok) {
        toast.success(res.message);
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };
  // Add package handlers ends

  const changeRegionHandler = async () => {
    try {
      const res = await api.patch(
        `/api/v1/siteadmin/changeSchoolRegion/${selectedSchool}`,
        { country: country, region: region }
      );
      if (res && res.ok) {
        toast.success(res.message);
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  // sendEmailReminder
  const sendEmailReminder = async (id) => {
    try {
      const emailReminder = await api.get(
        `/api/v1/siteadmin/liscenseExpiredNotification/${id}`
      );
      if (emailReminder && emailReminder.ok) {
        toast.success(emailReminder?.message);
      }
    } catch (err) {
      consoleLog(err);
      toast.error("Failed while sending email notification");
    }
  };
  // end

  // renewLiscenseModelHandler
  const [renewSchoolModelState, setRenewSchoolModelState] = useState(false);
  const [renewDate, setRenewDate] = useState(new Date());

  const renewLiscenseModelHandler = (id) => {
    setSelectedSchool(id);
    setRenewSchoolModelState(!renewSchoolModelState);
  };

  const renewLiscenseHandler = async () => {
    try {
      const resData = await api.patch("/api/v1/siteadmin/renew/liscense", {
        schoolId: selectedSchool,
        renewDate: renewDate,
      });
      if (resData && resData.ok) {
        toast.success(resData.message);
        setInterval(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  // End

  return (
    <Box sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          stickyHeader
          sx={{
            "& .MuiTableCell-head": {
              fontSize: 15,
              color: "black",

              fontFamily: "Inter",
              borderTopColor: "transparent",
            },
          }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={resultData.length}
            headCells={headCells}
          />
          <TableBody
            sx={{
              // minWidth: 750,
              "& .MuiTableCell-body": {
                fontFamily: "Inter",
              },
              "& .MuiTableCell-body:hover": {
                // color: '#FE9900',
              },
            }}
          >
            {resultData.length > 0 &&
              stableSort(resultData, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={isItemSelected}
                      sx={{ background: "white" }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="left"
                      >
                        <Link to={`/dashboard/school/${row._id}`}>
                          {row.name}
                        </Link>
                      </TableCell>

                      <TableCell
                        align="left"
                        sx={{
                          textDecoration: "underline",
                          textUnderlineOffset: "2px",
                        }}
                      >
                        {row.schoolAdminPrimaryDetail?.phone}
                      </TableCell>
                      <TableCell align="left">
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: "12px",
                            lineHeight: "15px",
                          }}
                        >
                          {row.liscenseStatus ? "Activated" : "Not Activated"}
                        </span>{" "}
                        <br />
                        <span
                          style={{
                            fontWeight: 400,
                            fontSize: "10px",
                            lineHeight: "12px",
                          }}
                        >
                          {row?.liscenseExpiryDate
                            ? format(
                                new Date(row?.liscenseExpiryDate),
                                "yyyy-MM-dd"
                              )
                            : ""}
                        </span>{" "}
                      </TableCell>
                      {renewBtn ? (
                        <TableCell
                          align="left"
                          sx={{
                            textUnderlineOffset: "2px",
                          }}
                        >
                          <button
                            className="addPackageBtn"
                            onClick={() => renewLiscenseModelHandler(row._id)}
                            style={{ padding: "10px" }}
                          >
                            Renew Liscense
                          </button>
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          sx={{
                            textUnderlineOffset: "2px",
                          }}
                        >
                          {row.packageType}
                          {row.packages && row.packages.length > 0
                            ? row.packages.map((d, index) => {
                                return (
                                  <span key={index}>{`,${d.packageType}`}</span>
                                );
                              })
                            : ""}
                          <button
                            className="addPackageBtn"
                            onClick={() => addPackageModelHandler(row._id)}
                          >
                            Add package
                          </button>
                        </TableCell>
                      )}

                      {/* <TableCell
                        align="left"
                        sx={{
                          textDecoration: "underline",
                          textUnderlineOffset: "2px",
                        }}
                      >
                        {row.schoolAdminPrimaryDetail?.phone}
                      </TableCell> */}
                      {renewBtn ? (
                        <TableCell
                          align="left"
                          sx={{
                            textUnderlineOffset: "2px",
                          }}
                        >
                          <button
                            className="addPackageBtn"
                            onClick={() => sendEmailReminder(row._id)}
                            style={{ padding: "10px" }}
                          >
                            Send email reminder
                          </button>
                        </TableCell>
                      ) : (
                        <TableCell align="left">
                          {row?.liscenseRenewDate
                            ? format(
                                new Date(row?.liscenseRenewDate),
                                "yyyy-MM-dd"
                              )
                            : ""}
                        </TableCell>
                      )}
                      <TableCell
                        align="left"
                        sx={{
                          textUnderlineOffset: "2px",
                        }}
                      >
                        <button
                          className="addPackageBtn"
                          onClick={() => changeRegionModelHandler(row._id)}
                        >
                          Change Region
                        </button>
                      </TableCell>

                      {renewBtn ? (
                        ""
                      ) : (
                        <TableCell align="right">
                          <MoreHoriz
                            style={{ color: "gray", cursor: "pointer" }}
                            onClick={() =>
                              togglePauseBar(`pause-school-model-${row._id}`)
                            }
                          />
                          <div
                            className="schoolPauseModel"
                            id={`pause-school-model-${row._id}`}
                            style={{ visibility: "hidden" }}
                          >
                            {row.status === "inactive" ? (
                              <>
                                <div
                                  style={{
                                    width: "84px",
                                    height: "27px",
                                    background: "#ffffff",
                                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "2px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    right: 0,
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => resumeSchoolHandler(row._id)}
                                >
                                  Resume
                                </div>
                                <div
                                  style={{
                                    width: "84px",
                                    height: "27px",
                                    background: "#727272",
                                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "2px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    right: 0,
                                    marginTop: "3px",
                                    marginRight: "120px",
                                    cursor: "pointer",
                                    color: "#ffffff,",
                                  }}
                                  onClick={() => deleteSchoolHandler(row._id)}
                                >
                                  Delete
                                </div>
                              </>
                            ) : (
                              <>
                                <img
                                  src="/icons/pauseIcon.svg"
                                  alt="pause school"
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    marginRight: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    changeSchoolStatusHandler(row._id)
                                  }
                                />
                                <div
                                  style={{
                                    width: "84px",
                                    height: "27px",
                                    background: "#727272",
                                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                    borderRadius: "2px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "absolute",
                                    right: 0,
                                    marginTop: "3px",
                                    marginRight: "120px",
                                    cursor: "pointer",
                                    color: "#ffffff",
                                  }}
                                  onClick={() => deleteSchoolHandler(row._id)}
                                >
                                  Delete
                                </div>
                              </>
                            )}
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 80 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={resultData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) =>
          `Showing ${from}-${to} of ${count !== -1 ? count : `MORE THAN ${to}`}`
        }
        showFirstButton
        showLastButton
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          marginTop: 1,
          '& .MuiTablePagination-selectLabel': {
            display: 'none',
          },
          '& .MuiInputBase-root': {
            backgroundColor: '#F5F5F5',
            borderRadius: 2,
          },
          '& .MuiTablePagination-displayedRows': {
            marginRight: '72%',
            color: '#7A7A7A',
            fontFamily: 'Poppins',
          },

          '& .MuiIconButton-root': {
            backgroundColor: '#ffdda8',
            borderRadius: 2,
            marginRight: 0.5,
            color: '#FE9900',
          },

          '& .Mui-disabled': {
            backgroundColor: '#F5F5F5',
            borderRadius: 2,
            marginRight: 0.5,
            color: 'gray',
          },
        }}
      /> */}
      <GlobalModal
        open={addPackageModelState}
        close={addPackageModelHandler}
        heading="
      Add new package"
      >
        <div className="addLanguageModelMain py-4">
          <div className="d-flex flex-column my-2">
            <label htmlFor="userName" style={{ marginBottom: "10px" }}>
              Package Type
            </label>
            <select
              value={packageType}
              onChange={(e) => setPacakgeType(e.target.value)}
              style={{ padding: "10px" }}
            >
              <option>Select Package Type</option>
              <option value={4}>4 Week</option>
              <option value={8}>8 Week</option>
              <option value={12}>12 Week</option>
            </select>
          </div>
          <div className="d-flex flex-column my-2">
            <label htmlFor="userName" style={{ marginBottom: "10px" }}>
              Language
            </label>
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              style={{ padding: "10px" }}
            >
              <option>Select language</option>
              {allLanguage && allLanguage.length > 0
                ? allLanguage.map((data, index) => {
                    return (
                      <option value={data._id} key={index}>
                        {data.name}
                      </option>
                    );
                  })
                : ""}
            </select>
          </div>
          <div className="confirm-close-btns">
            <ConfirmButton>
              <div className="open-btn" onClick={addPackageHandler}>
                Add
              </div>
            </ConfirmButton>
            <CancelButton>
              <div className="close-btn" onClick={addPackageModelHandler}>
                Cancel
              </div>
            </CancelButton>
          </div>
        </div>
      </GlobalModal>
      <GlobalModal
        open={changeRegionModelState}
        close={changeRegionModelHandler}
        heading="
      Change Region"
      >
        <div className="changeRegionModelMain py-4">
          <div className="d-flex flex-column my-2">
            <label htmlFor="country" style={{ marginBottom: "10px" }}>
              Country
            </label>
            <CountryDropdown
              value={country}
              onChange={(val) => setSelectedCountry(val)}
            />
          </div>
          <div className="d-flex flex-column my-2">
            <label htmlFor="region" style={{ marginBottom: "10px" }}>
              Region
            </label>
            <RegionDropdown
              country={country}
              value={region}
              onChange={(val) => setSelectedRegion(val)}
            />
          </div>
          <div className="confirm-close-btns">
            <ConfirmButton>
              <div className="open-btn" onClick={changeRegionHandler}>
                Change
              </div>
            </ConfirmButton>
            <CancelButton>
              <div className="close-btn" onClick={changeRegionModelHandler}>
                Cancel
              </div>
            </CancelButton>
          </div>
        </div>
      </GlobalModal>
      {/* Renew Liscense Starts */}
      <GlobalModal
        open={renewSchoolModelState}
        close={() => setRenewSchoolModelState(false)}
        heading="
      Renew School Liscense"
      >
        <div className="addLanguageModelMain py-4">
          <div className="d-flex flex-column my-2">
            <label htmlFor="userName" style={{ marginBottom: "10px" }}>
              Liscense expiry date
            </label>
            <input
              style={{ padding: "10px" }}
              type="date"
              name="renewDate"
              value={renewDate}
              onChange={(e) => setRenewDate(e.target.value)}
            />
          </div>
          <div className="confirm-close-btns">
            <ConfirmButton>
              <div className="open-btn" onClick={renewLiscenseHandler}>
                Renew
              </div>
            </ConfirmButton>
            <CancelButton>
              <div
                className="close-btn"
                onClick={() => setRenewSchoolModelState(false)}
              >
                Cancel
              </div>
            </CancelButton>
          </div>
        </div>
      </GlobalModal>
      {/* Renew Liscense Ends */}
    </Box>
  );
}
