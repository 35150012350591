import React, { useEffect, useState } from "react";
import BreadCrumbList from "../../components/BreadCrumbList";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Chart from "react-apexcharts";
import Sidebar from "../../components/Sidebar";
import api from "../../services/api";
const WeeklyQuiz = ({ match }) => {
  const breadcrumbList = [
    {
      name: "Analytics",
      to: "/analytics",
    },
    {
      name: "Quiz",
      to: `/analytics/quiz/${match.params.schoolId}/${match.params.title}/${match.params.weekId}`,
    },
  ];
  const options1 = {
    chart: {
      type: "radialBar",
      height: "220px",
      width: "220px",
    },

    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -20,
            show: true,
            color: "#000000",
            lineHeight: "15px",

            fontSize: "12px",
            fontWeight: "400",
          },
          value: {
            color: "#000000",

            show: true,
            fontWeight: 400,
            fontSize: "36px",
            lineHeight: "44px",
          },
        },
        track: {
          show: true,
          startAngle: undefined,
          endAngle: undefined,
          background: "#979797",
          strokeWidth: "97%",
          opacity: 1,
          margin: 5,
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 3,
            opacity: 0.5,
          },
        },
      },
    },
    colors: ["#EF5B2C", "#0090FF"],
    stroke: {
      lineCap: "round",
    },
    labels: ["Average Correct Rate"],
  };
  const series = [70];
  // get particular week data
  const [weekData, setWeekData] = useState();
  const [message, setMessage] = useState();
  const getWeekData = async () => {
    try {
      const data = await api.get(
        `/api/v1/siteadmin/weekly/individual/quiz/performance/${match.params.schoolId}/${match.params.weekId}`
      );
      if (data && data.ok) {
        setWeekData(data);
      }
    } catch (error) {
      console.error(error);
      setMessage(error.message);
    }
  };
  useEffect(() => {
    getWeekData();
  }, [match.params.schoolId, match.params.weekId]);
  const getProgresspercent = (noOfChoose, totalStudent) => {
    {
      return ((noOfChoose / totalStudent) * 100).toFixed(0);
    }
  };

  return (
    <>
      <Sidebar />
      <div className="main">
        <div className="container-page container-new-page">
          <BreadCrumbList values={breadcrumbList} />
          <div className="weeklyquiz-container">
            <div className="header">{match.params?.title} Analytics</div>
            {message && <div className="msg-week-error">{message}</div>}
            <div className="content analyticsSystem-outer">
              <div className="accordion-content" style={{ width: "100%" }}>
                {weekData?.numberOfOptionPicked &&
                  weekData?.numberOfOptionPicked[0]?.quiz &&
                  weekData?.numberOfOptionPicked[0]?.quiz.length > 0 &&
                  weekData?.numberOfOptionPicked[0]?.quiz.map(
                    (datum, index) => (
                      <Accordion key={index}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <span className="accordion-heading-1">
                            Q.{index + 1}
                          </span>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="accordion-inner-content">
                            <div className="leftContent">
                              <Chart
                                options={options1}
                                series={[
                                  weekData?.totalAvgCorrect[index]
                                    ? (
                                        weekData?.totalAvgCorrect[index] * 100
                                      ).toFixed(0)
                                    : 0,
                                ]}
                                type="radialBar"
                                height={220}
                                width={220}
                              />
                              <div className="time-content">
                                <span>Average Time Spent</span>
                                <span>
                                  {weekData?.averageSubmittionTime[0][index]
                                    ?.time
                                    ? weekData?.averageSubmittionTime[0][index]
                                        ?.time
                                    : 0}{" "}
                                  seconds
                                </span>
                              </div>
                            </div>
                            <div className="right-content">
                              <div className="top">{datum?.question}</div>
                              {datum.options &&
                                datum.options.length > 0 &&
                                datum.options.map((dataIs, index) => (
                                  <div className="option-here" ky={index}>
                                    {dataIs.name}
                                    <div
                                      className="progress"
                                      style={{
                                        width: `${getProgresspercent(
                                          dataIs?.noOfChoose,
                                          weekData?._totalStudent
                                        )}%`,
                                      }}
                                    >
                                      {dataIs?.noOfChoose > 0
                                        ? dataIs?.noOfChoose
                                        : ""}
                                      (
                                      {getProgresspercent(
                                        dataIs?.noOfChoose,
                                        weekData?._totalStudent
                                      ) > 0 &&
                                        `${getProgresspercent(
                                          dataIs?.noOfChoose,
                                          weekData?._totalStudent
                                        )}% `}
                                      ){" "}
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  )}
              </div>
              {/* <Box
                sx={{
                  // marginTop: '25px',
                  width: '30%',
                  background: 'rgba(239, 91, 44, 0.2)',
                  borderRadius: '10px',
                  overflow: 'hidden',
                  minHeight: '420px',
                  padding: '21px',
                  paddingTop: 0,
                }}
              >
                <div className='header'>Sort By</div>
                <div className='group' style={{ marginTop: '10px' }}>
                  <input type='checkbox' id='allRecord' />
                  <label htmlFor='allRecord'>All Record</label>
                </div>
                <div className='header-sm'>School</div>
                <div className='selectGroup'>
                  <select className='select-1' name='' id=''>
                    <option value='sdfds'>sdf</option>
                  </select>
                  <input type='text' />
                </div>
                <div className='header-sm'>Region</div>
                <div className='checkbox-containers'>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Hongkong</label>
                  </div>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Indonesia</label>
                  </div>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Japan</label>
                  </div>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Korea</label>
                  </div>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Mainland China</label>
                  </div>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Taiwan</label>
                  </div>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Singapore</label>
                  </div>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Thailand</label>
                  </div>
                </div>
                <div className='header-sm'>List</div>
                <div className='checkbox-containers'>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Collapse All</label>
                  </div>
                  <div className='group'>
                    <input type='checkbox' id='allRecord' />
                    <label htmlFor='allRecord'>Expand All</label>
                  </div>
                </div>
              </Box> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeeklyQuiz;
