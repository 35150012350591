// import React from 'react'
import TableCell from "@mui/material/TableCell";
import { Button, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import DatePicker from "react-datepicker";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TabPanel } from "../../components/Tabs";
import { SetProps } from "../../components/Tabs";
import "react-datepicker/dist/react-datepicker.css";
import { GlobalModal } from "../../components/Modal";
import warning from "../../assets/warning.svg";
import uploadIcon from "../../assets/uploadIcon.svg";
import uploadIconSm from "../../assets/uploadIconSm.svg";
import videoUpload from "../../assets/videoUpload.svg";
import plusIcon from "../../assets/plusIcon.svg";
import {
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import {
  consoleError,
  consoleLog,
  getOnlyFolderStructure,
} from "../../utils/helper";
import api from "../../services/api";
import { useParams } from "react-router-dom";
import axios from "axios";
import store from "../../store";
import { beginApiCall, endApiCall, cancelApiCall } from "../../store/actions/siteadminActions";
import MuiAlert from "@mui/material/Alert";
import config from "../../config";
import { borderRadius } from "@mui/system";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SchoolContent({ row }) {
  const { school } = useParams();

  const finalTheme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: ({ theme }) => theme.unstable_sx({
            borderBottom: "4px solid #202020 !important",
          }),
        },
      },
    },
  });

  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);

  const openModal = () => {
    setOpen(true);
  };
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const closeModal = () => {
    setOpen(false);
    // setChooseImage("");
    setClose(true);
    setValue(0);
  };
  // const Modal = () => (

  // )

  // const fetchContentData = async() => {
  //   try{

  //   }catch(err){
  //     consoleLog(err);
  //   }
  // }
  
  const [pdf, setPdf] = useState(row.description);
  const [video1Id, setVideo1Id] = useState(row.videos[0]._id);
  const [video1, setVideo1] = useState(row.videos[0]);
  const [subtitle1, setSubtitle1] = useState(
    row?.videos &&
      row?.videos.length > 0 &&
      row?.videos[0] &&
      row?.videos[0]?.subtitle &&
      row?.videos[0]?.subtitle.length > 0 &&
      row?.videos[0]?.subtitle[0] &&
      !row?.videos[0]?.subtitle[0]?.language
      ? [{ language: "", url: "" }]
      : row.videos[0]?.subtitle
  );
  const [video2Id, setVideo2Id] = useState(row.videos[1]._id);
  const [video2, setVideo2] = useState(row.videos[1]);
  // const [subtitle2, setSubtitle2] = useState(row.videos[1].subtitle);
  const [subtitle2, setSubtitle2] = useState(
    row.videos &&
      row?.videos.length > 0 &&
      row?.videos[0] &&
      row?.videos[0]?.subtitle &&
      row?.videos[0]?.subtitle.length > 0 &&
      row?.videos[0]?.subtitle[0] &&
      !row.videos[0]?.subtitle[0].language
      ? [{ language: "", url: "" }]
      : row.videos[1].subtitle
  );

  const [message, setMessage] = useState("");
  const [configName, setConfigName] = useState("");
  const [quiz, setQuiz] = useState(row.quiz);
  const [chooseCSV, setChooseCSV] = useState("");
  const [pdfLoading, setPdfLoading] = useState(false);
  const [videoLoading, setVideoLoading] = useState(false);
  const [subtitleLoading, setSubtitleLoading] = useState(false);
  const [quizLoading, setQuizLoading] = useState(false);
  const [title, setTitle] = useState(row.title);
  const [decisionTreeQuestion, setDecisionTreeQuestion] = useState(
    row.decisionTreeQuestions
  );
  const [chooseDecisionTreeCSV, setChooseDecisionTreeCSV] = useState("");

  const uploadPdf = async (e) => {
    setPdfLoading(true);
    // const data = new FormData();
    // data.append("docs", e.target.files[0]);
    // const res = await api.post(
    //   "/api/v1/teacher/classroomContents/upload/pdf",
    //   data
    // );
    // if (res && res.ok) {
    //   setPdf(res.url);
    //   setPdfLoading(false);
    // }
    const file = e.target.files[0];
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/upload/video/preSignedUrl`,
        {
          fileName: `uploads/admin/files/${e.target.files[0].name}`,
        }
      );
      if (res.ok && res.urls) {
        store.dispatch(beginApiCall());
        await axios.put(res.urls, file);
        store.dispatch(endApiCall());
        const onlyFolderLink = getOnlyFolderStructure(res.urls.split("?")[0]);
        setPdf(onlyFolderLink);
        setPdfLoading(false);
      }
    } catch (err) {
      console.error(err);
      setPdfLoading(false);
      alert("erorr uploading PDF");
    }
  };

  const uploadVideo = async (e, indexValue) => {
    setVideoLoading(true);
    // const data = new FormData();
    // data.append("videos", e.target.files[0]);
    // const res = await api.post(
    //   "/api/v1/teacher/classroomContents/upload/video",
    //   data
    // );
    // if (res && res.ok) {
    //   setVideoLoading(false);
    //   if (indexValue === 1) {
    //     setVideo1Id(res.urls[0]._id);
    //     setVideo1(res.urls[0]);
    //   } else if (indexValue === 2) {
    //     setVideo2Id(res.urls[0]._id);
    //     setVideo2(res.urls[0]);
    //   }
    // }
    const file = e.target.files[0];
    try {
      const res = await api.post(
        `/api/v1/teacher/classroomContents/upload/video/preSignedUrl`,
        {
          fileName: `uploads/admin/videos/${e.target.files[0].name}`,
        }
      );

      if (res.ok && res.urls) {
        store.dispatch(beginApiCall());
        await axios.put(res.urls, file);
        store.dispatch(endApiCall());
        const onlyFolderLink = getOnlyFolderStructure(res.urls.split("?")[0]);
        const uploadToDb = await api.post(
          `/api/v1/teacher/classroomContents/upload/video`,
          { videoUrl: onlyFolderLink }
        );
        if (uploadToDb && uploadToDb.ok) {
          if (indexValue === 1) {
            setVideo1((prevValue) => {
              return {
                ...prevValue,
                _id: uploadToDb.urls._id,
                url: uploadToDb.urls.url,
              };
            });
            // setVideo1Id(uploadToDb.urls._id);
            // setVideo1(uploadToDb.urls._id);
          } else if (indexValue === 2) {
            // setVideo2Id(uploadToDb.urls._id);
            // setVideo2(uploadToDb.urls._id);
            // setVideo2({ _id: uploadToDb.urls._id });
            setVideo2((prevValue) => {
              return {
                ...prevValue,
                _id: uploadToDb.urls._id,
                url: uploadToDb.urls.url,
              };
            });
          }
          setVideoLoading(false);
        }
      }
    } catch (err) {
      console.error(err);
      setVideoLoading(false);
      alert("erorr uploading video 1");
    }
  };

  const addNewSubtitle1Handler = () => {
    const oldData = [];
    for (var i = 0; i < subtitle1.length; i++) {
      oldData.push(subtitle1[i]);
    }
    oldData.push({ language: "", url: "" });
    setSubtitle1(oldData);
  };

  const addNewSubtitle2Handler = () => {
    const oldData = [];
    for (var i = 0; i < subtitle2.length; i++) {
      oldData.push(subtitle2[i]);
    }
    oldData.push({ language: "", url: "" });
    setSubtitle2(oldData);
  };

  const changeSubtitle1LanguageHandler = (e, index) => {
    const { value } = e.target;
    const oldData = [];
    for (var i = 0; i < subtitle1.length; i++) {
      oldData.push(subtitle1[i]);
    }
    oldData[index].language = value;
    setSubtitle1(oldData);
  };

  const changeSubtitle2LanguageHandler = (e, index) => {
    const { value } = e.target;
    const oldData = [];
    for (var i = 0; i < subtitle2.length; i++) {
      oldData.push(subtitle2[i]);
    }
    oldData[index].language = value;
    setSubtitle2(oldData);
  };

  // const uploadSubtitle = async (e, subtitleIndex) => {
  //   try {
  //     setSubtitleLoading(true);
  //     const file = e.target.files[0];
  //     const formData = new FormData();
  //     formData.append("subtiles", file);
  //     const res = await api.post(
  //       `/api/v1/teacher/classroomContents/uploadSingle/subtitle`,
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     if (res && res.ok) {
  //       setSubtitleLoading(false);
  //       if (subtitleIndex === 1) {
  //         setSubtitle1(res.url);
  //       } else if (subtitleIndex === 2) {
  //         setSubtitle2(res.url);
  //       }
  //     }
  //   } catch (err) {
  //     consoleError(err);
  //   }
  // };

  const uploadSubtitle1 = async (e, index) => {
    try {
      setSubtitleLoading(true);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("subtiles", file);
      const res = await api.post(
        `/api/v1/teacher/classroomContents/uploadSingle/subtitle`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        setSubtitleLoading(false);
        const oldData = [];
        for (var i = 0; i < subtitle1.length; i++) {
          oldData.push(subtitle1[i]);
        }
        oldData[index].url = res.url;
        setSubtitle1(oldData);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  const uploadSubtitle2 = async (e, index) => {
    try {
      setSubtitleLoading(true);
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("subtiles", file);
      const res = await api.post(
        `/api/v1/teacher/classroomContents/uploadSingle/subtitle`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res && res.ok) {
        setSubtitleLoading(false);
        const oldData = [];
        for (var i = 0; i < subtitle2.length; i++) {
          oldData.push(subtitle2[i]);
        }
        oldData[index].url = res.url;
        setSubtitle2(oldData);
      }
    } catch (err) {
      consoleError(err);
    }
  };

  const loadQuizFromJoyStick = async (event) => {
    try {
      store.dispatch(beginApiCall());
      var data = {
        u: "",
        p: {},
      };
      var configData = {
        method: "post",
        url: `${config.app.joystickApiBaseUrl}/${configName}/dynamic`,
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": `${config.app.joystickAPIKey}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        },
        data: data,
      };

      await axios(configData)
        .then(function (response) {
          store.dispatch(endApiCall());
          uploadQuizCsv(response.data.data.questions);
        })
        .catch(function (error) {
          console.error(error);
          alert("Invalid Quiz Code");
        });
    } finally {
      store.dispatch(cancelApiCall());
    }
};
  const uploadQuizCsv = async (data) => {
    try {
      const res = await api.patch(
        `/api/v1/teacher/upload/defaultContentQuizExcel`,
        {
          quizQuestions: data,
          classroomContentId: row._id,
        }
      );
      if (res && res.ok) {
        setQuizLoading(false);
        setMessage("Data loaded sucessfully.");
      }
    } catch (err) {
      consoleLog(err);
    }
  };

  const loadDecisionTreeFromJoyStick = async (event) => {
    try {
      store.dispatch(beginApiCall());
      var data = {
        u: "",
        p: {},
      };
      var configData = {
        method: "post",
        url: `${config.app.joystickApiBaseUrl}/${configName}/dynamic`,
        headers: {
          "Content-Type": "application/json",
          "X-Api-Key": `${config.app.joystickAPIKey}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT,DELETE",
        },
        data: data,
      };

      await axios(configData)
        .then(function (response) {
          store.dispatch(endApiCall());
          uploadDecisionTree(response.data.data.questions);
        })
        .catch(function (error) {
          console.error(error);
          alert("Invalid Decision Tree Code");
        });
      } finally {
        store.dispatch(cancelApiCall());
      }
  };
  const uploadDecisionTree = async (data) => {
    try {
      const addObj = {
        questions: data,
        language: decisionTreeQuestion.language,
      };
      const updateObj = {
        questions: data,
        decisionTreeId: decisionTreeQuestion,
      };
      const res = await api.post(
        "/api/v1/siteadmin/upload/default/decisionTree",
        decisionTreeQuestion ? updateObj : addObj
      );
      if (res && res.ok) {
        setMessage(res.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [video1Title, setVideo1Title] = useState(row.videos[0].videoTitle);
  const [video2Title, setVideo2Title] = useState(row.videos[1].videoTitle);
  const [pdfTitle, setPdfTitle] = useState(row.pdfTitle);

  const updateAllDataHandler = async () => {
    const video1NewData = video1;
    const video2NewData = video2;
    // console.log({ video1NewData, video2NewData });
    video1NewData.videoTitle = video1Title;
    video2NewData.videoTitle = video2Title;
    video1NewData.subtitle = subtitle1;
    video2NewData.subtitle = subtitle2;
    // console.log({ subtitle1, subtitle2 });
    // console.log({ video1, video2 });
    // console.log({
    //   title: title,
    //   description: pdf,
    //   // videos: [video1Id, video2Id],
    //   videos: [video1NewData, video2NewData],
    //   quiz: quiz,
    //   pdfTitle: pdfTitle,
    //   decisionTreeQuestions: decisionTreeQuestion,
    // });
    try {
      const obj = {
        title: title,
        description: pdf,
        // videos: [video1Id, video2Id],
        videos: [video1NewData, video2NewData],
        // quiz: quiz,
        pdfTitle: pdfTitle,
        // decisionTreeQuestions: decisionTreeQuestion,
      };
      // console.log(obj);
      const res = await api.post(
        `/api/v1/teacher/classroomContents/editAllData/${row._id}`,
        { data: obj }
      );
      if (res && res.ok) {
        closeModal();
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const exportCSVHandler = async () => {
    try {
      const resData = await api.get(
        `/api/v1/siteadmin/decisionTree/csv/${row._id}`
      );
      startDownload(resData.location, "decisionTree.xlsx");
    } catch (err) {
      consoleLog(err);
    }
  };
  async function startDownload(file, fileName) {
    const link = document.createElement("a");
    link.href = `${config.app.apiUrl}/api/public/admin/decisionTreeDownloaded/${file}`;
    link.setAttribute("download", file);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  const exportQuizCSVHandler = async () => {
    try {
      const resData = await api.get(`/api/v1/siteadmin/quiz/csv/${row._id}`);
      startDownloadQuiz(resData.location, "quiz.xlsx");
    } catch (err) {
      consoleLog(err);
    }
  };

  async function startDownloadQuiz(file, fileName) {
    const link = document.createElement("a");
    link.href = `${config.app.apiUrl}/api/public/admin/quizDownloaded/${file}`;
    link.setAttribute("download", file);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  }

  return (
    <>
      <TableCell
        align="left"
        // style={{ display: "flex", alignItems: "center" }}
      >
        {row.title}
        {/* <img src={"/icons/edit.svg"} style={{ marginLeft: "10px" }} onClick={editTitleModuleHandler}/> */}
      </TableCell>
      <TableCell align="left">{row?.classroomId?.className}</TableCell>
      {/* <TableCell align='left'>{row.type}</TableCell> */}
      <TableCell align="left">
        <Typography>
          <button className="inspect-btn" onClick={openModal}>
            Inspect
          </button>
        </Typography>
      </TableCell>
      <GlobalModal
        type="upload"
        open={open}
        close={closeModal}
        heading="Manage Content"
      >
        <ThemeProvider theme={finalTheme}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <div className="tabs-section tabs-section-upload">
                <Tabs
                  sx={{
                    width: "80%",
                    marginBottom: "33px",
                  }}
                  value={value}
                  indicatorColor="green"
                  onChange={handleChange}
                  sx={{ textTransform: "lowercase" }}
                >
                  <Tab label="Module Info" {...SetProps(0)} />
                  <Tab label="Text" {...SetProps(1)} />
                  <Tab label="Videos" {...SetProps(2)} />
                  <Tab label="Decision Tree" {...SetProps(3)} />
                  <Tab label="Quiz Questions" {...SetProps(4)} />
                </Tabs>
              </div>
            </Box>
            <TabPanel value={value} index={0} sx={{ position: "relative" }}>
              <div className="image-upload">
                <input
                  className="title-content  title-content-languagemgmt"
                  placeholder="Enter Module Title"
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  name="title"
                  value={title}
                />
              </div>
              <div className="newEntryBottom-wrapper">
                <div className="btn-next" onClick={() => setValue(1)}>
                  Next
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1} sx={{ position: "relative" }}>
              <div className="image-upload">
                <input
                  className="title-content  title-content-languagemgmt"
                  placeholder="Enter Title..."
                  type="text"
                  onChange={(e) => setPdfTitle(e.target.value)}
                  name="pdfTitle"
                  value={pdfTitle}
                />
                <div className="image-upload-inner">
                  <label htmlFor="input_file">
                    {" "}
                    <img src={uploadIcon} alt="" />
                  </label>
                  <input
                    onChange={(e) => uploadPdf(e)}
                    type="file"
                    class="input-file"
                    id="input_file"
                  />

                  <span>{pdf && pdf.split("\\")[3]}</span>
                  <label className="change-img" htmlFor="input_file">
                    Change{" "}
                  </label>
                </div>
              </div>
              <div className="newEntryBottom-wrapper">
                <div className="btn-next" onClick={() => setValue(2)}>
                  Next
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2} sx={{ position: "relative" }}>
              <div className="image-upload image-upload-video">
                <div className="image-upload-video-1">
                  <input
                    className="title-content video-title title-content-languagemgmt"
                    placeholder="Enter video 1 Title..."
                    type="text"
                    name="video1Title"
                    onChange={(e) => setVideo1Title(e.target.value)}
                    value={video1Title}
                  />
                  <div className="image-upload-inner image-upload-inner-languagemgmt">
                    <label htmlFor="input_file">
                      {" "}
                      <img src={videoUpload} alt="Upload Video" />
                    </label>
                    <input
                      onChange={(e) => uploadVideo(e, 1)}
                      type="file"
                      class="input-file"
                      id="input_file1"
                      name="video-1"
                    />

                    <span>
                      {video1 && video1.url && video1.url.substring(50, 100)}
                    </span>
                    <label className="change-img" htmlFor="input_file1">
                      Change{" "}
                    </label>
                  </div>
                  {subtitle1 && subtitle1.length > 0
                    ? subtitle1.map((data, index) => {
                        return (
                          <div
                            className="error-upload"
                            style={{
                              // marginTop: "-20px",
                              display: "flex",
                              flexDirection: "column",
                              padding: "20px",
                              alignItems: "flex-start",
                            }}
                            key={index}
                          >
                            <label>Language</label>
                            <input
                              type="text"
                              name={`language-${index}`}
                              value={subtitle1[index].language}
                              onChange={(e) =>
                                changeSubtitle1LanguageHandler(e, index)
                              }
                            />
                            <div className="subtitle">
                              <label for={`subtitle-${index}`}>
                                <img src={uploadIconSm} />
                                <span>
                                  {data[index]?.url
                                    ? "Subtitle selected"
                                    : "subtitle"}
                                </span>
                              </label>
                              <input
                                onChange={(e) => uploadSubtitle1(e, index)}
                                id={`subtitle-${index}`}
                                accept=".vtt,.srt"
                                type="file"
                              />
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  <span
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={addNewSubtitle1Handler}
                  >
                    +Add new subtitle
                  </span>
                  {/* <div className="error-upload">
                    <div className="subtitle">
                      <label for="subtitle-1">
                        <img src={uploadIconSm} />
                        <span>
                          {subtitle1 ? "Subtitle selected" : "subtitle"}
                        </span>
                      </label>
                      <input
                        onChange={(e) => uploadSubtitle(e, 1)}
                        id="subtitle-1"
                        accept=".vtt,.srt"
                        type="file"
                      />
                    </div>
                  </div> */}
                </div>
                <div className="image-upload-video-1">
                  <input
                    className="title-content video-title title-content-languagemgmt"
                    placeholder="Enter video 2 Title..."
                    type="text"
                    onChange={(e) => setVideo2Title(e.target.value)}
                    name="video2Title"
                    value={video2Title}
                  />
                  <div
                    className="image-upload-inner image-upload-inner-languagemgmt"
                    style={{ zIndex: 1 }}
                  >
                    <label htmlFor="input_file">
                      {" "}
                      <img src={videoUpload} alt="" />
                    </label>
                    <input
                      onChange={(e) => uploadVideo(e, 2)}
                      type="file"
                      accept=".mp4"
                      class="input-file"
                      id="input_file2"
                    />

                    <span>{video2 && video2.url && video2.url.substring(50, 100)}</span>
                    <label className="change-img" htmlFor="input_file2">
                      Change{" "}
                    </label>
                  </div>
                  {subtitle2 && subtitle2.length > 0
                    ? subtitle2.map((data, index) => {
                        return (
                          <div
                            className="error-upload"
                            style={{
                              // marginTop: "-20px",
                              display: "flex",
                              flexDirection: "column",
                              padding: "20px",
                              alignItems: "flex-start",
                            }}
                            key={index}
                          >
                            <label>Language</label>
                            <input
                              type="text"
                              name={`language-${index}`}
                              value={subtitle2[index].language}
                              onChange={(e) =>
                                changeSubtitle2LanguageHandler(e, index)
                              }
                            />
                            <div className="subtitle">
                              <label for={`subtitle1-${index}`}>
                                <img src={uploadIconSm} />
                                <span>
                                  {data[index]?.url
                                    ? "Subtitle selected"
                                    : "subtitle"}
                                </span>
                              </label>
                              <input
                                onChange={(e) => uploadSubtitle2(e, index)}
                                id={`subtitle1-${index}`}
                                accept=".vtt,.srt"
                                type="file"
                              />
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  <span
                    style={{
                      color: "blue",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={addNewSubtitle2Handler}
                  >
                    +Add new subtitle
                  </span>
                  {/* <div className="error-upload">
                    <div className="subtitle">
                      <label for="subtitle-2">
                        <img src={uploadIconSm} />
                        <span>
                          {subtitle2 ? "Subtitle selected" : "subtitle"}
                        </span>
                      </label>
                      <input
                        onChange={(e) => uploadSubtitle(e, 2)}
                        id="subtitle-2"
                        accept=".vtt,.srt"
                        type="file"
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              <div
                className="newEntryBottom-wrapper"
                style={{
                  position: "relative",
                  bottom: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 1,
                  padding: "10px",
                }}
              >
                <div
                  className="btn-next"
                  style={{ background: "#9D9D9D" }}
                  onClick={() => setValue(3)}
                >
                  Next
                </div>
              </div>
            </TabPanel>{" "}
            <TabPanel value={value} index={3} sx={{ position: "relative" }}>
              <div className="image-upload">
                <div className="image-upload-inner">
                  <input
                    type="text"
                    name="configName"
                    placeholder="Enter config file name"
                    value={configName}
                    onChange={(e) => setConfigName(e.target.value)}
                  />
                  <button
                    className="school-btn"
                    onClick={(e) => loadDecisionTreeFromJoyStick(e)}
                  >
                    Load Data
                  </button>
                </div>
                {message ? (
                  <div className="error-upload">
                    <img src={warning} alt="Warning" />
                    <span>{message}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="newEntryBottom-wrapper">
                <div
                  className="btn-next"
                  style={{ background: "#9D9D9D" }}
                  onClick={() => setValue(4)}
                >
                  Next
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={4} sx={{ position: "relative" }}>
              <div className="image-upload">
                <div className="image-upload-inner">
                  <input
                    type="text"
                    name="configName"
                    placeholder="Enter config file name"
                    value={configName}
                    onChange={(e) => setConfigName(e.target.value)}
                  />
                  <button className="school-btn" onClick={(e) => loadQuizFromJoyStick(e)}>
                    Load Data
                  </button>
                </div>
                {message ? (
                  <div className="error-upload">
                    <img src={warning} alt="Warning" />
                    <span>{message}</span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="newEntryBottom-wrapper">
                <div
                  className="btn-next btn-done"
                  onClick={updateAllDataHandler}
                >
                  Done
                </div>
              </div>
            </TabPanel>
          </Box>
        </ThemeProvider>
      </GlobalModal>
    </>
  );
}
