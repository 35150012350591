import React from 'react'
import TableCell from '@mui/material/TableCell'
export default function LeaderboardCell({ row }) {
  return (
    <>
      <TableCell align='left'>{row?.rank}</TableCell>
      <TableCell align='left'>{row?.schoolId?.name}</TableCell>
      <TableCell align='left'>{row?.name}</TableCell>
      <TableCell align='left'>
        {row?.totalCampnayValuation}
      </TableCell>
      <TableCell align='left'>
        {row?.totalcompanyReputation}
      </TableCell>
      <TableCell align='left'>{row?.totalemployNumber}</TableCell>
      <TableCell align='left'>{row?.totalemploySatisfaction}</TableCell>
      <TableCell align='left'>{row?.totalCash}</TableCell>
    </>
  )
}
