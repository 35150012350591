import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import TableMain from '../Table'
import { useDispatch, useSelector } from 'react-redux'
import siteadminService from '../../services/siteadminService'
import { InfoContext } from '../Context/InfoContext'
import CancelButton from '../Buttons/CancelButton'
import ConfirmButton from '../Buttons/ConfirmButton'
import api from '../../services/api'
import config from '../../config'
import { useHistory } from 'react-router-dom'

import {
  Backdrop,
  Box,
  Fade,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import ErrorMessage from '../../components/ErrorMessage'

const headCells = [
  {
    id: 'teacher',
    label: 'Teacher',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'classroom',
    label: 'Assigned Classroom',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'actions',
    label: 'Actions',
    numeric: false,
    disablePadding: false,
  },
]

const inspectHeadCells = [
  {
    id: 'class',
    label: 'Class',
    numeric: false,

    disablePadding: false,
  },

  {
    id: 'size',
    label: 'Size',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'progress',
    label: 'Progress',
    numeric: false,
    disablePadding: false,
  },

  {
    id: 'mean',
    label: 'Mean scores',
    numeric: false,
    disablePadding: false,
  },
]

export default function Teacher({ resultData, teacher, school }) {
  const navigation = useHistory()
  const schoolName = useContext(InfoContext).schoolName

  const InspectOn = (e) => {
    navigation.push({
      pathname: `/dashboard/content/${schoolName}`,
      state: {
        name: e.target.textContent,
        school: schoolName,
      },
    })
  }
  // form fields
  const dispatch = useDispatch()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [open, setOpen] = React.useState(false)
  const handleClose = () => setOpen(false)
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 3,
    borderRadius: 3,
    width: '45%',
  }
  // add teacher
  const [errors, setErrors] = useState({})
  const validateForm = (values) => {
    let errors = {}
    if (!values.name) {
      errors.name = 'Teacher Name is Required'
    }
    if (!values.email) {
      errors.email = 'Email is Required'
    }

    return errors
  }
  const [message, setMesssage] = useState('')
  const onAddTeacher = async (e) => {
    e.preventDefault()
    const events = {
      name,
      email,
    }
    try {
      setErrors(validateForm(events))
      if (Object.keys(validateForm(events)).length > 0) {
        return
      } else {
        const data = await api.post(
          `${config.app.apiUrl}/api/v1/siteadmin/add/teacher/school`,
          {
            email: email,
            username: name,
            role: 'teacher',
            school: school,
          }
        )
        if (data && data.ok) {
          setOpen(false)
          setEmail('')
          setName('')
          await siteadminService.getShoolTeacher(school, dispatch)
        }
      }
    } catch (error) {
      console.error(error)
      setMesssage(error.message)
    }
  }
  return (
    <div>
      {/* modal to add teacher */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography
              sx={{ textAlign: 'center', color: 'black', fontWeight: 'bold' }}
              variant='h6'
              component='h2'
            >
              New Teacher
            </Typography>
            {message && <ErrorMessage message={message} type='danger' />}
            <form>
              <Typography
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  mb: 5,
                }}
              >
              <div className="form-group">
                <label htmlFor="userName">Teacher name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              </Typography>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: 280,
                  marginLeft: '33%',
                }}
              >
                <div onClick={(e) => onAddTeacher(e)}>
                  <ConfirmButton>
                    <div className='open-btn'>Confirm</div>
                  </ConfirmButton>
                </div>

                <CancelButton>
                  <div className='close-btn' onClick={handleClose}>
                    Cancel
                  </div>
                </CancelButton>
              </div>
            </form>
          </Box>
        </Fade>
      </Modal>
      <div style={{ float: 'right', marginBottom: 20 }}>
        <button onClick={InspectOn} className='school-btn'>
          Languages / Content Management
        </button>
        <div style={{ marginBottom: '24.21px' }}></div>
        <button onClick={() => setOpen(true)} className='school-btn'>
          Add Teacher
        </button>
      </div>
      <TableMain
        headCells={headCells}
        resultData={resultData}
        topic='teacher'
        school={school}
      />
    </div>
  )
}
