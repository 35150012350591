import React from "react";
import ClassroomCell from "./ClassroomCell";
import LicenseCell from "./LicenseCell";
import SchoolContent from "./SchoolContent";
import TeacherCell from "./TeacherCell";
import SchoolAdminCell from "./SchoolAdminCell";

import {
  ManageAccountsRole,
  ManageAccountsSiteAdmin,
} from "./ManageAccountsCell";
import TeacherInspectCell from "./TeacherInspectCell";
import LeaderboardCell from "./LeaderboardCell";
import ClassroomInspectCell from "./ClassroomInspectCell";
import AnalyticsSystemCell from "./AnalyticsSystemCell";
import AnalyticsMediaCell from "./AnalyticsMediaCell";
import AnalyticsMediaText from "./AnalyticsMediaText";
import AnalyticsGameCell from "./AnalyticsGameCell";

export default function AllTableCells({ row, topic, school }) {
  return topic === "classroom" ? (
    <ClassroomCell row={row} school={school} />
    ) : topic === "schoolAdmin" ? (
    <SchoolAdminCell row={row} school={school} />
    ) : topic === "teacher" ? (
    <TeacherCell row={row} school={school} />
  ) : topic === "studentList" ? (
    <ClassroomInspectCell row={row} />
  ) : topic === "inspteacher" ? (
    <TeacherInspectCell row={row} />
  ) : topic === "license" ? (
    <LicenseCell row={row} school={school} />
  ) : topic === "school-content" ? (
    <SchoolContent row={row} />
  ) : topic === "manage-accounts-siteAdmin" ? (
    <ManageAccountsSiteAdmin row={row} />
  ) : topic === "manage-accounts-school" ? (
    <ManageAccountsRole role={"school"} row={row} />
  ) : topic === "manage-accounts-teacher" ? (
    <ManageAccountsRole role={"teacher"} row={row} />
  ) : topic === "manage-accounts-student" ? (
    <ManageAccountsRole role={"student"} row={row} />
  ) : topic === "leaderboard" ? (
    <LeaderboardCell row={row} />
  ) : topic === "AnalyticsSystem" ? (
    <AnalyticsSystemCell row={row} />
  ) : topic === "AnalyticsMedia" ? (
    <AnalyticsMediaCell row={row} />
  ) : topic === "AnalyticsMediaText" ? (
    <AnalyticsMediaText row={row} />
  ) : topic === "AnalyticsGame" ? (
    <AnalyticsGameCell row={row} />
  ) : null;
}
